import { StateRoutesContext } from "apps/optimization/state/routes"
import { updateSideBar } from "utils/store/sidebar"
import { errorNotification, successNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { site } from "utils/store/site"
import { organization } from "utils/store/organization"
import { Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { StateForm, StateOverview } from "../models"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { listGatewayBySite, update } from "../api"

export default function Detail() {
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [object, setObject] = useState(null)
  const { state, retrieveDetail } = useContext(StateRoutesContext)
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({})

  const [gateways, setGateways] = useState([])

  const [processing, setProcessing] = useState(false)

  const findAllGatewayAvailable = async () => {
    setLoading(true)
    listGatewayBySite(siteStore.id, auth.userData.access_token)
      .then(response => {
        setGateways(response.data.results)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.gateway"), message)]))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setObject({ ...state })
    if (state && edit) {
      // Only fetch gateways if we are in edit mode
      findAllGatewayAvailable()
      setData({
        ...state,
        gateway: parseInt(state.gateway) // @todo ugly
      })
    }
  }, [state, edit])

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  const updateObject = async event => {
    event.preventDefault()
    setProcessing(true)

    const finalData = {
      ...data,
      gateway: data.gateway.toString()
    }

    update(siteStore.id, state.id, finalData, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([successNotification(t("common.state"), t("common.update_success"))])
        )
        setEdit(false)
        retrieveDetail()
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message
        error?.response?.data && setErrors(error.response.data)
        dispatch(addNotifications(errorNotification(t("common.state"), message)))
      })
      .finally(() => setProcessing(false))
  }

  return object ? (
    <OverView
      object={object}
      model={StateForm(gateways)}
      overviewModel={StateOverview(object)}
      updateObject={updateObject}
      data={data}
      setData={setData}
      errors={errors}
      edit={edit}
      setEdit={setEdit}
      loading={loading}
      processing={processing}
      deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/${state.id}/delete/`}
    />
  ) : (
    <Loader />
  )
}
