import * as React from "react"
import { Route, Routes } from "react-router-dom"
import List from "./components/List"
import Update from "./components/Update"
import Version from "./components/Version"

export default function UpdateSoftwareRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="version"
        element={<Version />}
      />
      <Route
        path="update"
        element={<Update />}
      />
    </Routes>
  )
}
