import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListLayout, Loader, ListActions } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { create, retrieveList, update } from "../api"
import BillResume from "./BillResume"
import { Button } from "primereact/button"
import BillForm from "./BillForm"
import { DEFAULT_CURRENCY, MAPPING_CURRENCY } from "/utils/constants/bill"

const EMPTY_BILL = {
  start_validity: "",
  end_validity: "",
  currency: "",
  prices: []
}

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentBill, setCurrentBill] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currency, setCurrency] = useState(null)
  const [data, setData] = useState({
    start_validity: "",
    end_validity: "",
    prices: []
  })

  const submit = async e => {
    e.preventDefault()
    const start_date_to_send = new Date(data.start_validity)
    let end_validity_to_send = new Date(data.end_validity)
    if (data.start_validity === data.end_validity) {
      end_validity_to_send.setHours(23, 59)
    }
    const dataToSend = {
      ...data,
      currency: currency,
      start_validity: start_date_to_send.toISOString(),
      end_validity: end_validity_to_send.toISOString()
    }
    setLoading(true)
    if (items.length === 0) {
      await create(siteStore.id, dataToSend, auth.userData.access_token)
        .then(() => {
          dispatch(
            addNotifications(successNotification(t("common.bill"), t("common.creation_success")))
          )
          cancelForm()
          retrieve()
        })
        .catch(error => {
          setLoading(false)
          dispatch(addNotifications([errorNotification(t("common.bill"), error.message)]))
        })
    } else {
      const billsList = items.reverse()

      if (isUpdate) {
        if (billsList.length > 1) {
          if (dataToSend.start_validity < billsList[billsList.length - 2].end_validity) {
            dispatch(
              addNotifications(errorNotification(t("common.bill"), t("common.incoherent_dates")))
            )
            setLoading(false)
            return
          }
        }
        billsList[billsList.length - 1] = dataToSend
      } else {
        if (dataToSend.start_validity < billsList[billsList.length - 1].end_validity) {
          dispatch(
            addNotifications(errorNotification(t("common.bill"), t("common.incoherent_dates")))
          )
          setLoading(false)
          return
        }
        billsList.push(dataToSend)
      }
      await update(siteStore.id, { bills: billsList }, auth.userData.access_token)
        .then(() => {
          dispatch(
            addNotifications(
              successNotification(
                t("common.bill"),
                isUpdate ? t("common.update_success") : t("common.creation_success")
              )
            )
          )
          cancelForm()
          retrieve()
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification(t("common.bill"), error.message)]))
          setLoading(false)
        })
    }
  }

  const retrieve = async () => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        data.bills &&
          setItems(
            data.bills.reverse().map((bill, index) => {
              return {
                ...bill,
                id: index,
                start_validity: bill.start_validity.replace(" ", "T"),
                end_validity: bill.end_validity.replace(" ", "T")
              }
            })
          )
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.bills"), error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    {
      field: "start_validity",
      header: t("common.start_date"),
      sortable: true,
      processValue: value => new Date(value.split("T")[0]).toLocaleDateString()
    },
    {
      field: "end_validity",
      header: t("common.end_date"),
      sortable: true,
      processValue: value => new Date(value.split("T")[0]).toLocaleDateString()
    }
  ]

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    siteStore.id && retrieve()
  }, [siteStore.id])

  useEffect(() => {
    setCurrency(
      MAPPING_CURRENCY[siteStore.country] ? MAPPING_CURRENCY[siteStore.country] : DEFAULT_CURRENCY
    )
  }, [siteStore.country])

  useEffect(() => {
    if (items.length > 0) {
      setCurrentBill(items[0])
    }
  }, [items])

  const updateForm = () => {
    setShowForm(true)
    setIsUpdate(true)
    setData({
      ...items[0],
      start_validity: items[0].start_validity.split("T")[0],
      end_validity: items[0].end_validity.split("T")[0]
    })
  }

  const createForm = () => {
    setShowForm(true)
    setData({
      ...EMPTY_BILL,
      start_validity: items.length > 0 ? items[0].end_validity.split("+")[0] : undefined
    })
  }

  const cancelForm = () => {
    setShowForm(false)
    setIsUpdate(false)
    setData({
      ...EMPTY_BILL
    })
  }

  return !loading ? (
    <section className="list-section">
      {!showForm && (
        <ListActions>
          <Button
            rounded
            icon="fa-regular fa-plus"
            onClick={() => createForm()}
            label={t("common.add_bill")}
          />
          {items.length > 0 && (
            <Button
              rounded
              icon="fa-solid fa-pen"
              onClick={() => updateForm()}
              label={t("common.update_last_bill")}
            />
          )}
        </ListActions>
      )}

      {!currentBill && !showForm && <p className="text-center">{t("common.no_bill")}</p>}

      {showForm && (
        <>
          {!isUpdate && (
            <div className="base-content-title">
              <h2>{t("common.create_asset", { asset: t("common.bill").toLowerCase() })}</h2>
            </div>
          )}
          <BillForm
            data={data}
            setData={setData}
            submit={submit}
            cancelForm={cancelForm}
            currency={currency}
          />
        </>
      )}

      {currentBill && !showForm && (
        <BillResume
          bill={currentBill}
          currency={currency}
        />
      )}

      {items.length > 0 && !showForm && (
        <ListLayout
          columns={columns}
          value={items}
          loading={loading}
          selection={currentBill}
          selectionMode="single"
          setSelectedItems={selected => selected && setCurrentBill(selected)}
        />
      )}
    </section>
  ) : (
    <Loader />
  )
}
