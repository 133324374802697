import { Dropdown, SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Equipment = (
  retrieveNodes,
  getNodeValues,
  gateways,
  spaces,
  equipmentTypes,
  equipmentSubTypes,
  loadingGateways,
  loadingSpaces,
  loadingEquipments,
  loadingEquipmentSubTypes
) => {
  return {
    /*
     * List of fields for the model, like this
     */
    ...{
      name: {
        label: i18n.t("common.name"),
        field: InputText,
        options: {
          required: true,
          keyfilter: /[\w\d\-_]/,
          maxLength: 255
        }
      },
      description: {
        label: i18n.t("common.description"),
        field: InputTextarea,
        options: {}
      },
      equip_comm_info: {
        node: {
          label: i18n.t("common.node"),
          field: SelectArray,
          options: {
            getItems: retrieveNodes,
            getAllValues: getNodeValues,
            keyLabel: "name",
            required: true,
            columns: [
              {
                field: "name",
                header: i18n.t("common.name"),
                sortable: true
              },
              {
                field: "model",
                header: i18n.t("common.model"),
                sortable: true
              },
              {
                field: "manufacturer",
                header: i18n.t("common.manufacturer"),
                sortable: true
              },
              {
                field: "type",
                header: i18n.t("common.protocol"),
                sortable: true
              },
              {
                field: "ip_addresses",
                header: i18n.t("common.ip_addresses"),
                sortable: true
              }
            ]
          }
        },
        gateway: {
          label: i18n.t("common.gateway"),
          field: Dropdown,
          options: {
            required: true,
            options: gateways,
            optionLabel: "name",
            optionValue: "id",
            filter: true,
            loading: loadingGateways,
            placeholder: loadingGateways
              ? i18n.t("common.loading")
              : i18n.t("common.select_a", {
                  value: i18n.t("common.gateway").toLowerCase()
                })
          }
        }
      },
      space: {
        label: i18n.t("common.space"),
        field: Dropdown,
        options: {
          options: spaces,
          optionLabel: "name",
          optionValue: "id",
          filter: true,
          loading: loadingSpaces,
          placeholder: loadingSpaces
            ? i18n.t("common.loading")
            : i18n.t("common.select_a", {
                value: i18n.t("common.space").toLowerCase()
              })
        }
      },
      equipment_type: {
        label: i18n.t("common.equipment_type"),
        field: Dropdown,
        options: {
          options: equipmentTypes,
          optionLabel: "name",
          optionValue: "id",
          loading: loadingEquipments,
          placeholder: loadingEquipments
            ? i18n.t("common.loading")
            : i18n.t("common.select_an", {
                value: i18n.t("common.equipment_type").toLowerCase()
              }),
          filter: true
        }
      }
    },
    ...(equipmentSubTypes.length > 0
      ? {
          equipment_subtype: {
            label: i18n.t("common.equipment_sub_type"),
            field: Dropdown,
            options: {
              options: equipmentSubTypes,
              optionLabel: "name",
              optionValue: "id",
              loading: loadingEquipmentSubTypes,
              placeholder: loadingEquipmentSubTypes
                ? i18n.t("common.loading")
                : i18n.t("common.select_an", {
                    value: i18n.t("common.equipment_sub_type").toLowerCase()
                  }),
              filter: true
            }
          }
        }
      : {})
  }
}

export const EquipmentOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("common.name") },
    description: { label: i18n.t("common.description") },
    node_name: { label: i18n.t("common.node") },
    gateway_name: { label: i18n.t("common.gateway") },
    equipment_type_name: { label: i18n.t("common.equipment_type") },
    equipment_subtype_name: { label: i18n.t("common.equipment_sub_type") },
    space_name: { label: i18n.t("common.space") }
  }
}
