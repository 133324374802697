import { EquipmentRoutesContext } from "/apps/assets/building/equipment/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [processing, setProcessing] = useState(false)
  const { equipment } = useContext(EquipmentRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(siteStore.id, equipment.id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification(t("common.equipment"), t("common.delete_success"))
        ])
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/`
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification(t("common.equipment"), message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    equipment && (
      <DeleteConfirmation
        objectType={t("common.equipment")}
        objectNames={[equipment.name]}
        remove={removeObject}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}
