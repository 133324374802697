import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { update } from "../api"
import { Organization as OrganizationModel } from "../models"
import LogoInput from "./LogoInput"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(organizationStore.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([
            successNotification(t("common.organization"), t("common.update_success"))
          ])
        )
        navigate("/")
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification(t("common.organization"), message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (organizationStore.id) {
      setData(organizationStore)
    }
  }, [organizationStore.id])

  return (
    data && (
      <BaseLayout title={data.name}>
        <form
          className="form-view"
          onSubmit={updateObject}
        >
          <FormContent
            model={OrganizationModel()}
            data={data}
            setData={setData}
            errors={errors}
          />

          <LogoInput
            data={data}
            setData={setData}
          />

          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              disabled={processing}
              icon="fa-solid fa-xmark"
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              className="button"
              severity="success"
              label={t("common.save")}
              type="submit"
              disabled={processing}
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}
