export const GATEWAY_TYPES = {
  BOX: "box",
  VIRTUAL_MACHINE: "virtualmachine"
}

export const VIRTUAL_MACHINE_TYPES = {
  SENSINOV: "sensinov",
  MY_SIRIUS: "mysirius",
  SIGFOX: "sigfox",
  GRDF: "grdf",
  KOOVEA: "koovea",
  MOBILE_ALERTS: "mobilealerts"
}
