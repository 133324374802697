import { paginatedRequest } from "utils/api"
import { errorNotification, successNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { updateSideBar } from "utils/store/sidebar"
import { FormContent, BaseLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import { StateForm } from "../models"

import { useDispatch, useSelector } from "react-redux"
import { organization } from "utils/store/organization"
import { site } from "utils/store/site"

export default function Create() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)

  const [data, setData] = useState({ active: false, state_values: [] })
  const [errors, setErrors] = useState({})
  const [gateways, setGateways] = useState([])

  const create = async function (e) {
    e.preventDefault()

    const finalData = {
      ...data,
      // @todo ugly
      gateway: data.gateway.toString()
    }

    await add(siteStore.id, finalData, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([successNotification(t("common.state"), t("common.creation_success"))])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/${response.data.id}`
        )
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        const message = error?.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.state"), message)]))
      })
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

    const getGW = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(
        `connectivity/sites/${siteStore.id}/gateways/`,
        auth.userData.access_token
      )()
        .then(results => {
          setGateways(results)
        })
        .catch(error => {
          const message = error?.response?.data?.message ?? error.message
          dispatch(addNotifications([errorNotification(t("common.gateways"), message)]))
        })
    }
    getGW()
  }, [])

  return (
    data && (
      <BaseLayout title={t("common.create_asset", { asset: t("common.state").toLowerCase() })}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={StateForm(gateways)}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              severity="success"
              label={t("common.save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}
