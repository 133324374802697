import axios from "axios"
import { paginatedRequest, config } from "utils/api"

export async function createFlexContract(siteId, data, token) {
  data["site_id"] = `${siteId}`

  return await axios.post(
    `${window.BACKEND_FLEX_URL}/flex/sites/${siteId}/contracts/`,
    data,
    config({ token })
  )
}

export async function deleteFlexContract(siteId, contractId, token) {
  return await axios.delete(
    `${window.BACKEND_FLEX_URL}/flex/sites/${siteId}/contracts/${contractId}`,
    config({ token })
  )
}

// update only the curtain part of the contract
export async function updateFlexContract(siteId, contractId, data, token) {
  return await axios.put(
    `${window.BACKEND_FLEX_URL}/flex/sites/${siteId}/contracts/${contractId}`,
    data,
    config({ token })
  )
}

export async function retrieveFlexContratBySite(siteId, token) {
  return await axios.get(
    `${window.BACKEND_FLEX_URL}/flex/sites/${siteId}/contracts/`,
    config({ token })
  )
}

export async function retrieveFlexContratByContractId(siteId, contractId, token) {
  return await axios.get(
    `${window.BACKEND_FLEX_URL}/flex/sites/${siteId}/contracts/${contractId}`,
    config({ token })
  )
}

export function getAllEnergyDeliveryPointsOfSite(siteId, accessToken) {
  // TODO: remove when the api will no longer paginate
  return paginatedRequest(`energy/sites/${siteId}/energy_delivery_points/`, accessToken)()
}

export function getAllEnergyAggregatorsCompanies(accessToken) {
  // TODO: remove when the api will no longer paginate
  return paginatedRequest("energy/energy_companies/?company_type=Aggregator", accessToken)()
}

export function getAllGatewayOfSite(siteId, accessToken) {
  // TODO: remove when the api will no longer paginate
  return paginatedRequest(`connectivity/sites/${siteId}/gateways/`, accessToken)()
}
