{
  "Active": "Actif",
  "Comment": "Commentaire",
  "flex_contract": {
    "create_flex_contract_button_label": "Create a flex contract",
    "errors": {
      "delete": "An error occurred while deleting the flex contract. Please contact an administrator.",
      "fetch": "An error occurred while fetching the flex contract. Please contact an administrator."
    },
    "form": {
      "button": {
        "gateway_missing": "Ajouter une gateway"
      },
      "buttons": {
        "energy_delivery_point_missing": "Ajouter un point de livraison d'énergie"
      },
      "erros": {
        "energy_delivery_point_missing": "No Energy delivery point found, please configure one to be able to create a flex contract.",
        "gateway_missing": "No Gateway found, please configure one to be able to create a flex contract."
      },
      "fields": {
        "active": {
          "label": "Active"
        },
        "comment": {
          "label": "Comment"
        },
        "control_method": {
          "label": "Contract type",
          "values": {
            "rectangle": "AOFD + NEBEF Up Rectangle (Double Referencer Corrected)"
          }
        },
        "end_validity": {
          "errors": {
            "smaller_than_start_validity": "End validity date must be after than start validity date."
          },
          "label": "End validity"
        },
        "energy_company": {
          "label": "Market player"
        },
        "energy_delivery_point": {
          "label": "Energy delivery point"
        },
        "max_schedules_per_day": {
          "label": "Max schedules per day"
        },
        "neutralization_duration": {
          "label": "Neutralization duration (seconds)"
        },
        "optimization_gateways": {
          "label": "Optimization gateway"
        },
        "schedule_duration": {
          "label": "Curtail duration (seconds)"
        },
        "start_validity": {
          "errors": {
            "greater_than_end_validity": "Start validity date must be before than end validity date."
          },
          "label": "Start validity"
        }
      },
      "messages": {
        "creation_success": "Flex contract has been created.",
        "delete_success": "Flex contract has been deleted.",
        "update_successful": "Flex contract has been updated."
      },
      "titles": {
        "contract_part": "Flex contract",
        "curtails_part": "Curtail parameters",
        "optimization_part": "Optimization"
      }
    },
    "model": {
      "name": "Flex contract"
    },
    "title": "Flex"
  },
  "Flexibility": "Flexibilité",
  "label_cancel": "Annuler",
  "label_comment": "Commentaire",
  "label_configuration": "Configuration",
  "label_create_button": "Créer",
  "label_edge_controller": "Edge controller",
  "label_export_button": "Exporter",
  "label_gateway": "Gateway",
  "label_import_button": "Importer",
  "label_modified_at": "Modifié à",
  "label_not_selected_gateway": "Selectionner une gateway",
  "label_not_selected_type": "Selectionner un type",
  "label_save_button": "Sauvegarder",
  "label_tab_curtail_schedules": "Créneaux de réduction",
  "label_type": "Type",
  "label_updated_by": "Mis à jour par",
  "Market player interaction": "Market player interaction",
  "message_error_configuration": "Il y a des erreurs dans la configuration",
  "notification_create": "Création",
  "notification_delete": "Suppression",
  "notification_edge_controller_created": "Le edge controller a été créé.",
  "notification_edge_controller_deleted": "Le edge controller a été supprimé",
  "notification_edge_controller_updated": "Le edge controller a été mis à jour",
  "notification_error": "Erreur",
  "notification_update": "Mise à jour",
  "overview_tab_label": "Overview",
  "Parameters": "Parameters",
  "Section": "Section",
  "title_create_edge_controller": "Création d'un edge controller",
  "title_edge_controller_list": "Edge controllers"
}
