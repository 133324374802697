import { DateInput, Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

const EnergyDeliveryPoint = (
  distributors,
  transmissionCompanies,
  loadingDistributions = false,
  loadingTransmissions = false
) => ({
  /*
   * List of fields for the model, like this
   */
  meter_id: {
    label: i18n.t("common.meter_id"),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  energy_type: {
    label: i18n.t("common.type"),
    field: Dropdown,
    options: {
      required: true,
      placeholder: i18n.t("common.select", { value: i18n.t("common.type") }),
      options: [
        { label: i18n.t("common.gas"), value: "Gas" },
        { label: i18n.t("common.electricity"), value: "Electricity" }
      ]
    }
  },
  energy_company: {
    label: i18n.t("common.distributor"),
    field: Dropdown,
    options: {
      placeholder: loadingDistributions
        ? i18n.t("common.loading")
        : i18n.t("common.select", {
            value: i18n.t("common.distributor").toLowerCase()
          }),
      options: distributors,
      optionLabel: "name",
      optionValue: "id",
      filter: distributors && distributors.length > 20,
      loading: loadingDistributions
    }
  },
  transmission_energy_company: {
    label: i18n.t("common.transmission_company"),
    field: Dropdown,
    options: {
      placeholder: loadingTransmissions
        ? i18n.t("common.loading")
        : i18n.t("common.select", {
            value: i18n.t("common.transmission_company").toLowerCase()
          }),
      options: transmissionCompanies,
      optionLabel: "name",
      optionValue: "id",
      filter: transmissionCompanies && transmissionCompanies.length > 20,
      loading: loadingTransmissions
    }
  },
  description: {
    label: i18n.t("common.description"),
    field: InputTextarea,
    options: {}
  },
  start_validity: {
    label: i18n.t("common.start_validity"),
    field: DateInput,
    options: {}
  },
  end_validity: {
    label: i18n.t("common.end_validity"),
    field: DateInput,
    options: {}
  },
  equivalent_metering_intercept: {
    label: i18n.t("common.equivalent_metering_intercept"),
    field: InputNumber,
    options: { showButtons: true }
  },
  data_step: {
    label: i18n.t("common.data_step"),
    field: InputNumber,
    options: { showButtons: true }
  }
})

const EnergyDeliveryPointOverview = () => ({
  meter_id: { label: i18n.t("common.meter_id") },
  energy_type: { label: i18n.t("common.type") },
  energy_company_name: { label: i18n.t("common.distributor") },
  transmission_energy_company: { label: i18n.t("common.transmission_company") },
  description: { label: i18n.t("common.description") },
  start_validity: { label: i18n.t("common.start_validity") },
  end_validity: { label: i18n.t("common.end_validity") },
  equivalent_metering_intercept: {
    label: i18n.t("common.equivalent_metering_intercept")
  },
  equivalent_metering_equation: { label: i18n.t("common.equivalent_metering_equation") },
  data_step: { label: i18n.t("common.data_step") }
})

const EquivalentMeteringTerm = (points, edit, loading) => ({
  gatewayPoint: {
    label: i18n.t("common.point"),
    field: Dropdown,
    options: {
      required: true,
      disabled: edit,
      options: points,
      optionLabel: "ext_name",
      optionValue: "id",
      filter: points.length && points.length > 20,
      placeholder: loading
        ? i18n.t("common.loading")
        : i18n.t("common.select_a", {
            value: i18n.t("common.point").toLowerCase()
          }),
      loading
    }
  },
  coef: {
    label: i18n.t("common.coef"),
    field: InputNumber,
    options: {
      required: true,
      showButtons: true
    }
  }
})

export { EnergyDeliveryPoint, EnergyDeliveryPointOverview, EquivalentMeteringTerm }
