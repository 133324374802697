import { retrieve } from "/apps/assets/electrical/circuit-breaker/api"
import Create from "/apps/assets/electrical/circuit-breaker/components/Create"
import Delete from "/apps/assets/electrical/circuit-breaker/components/Delete"
import Detail from "/apps/assets/electrical/circuit-breaker/components/Detail"
import List from "/apps/assets/electrical/circuit-breaker/components/List"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader, InnerTabsLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import { organization } from "/utils/store/organization"
import { useTranslation } from "react-i18next"
import CircuitBreakerRelationRoutes from "../circuit-breaker-relation/routes"

const CircuitBreakerRouteContext = createContext(null)

const CircuitBreakerContent = () => {
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const auth = useAuth()
  const { circuitBreakerId } = useParams()
  const [circuitBreaker, setCircuitBreaker] = useState()
  const [tabs, setTabs] = useState()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, circuitBreakerId, auth.userData.access_token)
      .then(response => {
        setCircuitBreaker(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.circuit_breaker"), message)]))
      })
  }

  useEffect(() => {
    if (siteStore.id && circuitBreakerId) {
      setTabs([
        {
          name: t("common.overview"),
          active:
            location.pathname.endsWith(`/circuit-breakers/${circuitBreakerId}/`) ||
            location.pathname.endsWith(`/circuit-breakers/${circuitBreakerId}/delete/`),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreakerId}/`
        },
        {
          name: t("common.linked_equipments"),
          active: location.pathname.includes(
            `/circuit-breakers/${circuitBreakerId}/circuit-breaker-relations/`
          ),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreakerId}/circuit-breaker-relations/`
        }
      ])
      retrieveDetail()
    }
  }, [circuitBreakerId, siteStore.id, organizationStore.id])

  return (
    <CircuitBreakerRouteContext.Provider value={{ circuitBreaker, retrieveDetail }}>
      {circuitBreaker ? (
        <InnerTabsLayout
          title={circuitBreaker.name}
          tabs={tabs}
          setTabs={setTabs}
        />
      ) : (
        <Loader />
      )}
    </CircuitBreakerRouteContext.Provider>
  )
}

const CircuitBreakerRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":circuitBreakerId"
        element={<CircuitBreakerContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="circuit-breaker-relations/*"
          element={<CircuitBreakerRelationRoutes />}
        />
      </Route>
    </Routes>
  )
}

export { CircuitBreakerRouteContext }
export default CircuitBreakerRoutes
