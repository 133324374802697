import React from "react"
import { useTranslation } from "react-i18next"
import { DateInput, ButtonWithLoader } from "@software-engineering/hivolution-frontend-utils"
import { Button } from "primereact/button"
import BillFixPriceFields from "./BillFixPriceFields"
import BillSchedulePriceFields from "./BillSchedulePriceFields"
import { BILL_PRICE_TYPES } from "/utils/constants/bill"

const DEFAULT_FIX_PRICE = {
  name: "",
  price_type: BILL_PRICE_TYPES.FIX,
  value: 0
}

const DEFAULT_SCHEDULE_PRICE = {
  name: "",
  price_type: BILL_PRICE_TYPES.SCHEDULE,
  value: {
    HCE: 0,
    HCH: 0,
    HPE: 0,
    HPH: 0,
    Pointe: 0
  }
}

export default function BillForm(props) {
  const { t } = useTranslation()

  const updateData = (key, value) => {
    props.setData({
      ...props.data,
      [key]: value
    })
  }

  const addPrice = priceType => {
    props.setData({
      ...props.data,
      prices: [
        ...props.data.prices,
        priceType === BILL_PRICE_TYPES.FIX ? DEFAULT_FIX_PRICE : DEFAULT_SCHEDULE_PRICE
      ]
    })
  }

  const removePrice = index => {
    const prices = [...props.data.prices]
    prices.splice(index, 1)
    props.setData({
      ...props.data,
      prices
    })
  }

  const setPrice = (index, newPrice) => {
    const prices = [...props.data.prices]
    prices[index] = newPrice
    props.setData({
      ...props.data,
      prices
    })
  }

  return (
    <form
      className="form-view my-5"
      onSubmit={props.submit}
    >
      <div className="flex gap-4 w-full">
        <div className="field-container flex-grow-1">
          <div className="field datetimeinput">
            <label className="label label-required">{t("common.start_date")}</label>
            <div className="field-input">
              <DateInput
                required={true}
                value={props.data.start_validity}
                onChange={e => updateData("start_validity", e.value)}
              />
            </div>
          </div>
        </div>

        <div className="field-container flex-grow-1">
          <div className="field datetimeinput">
            <label className="label label-required">{t("common.end_date")}</label>
            <div className="field-input">
              <DateInput
                required={true}
                value={props.data.end_validity}
                min={props.data.start_validity}
                onChange={e => updateData("end_validity", e.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="prices-fields">
        {props.data.prices.map((price, index) => (
          <div
            key={index}
            className="price-fields flex flex-column"
          >
            {price.price_type === BILL_PRICE_TYPES.FIX && (
              <BillFixPriceFields
                price={price}
                setPrice={setPrice}
                currency={props.currency}
                index={index}
                removePrice={removePrice}
              />
            )}

            {price.price_type === BILL_PRICE_TYPES.SCHEDULE && (
              <BillSchedulePriceFields
                price={price}
                setPrice={setPrice}
                currency={props.currency}
                index={index}
                removePrice={removePrice}
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex gap-4">
        <Button
          rounded
          type="button"
          onClick={() => addPrice(BILL_PRICE_TYPES.FIX)}
          icon="fa-solid fa-plus"
          label={t("common.add_fix_price")}
        />
        <Button
          rounded
          type="button"
          onClick={() => addPrice(BILL_PRICE_TYPES.SCHEDULE)}
          icon="fa-solid fa-plus"
          label={t("common.add_schedule_price")}
        />
      </div>

      <div className="action-buttons-wrapper">
        <Button
          rounded
          outlined
          severity="secondary"
          label={t("common.cancel")}
          icon="fa-solid fa-xmark"
          onClick={props.cancelForm}
        />
        <ButtonWithLoader
          rounded
          severity="success"
          label={t("common.save")}
          type="submit"
          icon="fa-solid fa-floppy-disk"
        />
      </div>
    </form>
  )
}
