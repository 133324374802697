import DefaultLogo from "/static/svg/logo.svg"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { resetOrganization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { resetSite } from "/utils/store/site"
import {
  BaseLayout,
  CopyButton,
  ListActions,
  ListLayout,
  Loader
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { TabPanel, TabView } from "primereact/tabview"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { getAllValues, retrieveList } from "../api"

const CardsLayout = props => {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a list of cards.
   * It is placed in a TabList.
   *
   * @component
   * @example
   *
   * export default function List () {
   *   const [activeIndex, setActiveIndex] = useState(0)
   *   const items = [{label: "Cards"}, ...]
   *   return (
   *     <>
   *       <section>
   *         <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
   *         {activeIndex === 0 && <CardsLayout />}
   *         ...
   *       </section>
   *     </>
   *   )
   * }
   *
   *
   */
  const { t } = useTranslation()

  return props.loading ? (
    <Loader />
  ) : (
    <div className="organizations-cards">
      {props.items &&
        props.items.map(organization => (
          <div
            key={organization.id}
            className="organization-card"
          >
            <div className="organization-card-actions">
              <CopyButton
                isUrl
                icon="fa-solid fa-link"
                value={`/organizations/${organization.id}/`}
              />
              <Link to={`/organizations/${organization.id}/`}>
                <Button
                  rounded
                  text
                  raised
                  aria-label="Edit"
                  icon="fa-solid fa-pen"
                />
              </Link>
              <Link to={`/organizations/${organization.id}/delete`}>
                <Button
                  rounded
                  text
                  raised
                  aria-label={t("common.delete")}
                  icon="fa-regular fa-trash-can"
                />
              </Link>
            </div>
            <Link to={`/organizations/${organization.id}/sites/`}>
              {organization.logo ? (
                <div
                  className="organization-card-logo"
                  dangerouslySetInnerHTML={{ __html: organization.logo }}
                />
              ) : (
                <div className="organization-card-logo">
                  <img src={DefaultLogo} />
                </div>
              )}

              <p className="organization-card-title">{organization.name}</p>
              <div className="organization-card-infos">
                <p>{t("common.sites_count", { count: organization.sites_count })}</p>
              </div>
            </Link>
          </div>
        ))}
    </div>
  )
}

const TableLayout = props => {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in a TabList.
   *
   * @component
   * @example
   *
   * export default function List () {
   *   const [activeIndex, setActiveIndex] = useState(0)
   *   const items = [{label: "Table"}, ...]
   *   return (
   *     <>
   *       <section>
   *         <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
   *         {activeIndex === 0 && <TableLayout />}
   *         ...
   *       </section>
   *     </>
   *   )
   * }
   *
   *
   */

  const actionsBodyTemplate = object => {
    return (
      <div className="p-datatable-action-buttons">
        <CopyButton
          isUrl
          icon="fa-solid fa-link"
          value={`/organizations/${object.id}/sites/`}
        />

        <Link
          className="update-button"
          to={`/organizations/${object.id}/`}
        >
          <i className="fa-solid fa-pen" />
        </Link>

        <Link
          className="delete-button"
          to={`/organizations/${object.id}/delete/`}
        >
          <i className="fa-regular fa-trash-can" />
        </Link>
      </div>
    )
  }

  const navigate = useNavigate()
  const { t } = useTranslation()

  const columns = [
    { field: "name", header: t("common.name") },
    { field: "sites_count", header: t("common.number_of_sites") }
  ]

  const onRowClick = objectId => {
    navigate(`/organizations/${objectId}/sites/`)
  }

  return (
    props.items && (
      <ListLayout
        columns={columns}
        value={props.items}
        onRowClick={onRowClick}
        actionsBodyTemplate={actionsBodyTemplate}
        loading={props.loading}
      />
    )
  )
}

export default function TabList() {
  /*
   * This component work with PrimeReact
   * It display a TabMenu component with one or more <componentLayout>.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<TabList />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth = useAuth()
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const retrieve = async (queryParams = {}) => {
    setLoading(true)
    await retrieveList(auth.userData.access_token, queryParams)
      .then(response => {
        response.data && response.data.results && setOrganizations(response.data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.organizations"), message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(auth.userData.access_token)
      .then(({ data }) => {
        setFilterValues(data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.filter_values"), error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const queryParams = () => {
    return {
      ...filters
    }
  }

  const columnsFilter = [{ field: "name", header: t("common.name") }]

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  const onTabChange = e => {
    setActiveTab(e.index)
    setFilters({})
  }

  useEffect(() => {
    dispatch(resetSite())
    dispatch(resetOrganization())
    dispatch(updateSideBar({ menuName: null, siteId: null }))
    getFilterValues()
    retrieve()
  }, [])

  useEffect(() => {
    retrieve(queryParams())
  }, [filters])

  return (
    <BaseLayout title={t("common.organizations")}>
      <TabView
        activeIndex={activeTab}
        onTabChange={onTabChange}
      >
        <TabPanel header={t("common.cards")}>
          <ListActions
            filterBackend={true}
            filters={menuFilters}
            loading={loadingFilters}
          >
            <Link to="/organizations/create/">
              <Button
                rounded
                severity="info"
                icon="fa-regular fa-plus"
                label={t("common.create")}
              />
            </Link>
          </ListActions>
          <CardsLayout
            items={organizations}
            loading={loading}
          />
        </TabPanel>
        <TabPanel header={t("common.list")}>
          <ListActions
            filterBackend={true}
            filters={menuFilters}
            loading={loadingFilters}
          >
            <Link to="/organizations/create/">
              <Button
                rounded
                severity="info"
                icon="fa-regular fa-plus"
                label={t("common.create")}
              />
            </Link>
          </ListActions>
          <TableLayout
            items={organizations}
            loading={loading}
          />
        </TabPanel>
      </TabView>
    </BaseLayout>
  )
}
