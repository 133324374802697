import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"

export const SMIO = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      point: {
        label: i18n.t("common.point"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          showButtons: true
        }
      },
      mod: {
        label: i18n.t("common.mod"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          showButtons: true
        }
      }
    }
  }
}

export const SMIOOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      point: { label: i18n.t("common.point") },
      mod: { label: i18n.t("common.mod") }
    }
  }
}

export const SMMeter = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      tag: {
        label: i18n.t("common.tag"),
        field: InputText,
        options: { maxLength: 255 }
      }
    }
  }
}

export const SMMeterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      tag: { label: i18n.t("common.tag"), options: { maxLength: 255 } }
    }
  }
}

export const SMController = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      writable: {
        label: i18n.t("common.writable"),
        field: InputSwitch,
        options: {}
      },
      tag: {
        label: i18n.t("common.tag"),
        field: InputText,
        options: { maxLength: 255 }
      },
      cid: {
        label: i18n.t("common.cid"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      },
      vid: {
        label: i18n.t("common.vid"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true
        }
      }
    }
  }
}

export const SMControllerOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      writable: { label: i18n.t("common.writable") },
      tag: { label: i18n.t("common.tag") },
      cid: { label: i18n.t("common.cid") },
      vid: { label: i18n.t("common.vid") }
    }
  }
}
