import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation } from "react-router-dom"

import BoxRoutes from "./box/routes"
import UpdateSoftwareRoutes from "./update-software/routes"

export default function GatewayRoutes() {
  /* Tabs to ListLayout component*/
  const location = useLocation()
  const { t } = useTranslation()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        name: t("common.boxes"),
        active: location.pathname.includes("boxes"),
        url: "/configuration/gateways/boxes/"
      },
      {
        name: t("common.update"),
        active: location.pathname.includes("update-software"),
        url: "/configuration/gateways/update-software/"
      }
    ])
  }, [location])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("common.gateways")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="boxes/*"
          element={<BoxRoutes />}
        />
        <Route
          index
          path="update-software/*"
          element={<UpdateSoftwareRoutes />}
        />
      </Route>
    </Routes>
  )
}
