import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "utils/constants/api"
import { errorNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { updateSideBar } from "utils/store/sidebar"
import { site } from "utils/store/site"
import {
  BaseLayout,
  ListActions,
  ListLayout
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getAllValues, retrieveList } from "../api"
import { getEventStateLabels, getEventTypeLabels } from "utils/constants/event"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(null)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const EVENT_TYPE_LABELS = getEventTypeLabels()
  const EVENT_STATE_LABELS = getEventStateLabels()

  const columns = [
    {
      field: "request_type",
      header: t("common.request_type"),
      sortable: true,
      processValue: type => EVENT_TYPE_LABELS[type] ?? type
    },
    {
      field: "state",
      header: t("common.state"),
      sortable: true,
      processValue: value => EVENT_STATE_LABELS[value] ?? value
    },
    { field: "user", header: t("common.user"), sortable: true },
    {
      field: "start_time",
      header: t("common.start_date"),
      sortable: true,
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    {
      field: "end_time",
      header: t("common.end_date"),
      sortable: true,
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    { field: "info", header: t("common.infos") }
  ]

  const columnsFilter = [
    { field: "request_type", header: t("common.request_type") },
    { field: "state", header: t("common.state") },
    { field: "user", header: t("common.user") },
    { field: "start_time", header: t("common.start_date") },
    { field: "end_time", header: t("common.end_date") }
  ]

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)

    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.results && setItems(data.results)
        data.count && setCount(data.count)
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message

        dispatch(addNotifications([errorNotification(t("common.event"), message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues({
          ...response.data,
          request_type: Array.from(
            new Set(
              response.data.request_type.map(type => {
                return {
                  label: EVENT_TYPE_LABELS[type] ?? type,
                  value: type
                }
              })
            )
          ),
          state: Array.from(
            new Set(
              response.data.state.map(state => {
                return {
                  label: EVENT_STATE_LABELS[state] ?? state,
                  value: state
                }
              })
            )
          ),
          start_time: Array.from(
            new Set(
              response.data.start_time.map(time => {
                return {
                  label: new Date(time).toLocaleString(),
                  value: time.split(" ")[0]
                }
              })
            )
          ),
          end_time: Array.from(
            new Set(
              response.data.end_time.map(time => {
                return {
                  label: new Date(time).toLocaleString(),
                  value: time.split(" ")[0]
                }
              })
            )
          )
        })
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.event"), error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    siteStore.id && getFilterValues()
  }, [siteStore.id])

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id &&
      (offset !== REQUEST_DEFAULT_OFFSET
        ? setOffset(REQUEST_DEFAULT_OFFSET)
        : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && offset !== null && retrieve(queryParams())
  }, [limit, offset])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <BaseLayout title={t("common.events")}>
      <section className="list-section">
        <ListActions
          filterBackend={true}
          filters={menuFilters}
          loading={loadingFilters}
        ></ListActions>

        <ListLayout
          columns={columns}
          value={items}
          loading={loading}
          filters={filters}
          offset={offset}
          limit={limit}
          setOffset={setOffset}
          setLimit={setLimit}
          count={count}
          onSort={onSort}
          sortOrder={sortOrder}
          sortField={sortField}
          backendSort={true}
        />
      </section>
    </BaseLayout>
  )
}
