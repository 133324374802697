import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "utils/constants/api"
import { errorNotification, successNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useRef, useState, useContext } from "react"
import { SortOrder } from "primereact/api"
import { StateRoutesContext } from "apps/optimization/state/routes"

import { site } from "utils/store/site"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { updateSideBar } from "utils/store/sidebar"
import { StateValue } from "../models"
import { retrieveList, exportStateValues, importStateValues, getAllValues } from "../api"
import fileDownload from "js-file-download"
import { STATE_TYPES } from "utils/constants/state"

function getStateValuesFieldFilters() {
  /**
   * Return the fields of the StateValue model with the following format:
   * {
   *  FIELD_NAME: [],
   *  ...
   * }
   */

  const fieldNames = Object.keys(StateValue())
  return fieldNames.reduce((acc, fieldName) => {
    acc[fieldName] = []
    return acc
  }, {})
}

export default function List() {
  const siteStore = useSelector(site)
  const { stateId } = useParams()
  const { state } = useContext(StateRoutesContext)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [batchImportMode, setBatchImportMode] = useState(true)
  const hiddenFileInput = useRef(null)

  // Filters
  // loading for filters values : retrieve all values for the filters
  const [loadingFilters, setLoadingFilters] = useState(false)
  // filter on different fields (batch, point, value)
  const [filters, setFilters] = useState({})
  // filter values available
  const [filterValues, setFilterValues] = useState(getStateValuesFieldFilters())

  // pagination and ordering
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(null)
  const [sortField, setSortField] = useState(null)
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC)
  const [count, setCount] = useState(0)

  const buildQueryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  const columns = [
    { field: "point", header: t("common.point"), sortable: true },
    {
      field: "batch",
      header: t("common.batch"),
      sortable: true,
      processValue: value => `${value ? t("common.true") : t("common.false")}`
    },
    { field: "value", header: t("common.value"), sortable: true }
  ]

  const columnsFilter = [
    { field: "point", header: t("common.point") },
    { field: "batch", header: t("common.batch") },
    { field: "value", header: t("common.value") }
  ]

  const getExport = async batch => {
    await exportStateValues(siteStore.id, stateId, batch, auth.userData.access_token)
      .then(response => {
        const fileName = response.headers["content-disposition"].split("filename=")[1]
        fileDownload(response.data, fileName.slice(1, -1))
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.export"), message)]))
      })
  }

  const postImport = async () => {
    if (hiddenFileInput.current.files.length > 0) {
      const file = hiddenFileInput.current.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = async e => {
        const jsonContent = e.target.result
        await importStateValues(
          siteStore.id,
          stateId,
          jsonContent,
          batchImportMode,
          auth.userData.access_token
        )
          .then(response => {
            dispatch(
              addNotifications([
                successNotification(t("common.import"), t("common.import_success"))
              ])
            )
            setFilters({
              batch: batchImportMode
            })
            if (response.data && response.data.results) {
              updateItems(response.data)
            }
          })
          .catch(error => {
            // Should be handled at another place with a global error handler for pydantic errors
            if (error.response && error.response.status === 422) {
              const details = error.response.data.detail
              let msg = ""
              for (const error of details) {
                if (error.loc.length > 0) {
                  const lastLoc = error.loc[error.loc.length - 1]
                  msg += `${lastLoc}: ${error.msg}\n`
                }
              }
              dispatch(
                addNotifications([
                  errorNotification(t("common.import"), msg.length > 0 ? msg : error.message)
                ])
              )
            } else {
              dispatch(
                addNotifications([
                  errorNotification(
                    t("common.import"),
                    error.response !== undefined ? error.response.data.message : error.message
                  )
                ])
              )
            }
          })
      }
    }
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, stateId, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.filter_values"), message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const updateItems = data => {
    setItems(
      data.results.map(item => {
        // add the "id" (key) for Table component
        return { ...item, id: item.point }
      })
    )
    setCount(data.count)
  }
  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const retrieve = async queryParams => {
    setLoading(true)

    await retrieveList(siteStore.id, stateId, queryParams, auth.userData.access_token)
      .then(response => {
        if (response.data && response.data.results) {
          updateItems(response.data)
        }
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.state_value"), message)]))
      })
      .finally(() => setLoading(false))
  }

  const switchImportModeAndDisplayInput = importMode => {
    setBatchImportMode(importMode)
    hiddenFileInput?.current?.click()
  }

  const getMenuEntries = () => {
    const exportItems = [
      {
        label: t("common.export_without_batch"),
        command: () => getExport(false)
      }
    ]
    const importItems = [
      {
        label: t("common.import_without_batch"),
        command: () => switchImportModeAndDisplayInput(false)
      }
    ]

    if (state.type === STATE_TYPES.INHIBIT) {
      exportItems.push({
        label: t("common.export_with_batch"),
        command: () => getExport(true)
      })
      importItems.push({
        label: t("common.import_with_batch"),
        command: () => switchImportModeAndDisplayInput(true)
      })
    }

    return [
      {
        label: t("common.export_button"),
        icon: "fa-solid fa-file-export",
        items: exportItems
      },
      {
        label: t("common.import_button"),
        icon: "fa-solid fa-file-import",
        items: importItems
      }
    ]
  }

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  useEffect(() => {
    siteStore.id && getFilterValues()
  }, [siteStore.id])

  // Update of the site bar if the siteStore.id change
  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      (offset !== REQUEST_DEFAULT_OFFSET
        ? setOffset(REQUEST_DEFAULT_OFFSET)
        : retrieve(buildQueryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && offset !== null && retrieve(buildQueryParams())
  }, [limit, offset])

  return (
    <section className="list-section">
      <ListActions
        entries={getMenuEntries()}
        filters={menuFilters}
        filterBackend={true}
        loading={loadingFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          accept="application/JSON"
          onChange={postImport}
        />
      </ListActions>
      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        filters={filters}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </section>
  )
}
