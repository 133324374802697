import { i18nextOptions } from "/i18next.config"
import assetsTranslationEN from "/locales/assets/en.json"
import assetsTranslationFR from "/locales/assets/fr.json"
import commonTranslationEN from "/locales/common/en.json"
import commonTranslationFR from "/locales/common/fr.json"
import configurationTranslationEN from "/locales/configuration/en.json"
import configurationTranslationFR from "/locales/configuration/fr.json"
import energyTranslationEN from "/locales/energy/en.json"
import energyTranslationFR from "/locales/energy/fr.json"
import optimizationTranslationEN from "/locales/optimization/en.json"
import optimizationTranslationFR from "/locales/optimization/fr.json"
import {
  componentsTranslationEN,
  componentsTranslationFR
} from "@software-engineering/hivolution-frontend-utils"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
  en: {
    assets: assetsTranslationEN,
    common: commonTranslationEN,
    components: componentsTranslationEN,
    configuration: configurationTranslationEN,
    optimization: optimizationTranslationEN,
    energy: energyTranslationEN
  },
  fr: {
    assets: assetsTranslationFR,
    common: commonTranslationFR,
    components: componentsTranslationFR,
    configuration: configurationTranslationFR,
    optimization: optimizationTranslationFR,
    energy: energyTranslationFR
  }
}

i18n.use(initReactI18next).init({
  ...i18nextOptions,
  resources
})
