import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getSMAllValues, getSMNodes, getSpaces, retrieveList } from "../api"
import { BacnetNode } from "../models/bacnet"
import { CarelNode } from "../models/carel"
import { CarelNetworkNode } from "../models/carelnetwork"
import { ExternalAPINode } from "../models/externalapi"
import { ModbusNode } from "../models/modbus"
import { OPCUANode } from "../models/opcua"
import { SMNode } from "../models/sm"
import { SMControllerNode } from "../models/smcontroller"
import { SMIONode } from "../models/smio"
import { SMMeterNode } from "../models/smmeter"
import { NODE_DEFAULT_VALUES, NODE_TYPES } from "/utils/constants/node"
import { handleErrors } from "/utils/error"

const SelectType = ({ data, setData }) => {
  const { t } = useTranslation()

  const [type, setType] = useState(null)
  const types = [
    { value: NODE_TYPES.MODBUS, label: t("common.modbus") },
    { value: NODE_TYPES.BACNET, label: t("common.bacnet") },
    { value: NODE_TYPES.SM_NETWORK, label: t("common.aksm_network_node") },
    { value: NODE_TYPES.SM, label: t("common.aksm_node") },
    { value: NODE_TYPES.OPCUA, label: t("common.opcua_node") },
    { value: NODE_TYPES.CAREL, label: t("common.carel_node") },
    { value: NODE_TYPES.CAREL_NETWORK, label: t("common.carel_network_node") },
    { value: NODE_TYPES.EXTERNAL_API, label: t("common.external_api_node") }
  ]
  const [subTypeList, setSubTypeList] = useState([])
  const [subType, setSubType] = useState(null)
  const smnodeSubTypes = [
    { value: NODE_TYPES.SM_IO, label: t("common.aksm_io_node") },
    { value: NODE_TYPES.SM_METER, label: t("common.aksm_meter_node") },
    { value: NODE_TYPES.SM_CONTROLLER, label: t("common.aksm_controller_node") }
  ]

  const updateType = value => {
    setType(value)
    setSubType(null)

    if (value === NODE_TYPES.SM_NETWORK) {
      setSubTypeList(smnodeSubTypes)
    } else {
      setSubTypeList([])
    }
  }

  const updateSubType = value => {
    setSubType(value)
  }

  useEffect(() => {
    if (type === NODE_TYPES.SM_NETWORK && subType) {
      setData({ ...data, ...{ type: subType }, ...NODE_DEFAULT_VALUES[subType] })
    } else {
      setData({ ...data, ...{ type: type }, ...NODE_DEFAULT_VALUES[type] })
    }
  }, [type, subType])

  return (
    <>
      <div className="field-container">
        <div className="field dropdown2">
          <label className="label label-required">{t("common.type")}</label>
          <div className="field-input">
            <Dropdown
              options={types}
              value={type}
              onChange={e => updateType(e.value)}
              placeholder={t("common.choose_node_type")}
            />
          </div>
        </div>
      </div>

      {subTypeList.length > 0 && (
        <div className="field-container">
          <div className="field dropdown2">
            <label className="label label-required">{t("common.sub_type")}</label>
            <div className="field-input">
              <Dropdown
                options={subTypeList}
                value={subType}
                onChange={e => updateSubType(e.value)}
                placeholder={t("common.choose_sub_type")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [data, setData] = useState({ site: siteStore.id })
  const [errors, setErrors] = useState({})
  const [spaces, setSpaces] = useState([])
  const [carelNodes, setCarelNodes] = useState([])
  const [loadingCarelNodes, setLodingCarelNodes] = useState(false)
  const [processing, setProcessing] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const retrieveSpaces = async () => {
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.spaces"), error.message)]))
      })
  }

  const retrieveCarelNodes = async () => {
    setLodingCarelNodes(true)
    await retrieveList(siteStore.id, auth.userData.access_token, { type: NODE_TYPES.CAREL })
      .then(({ data }) => {
        setCarelNodes(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.carel"), error.message)]))
      })
      .finally(() => setLodingCarelNodes(false))
  }

  const retrieveSMNodes = async queryParams => {
    return await getSMNodes(siteStore.id, auth.userData.access_token, queryParams)
  }

  const getSMValues = async () => {
    return await getSMAllValues(siteStore.id, auth.userData.access_token)
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)

    if (data.type === NODE_TYPES.SM || data.type === NODE_TYPES.CAREL) {
      if (data.password !== data.confirm_password) {
        setErrors({
          password: t("common.same_passwords"),
          confirm_password: t("common.same_passwords")
        })
        dispatch(
          addNotifications([
            errorNotification(t("common.automation_node"), t("common.same_passwords"))
          ])
        )
        return
      }
    }

    await add(
      siteStore.id,
      { ...data, sm_node: data?.sm_node?.id ?? null },
      auth.userData.access_token
    )
      .then(response => {
        dispatch(
          addNotifications([
            successNotification(t("common.automation_node"), t("common.creation_success"))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-nodes/${response.data.id}`
        )
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.automation_node"))
      })
      .finally(() => setProcessing(false))
  }

  const getModel = nodeType => {
    switch (nodeType) {
      case NODE_TYPES.MODBUS:
        return ModbusNode(spaces)
      case NODE_TYPES.BACNET:
        return BacnetNode(spaces)
      case NODE_TYPES.SM:
        return SMNode(spaces)
      case NODE_TYPES.SM_IO:
        return SMIONode(spaces, retrieveSMNodes, getSMValues)
      case NODE_TYPES.SM_CONTROLLER:
        return SMControllerNode(spaces, retrieveSMNodes, getSMValues)
      case NODE_TYPES.SM_METER:
        return SMMeterNode(spaces, retrieveSMNodes, getSMValues)
      case NODE_TYPES.OPCUA:
        return OPCUANode(spaces)
      case NODE_TYPES.CAREL:
        return CarelNode(spaces)
      case NODE_TYPES.CAREL_NETWORK:
        return CarelNetworkNode(spaces, carelNodes, loadingCarelNodes)
      case NODE_TYPES.EXTERNAL_API:
        return ExternalAPINode(spaces)
      default:
        return {}
    }
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      retrieveSpaces()
    }
  }, [siteStore.id, organizationStore.id])

  useEffect(() => {
    data.type && data.type === NODE_TYPES.CAREL_NETWORK && retrieveCarelNodes()
  }, [data.type])

  return (
    data && (
      <BaseLayout
        title={t("common.create_asset", { asset: t("common.automation_node").toLowerCase() })}
      >
        <form
          className="form-view"
          onSubmit={create}
        >
          <SelectType
            data={data}
            setData={setData}
          />
          {data.type && (
            <>
              <FormContent
                model={getModel(data.type)}
                data={data}
                setData={setData}
                errors={errors}
              />
              <div className="action-buttons-wrapper">
                <Button
                  rounded
                  outlined
                  severity="secondary"
                  label={t("common.cancel")}
                  icon="fa-solid fa-xmark"
                  disabled={processing}
                  onClick={goBack}
                />
                <ButtonWithLoader
                  rounded
                  severity="success"
                  label={t("common.save")}
                  type="submit"
                  icon="fa-solid fa-floppy-disk"
                  disabled={processing}
                />
              </div>
            </>
          )}
        </form>
      </BaseLayout>
    )
  )
}
