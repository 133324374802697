import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"
import { SMALL_INT_MAX_VALUE, SMALL_INT_MIN_VALUE } from "/utils/constants/input"

export const CarelNetworkNode = (spaces, carelNodes, loadingCarelNodes) => {
  return {
    ...commonsFields(spaces),
    ...{
      carel_node: {
        label: i18n.t("common.carel_node"),
        field: Dropdown,
        options: {
          options: carelNodes,
          required: true,
          optionValue: "id",
          optionLabel: "name",
          loading: loadingCarelNodes,
          filter: true,
          placeholder: loadingCarelNodes
            ? i18n.t("common.loading")
            : i18n.t("common.select_a", { value: i18n.t("common.carel_node") })
        }
      },
      id_device: {
        label: i18n.t("common.device_id"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          max: SMALL_INT_MAX_VALUE,
          min: SMALL_INT_MIN_VALUE
        }
      },
      status: {
        label: i18n.t("common.status"),
        field: InputNumber,
        options: {
          showButtons: true,
          max: SMALL_INT_MAX_VALUE,
          min: SMALL_INT_MIN_VALUE
        }
      },
      address: {
        label: i18n.t("common.address"),
        field: InputNumber,
        options: {
          showButtons: true,
          max: SMALL_INT_MAX_VALUE,
          min: SMALL_INT_MIN_VALUE
        }
      },
      id_line: {
        label: i18n.t("common.line_id"),
        field: InputNumber,
        options: {
          showButtons: true,
          max: SMALL_INT_MAX_VALUE,
          min: SMALL_INT_MIN_VALUE
        }
      },
      type_protocol: {
        label: i18n.t("common.protocol_type"),
        field: InputText,
        options: { maxLength: 255 }
      }
    }
  }
}

export const CarelNetworkOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      carel_node_label: { label: i18n.t("common.carel") },
      id_device: { label: i18n.t("common.device_id") },
      status: { label: i18n.t("common.status") },
      address: { label: i18n.t("common.address") },
      id_line: { label: i18n.t("common.line_id") },
      type_protocol: { label: i18n.t("common.protocol_type") }
    }
  }
}
