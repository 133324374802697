import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { useTranslation } from "react-i18next"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"
import List from "./components/List"

const ProtocolInstanceRouteContext = createContext(null)

const ProtocolInstanceContent = () => {
  const siteStore = useSelector(site)
  const [protocolInstance, setProtocolInstance] = useState()
  const dispatch = useDispatch()
  const auth = useAuth()
  const { protocolInstanceId } = useParams()
  const { t } = useTranslation()

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, protocolInstanceId, auth.userData.access_token)
      .then(response => {
        setProtocolInstance(response.data)
      })
      .catch(error => {
        dispatch(
          addNotifications([errorNotification(t("common.protocol_instance"), error.message)])
        )
      })
  }

  useEffect(() => {
    siteStore.id && protocolInstanceId && retrieveDetail()
  }, [siteStore.id, protocolInstanceId])

  return (
    <ProtocolInstanceRouteContext.Provider value={{ protocolInstance, retrieveDetail }}>
      {protocolInstance ? <Outlet /> : <Loader />}
    </ProtocolInstanceRouteContext.Provider>
  )
}

export { ProtocolInstanceRouteContext }
export default function ProtocolInstanceRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":protocolInstanceId"
        element={<ProtocolInstanceContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
