import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { siteId, flexId, marketPlayerId } = useParams()

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))
  }, [])

  const removeObject = async () => {
    await remove(flexId, marketPlayerId, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification(t("common.market_player_interaction"), t("common.delete_success"))
        ])
        navigate(`/sites/${siteId}/optimization/flexibility/${flexId}/market-player-interaction/`)
      })
      .catch(error => {
        displayNotification([
          errorNotification(t("common.market_player_interaction"), error.message)
        ])
      })
  }

  return (
    <DeleteConfirmation
      objectType={t("common.proposal_for_aggregator")}
      remove={removeObject}
      goBack={() => navigate(-1)}
    />
  )
}
