import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import { InputText } from "primereact/inputtext"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, createSearchParams } from "react-router-dom"
import { getGateways } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [gateways, setGateways] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(null)
  const [count, setCount] = useState(0)
  const [searchName, setSearchName] = useState("")
  const [searchSerialId, setSearchSerialId] = useState("")
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const statusTranslation = {
    unknown: t("common.no_requested_update"),
    in_sync: t("common.up_to_date"),
    pending: t("common.running"),
    error: t("common.error"),
    registered: t("common.no_requested_update")
  }

  const retrieveGateways = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await getGateways(auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.content && setGateways(data.content)
        setCount(data.total)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.gateways"), message)]))
      })
      .finally(() => setLoading(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const goToVersionSelection = () => {
    selected.length > 0 &&
      navigate({
        pathname: "/configuration/gateways/update-software/version/",
        search: createSearchParams({
          gateway_id: selected.map(gateway => gateway.controllerId)
        }).toString()
      })
  }

  const columns = [
    { field: "name", header: t("common.name"), sortable: true },
    { field: "controllerId", header: t("common.serial_number"), sortable: true },
    {
      field: "updateStatus",
      header: t("common.update_status"),
      sortable: true,
      processValue: value => statusTranslation[value]
    },
    {
      field: "pollStatus",
      header: t("common.connectivity"),
      processValue: value => (value && !value["overdue"] ? t("common.online") : t("common.offline"))
    }
  ]

  const queryParams = () => {
    return {
      limit,
      offset,
      sort: sortField && `${sortField}:${sortOrder > 0 ? "ASC" : "DESC"}`,
      q: `name==*${searchName}*;controllerId==*${searchSerialId}*`
    }
  }

  useEffect(() => {
    offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieveGateways(queryParams())
  }, [searchName, searchSerialId, sortField, sortOrder])

  useEffect(() => {
    offset !== null && retrieveGateways(queryParams())
  }, [limit, offset])

  return (
    <>
      <ListActions>
        <Button
          rounded
          severity="info"
          icon="fa-solid fa-arrow-up-right-from-square"
          label={t("common.update_software")}
          disabled={selected.length === 0}
          onClick={goToVersionSelection}
        />
      </ListActions>

      <div className="list-actions-filters is-visible">
        <div className="list-filters">
          <div className="list-filters-fields">
            <div className="list-filters-field-group">
              <label>{t("common.name")}</label>

              <IconField iconPosition="left">
                <InputIcon className="fa-solid fa-search"></InputIcon>
                <InputText
                  placeholder={t("common.set_filter")}
                  value={searchName}
                  onChange={e => setSearchName(e.target.value)}
                />
              </IconField>
            </div>

            <div className="list-filters-field-group">
              <label>{t("common.serial_number")}</label>

              <IconField iconPosition="left">
                <InputIcon className="fa-solid fa-search"></InputIcon>
                <InputText
                  placeholder={t("common.set_filter")}
                  value={searchSerialId}
                  onChange={e => setSearchSerialId(e.target.value)}
                />
              </IconField>
            </div>
          </div>
        </div>
      </div>

      <ListLayout
        columns={columns}
        value={gateways}
        dataKey={"controllerId"}
        handleSelection={setSelected}
        loading={loading}
        limit={limit}
        offset={offset}
        setLimit={setLimit}
        setOffset={setOffset}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
      />
    </>
  )
}
