import * as React from "react"
import { createContext } from "react"
import { Route, Routes } from "react-router-dom"
import List from "./components/List"

const RouteContext = createContext(null)

export { RouteContext }
export default function StateValueRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
    </Routes>
  )
}
