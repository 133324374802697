export function dateStr(date) {
  /**
   * Get a date string from a date (YYYY-MM-DD)
   */
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, "0")
  const day = date.getDate().toString().padStart(2, "0")
  return `${year}-${month}-${day}`
}

export function getNextDay(date) {
  /**
   * Get the next day from a date
   */
  const nextDay = new Date(date)
  nextDay.setDate(date.getDate() + 1)
  return nextDay
}
