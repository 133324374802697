import { Bacnet, BacnetOverview } from "./bacnet"
import { Carel, CarelOverview } from "./carel"
import { ExternalAPI, ExternalAPIOverview } from "./externalapi"
import {
  ModbusBit,
  ModbusBitOverview,
  ModbusRegister,
  ModbusRegisterBitExtract,
  ModbusRegisterBitExtractOverview,
  ModbusRegisterOverview
} from "./modbus"
import { OPCUA, OPCUAOverview } from "./opcua"
import {
  SMController,
  SMControllerOverview,
  SMIO,
  SMIOOverview,
  SMMeter,
  SMMeterOverview
} from "./sm"
import { AUTOMATION_POINT_TYPES } from "/utils/constants/automation_point"

const FORM_MODELS = {
  [AUTOMATION_POINT_TYPES.MODBUS_BIT]: ModbusBit,
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER]: ModbusRegister,
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER_BIT_EXTRACT]: ModbusRegisterBitExtract,
  [AUTOMATION_POINT_TYPES.BACNET]: Bacnet,
  [AUTOMATION_POINT_TYPES.SM_IO]: SMIO,
  [AUTOMATION_POINT_TYPES.SM_METER]: SMMeter,
  [AUTOMATION_POINT_TYPES.SM_CONTROLLER]: SMController,
  [AUTOMATION_POINT_TYPES.OPCUA]: OPCUA,
  [AUTOMATION_POINT_TYPES.CAREL]: Carel,
  [AUTOMATION_POINT_TYPES.EXTERNAL_API]: ExternalAPI
}

export const get_form_model = (type, nodes, loadingNodes) => {
  const model = FORM_MODELS[type]
  return model ? model(nodes, loadingNodes) : {}
}

const OVERVIEW_MODELS = {
  [AUTOMATION_POINT_TYPES.MODBUS_BIT]: ModbusBitOverview,
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER]: ModbusRegisterOverview,
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER_BIT_EXTRACT]: ModbusRegisterBitExtractOverview,
  [AUTOMATION_POINT_TYPES.BACNET]: BacnetOverview,
  [AUTOMATION_POINT_TYPES.SM_IO]: SMIOOverview,
  [AUTOMATION_POINT_TYPES.SM_METER]: SMMeterOverview,
  [AUTOMATION_POINT_TYPES.SM_CONTROLLER]: SMControllerOverview,
  [AUTOMATION_POINT_TYPES.OPCUA]: OPCUAOverview,
  [AUTOMATION_POINT_TYPES.CAREL]: CarelOverview,
  [AUTOMATION_POINT_TYPES.EXTERNAL_API]: ExternalAPIOverview
}

export const get_overview_model = type => {
  const model = OVERVIEW_MODELS[type]
  return model ? model() : {}
}
