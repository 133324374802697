import { GATEWAY_TYPES, VIRTUAL_MACHINE_TYPES } from "/utils/constants/gateway"
import { handleErrors } from "/utils/error"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"

import { Dropdown } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getAvailableSensinovIDs, getSpaces, retrieveList } from "../api"
import { getFormModel } from "../models/utils"

import {
  getAllValues as getHardwareValues,
  retrieveList as retrieveHardwares
} from "apps/configuration/gateway/box/api"

const SelectType = ({ data, setData }) => {
  const { t } = useTranslation()
  const [types, setTypes] = useState([
    { value: GATEWAY_TYPES.BOX, label: t("common.beebrytebox") },
    { value: VIRTUAL_MACHINE_TYPES.SENSINOV, label: t("common.sensinov") },
    { value: VIRTUAL_MACHINE_TYPES.MY_SIRIUS, label: t("common.mysirius") },
    { value: VIRTUAL_MACHINE_TYPES.SIGFOX, label: t("common.sigfox") },
    { value: VIRTUAL_MACHINE_TYPES.GRDF, label: "common.grdf" },
    { value: VIRTUAL_MACHINE_TYPES.KOOVEA, label: "common.koovea" },
    { value: VIRTUAL_MACHINE_TYPES.MOBILE_ALERTS, label: "common.mobilealerts" }
  ])
  const [type, setType] = useState(null)
  const siteStore = useSelector(site)
  const auth = useAuth()

  const getGW = async () => {
    await retrieveList(siteStore.id, auth.userData.access_token).then(({ data }) => {
      const found = data.results.find(
        gateway => gateway.virtual_machine_type === VIRTUAL_MACHINE_TYPES.SENSINOV
      )
      found && setTypes(types.filter(({ value }) => value !== VIRTUAL_MACHINE_TYPES.SENSINOV))
    })
  }

  const updateType = value => {
    setType(value)
    if (value === GATEWAY_TYPES.BOX) {
      setData({
        ...data,
        type: value
      })
    } else {
      setData({
        ...data,
        type: GATEWAY_TYPES.VIRTUAL_MACHINE,
        virtual_machine_type: value
      })
    }
  }

  useEffect(() => {
    siteStore.id && getGW()
  }, [siteStore.id])

  return (
    <div className="field-container">
      <div className="field dropdown2">
        <label className="label label-required">{t("common.type")}</label>
        <div className="field-input">
          <Dropdown
            options={types}
            value={type}
            onChange={e => updateType(e.value)}
            placeholder={t("common.select_a", { value: t("common.type").toLowerCase() })}
          />
        </div>
      </div>
    </div>
  )
}

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [spaces, setSpaces] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)
  const [sensinovIDs, setSensinovIDs] = useState([])
  const [loadindSensinovIDs, setLoadingSensinovIDs] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const retrieveAvailableHardwares = queryParams => {
    return retrieveHardwares(auth.userData.access_token, { available: 1, ...queryParams })
  }

  const listHardwareAllValues = () => {
    return getHardwareValues(auth.userData.access_token)
  }

  const retrieveSpaces = async () => {
    setLoadingSpaces(true)
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.spaces"), message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  const retrieveSensinovIDs = async () => {
    setLoadingSensinovIDs(true)
    await getAvailableSensinovIDs(auth.userData.access_token)
      .then(({ data }) => {
        setSensinovIDs(data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.sensinov"), message)]))
      })
      .finally(() => setLoadingSensinovIDs(false))
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)

    await add(
      siteStore.id,
      {
        ...data,
        hardware: data.hardware?.id ?? null
      },
      auth.userData.access_token
    )
      .then(response => {
        dispatch(
          addNotifications([successNotification(t("common.gateway"), t("common.creation_success"))])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${response.data.id}/`
        )
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.gateway"))
      })
      .finally(() => setProcessing(false))
  }

  const getModel = (type, subType) => {
    const modelParams = {
      spaces,
      loadingSpaces,
      sensinovIDs,
      loadindSensinovIDs,
      retrieveHardwares: retrieveAvailableHardwares,
      getHardwareValues: listHardwareAllValues
    }
    return getFormModel({ type, subType, modelParams })
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      retrieveSpaces()
    }
  }, [siteStore.id])

  useEffect(() => {
    if (data.type === GATEWAY_TYPES.BOX) {
      setData({ ...data, name: `gatewayBox-${siteStore.name}` })
    } else if (data.type === GATEWAY_TYPES.VIRTUAL_MACHINE) {
      if (data.virtual_machine_type === VIRTUAL_MACHINE_TYPES.SENSINOV) {
        retrieveSensinovIDs()
      } else {
        setData({ ...data, name: `${data.virtual_machine_type}-${siteStore.name}` })
      }
    }
  }, [data.type])

  useEffect(() => {
    if (
      data.type === GATEWAY_TYPES.VIRTUAL_MACHINE &&
      data.virtual_machine_type === VIRTUAL_MACHINE_TYPES.SENSINOV
    ) {
      const sensinovID = sensinovIDs.find(item => item.name === data.name)
      setData({ ...data, external_id: sensinovID.external_id })
    }
  }, [data.name])

  return (
    data && (
      <BaseLayout title={t("common.create_asset", { asset: t("common.gateway").toLowerCase() })}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <SelectType
            data={data}
            setData={setData}
          />
          {data.type && (
            <FormContent
              model={getModel(data.type, data.virtual_machine_type)}
              data={data}
              setData={setData}
              errors={errors}
            />
          )}
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              disabled={processing}
              icon="fa-solid fa-xmark"
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("common.save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
              disabled={processing}
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}
