import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  ListActions,
  ListLayout,
  CopyButton,
  BaseLayout
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { exportEdgeController, retrieveList } from "../api"
import fileDownload from "js-file-download"
import { datetimeStr } from "/utils/datetime"
import { organization } from "/utils/store/organization"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.edge_controller"), error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    { field: "gateway_name", header: t("common.gateway"), sortable: true },
    { field: "type", header: t("common.type"), sortable: true },
    { field: "updated_by", header: t("common.updated_by"), sortable: true },
    {
      field: "updated_at",
      header: t("common.modified_at"),
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    }
  ]

  const getDetailUrl = edgeControllerId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/edge-controllers/${edgeControllerId}/`
  }

  const getDeleteUrl = edgeControllerId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/edge-controllers/${edgeControllerId}/delete/`
  }

  const onRowClick = edgeControllerId => {
    navigate(getDetailUrl(edgeControllerId))
  }

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && retrieve()
  }, [siteStore.id, limit, offset])

  const getExport = async function (edgeControllerId) {
    await exportEdgeController(siteStore.id, edgeControllerId, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `edge_controller_${datetimeStr()}.tar`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.export"), error.message)]))
      })
  }

  const actionsBodyTemplate = ({ id }) => {
    return (
      <div className="p-datatable-action-buttons">
        <CopyButton
          isUrl
          icon="fa-solid fa-link"
          value={getDetailUrl(id)}
        />

        <Link
          className="delete-button"
          to={getDeleteUrl(id)}
        >
          <i className="fa-solid fa-trash-can" />
        </Link>

        <button
          className="copy-button"
          onClick={() => getExport(id)}
        >
          <i className="fa-solid fa-download" />
        </button>
      </div>
    )
  }

  return (
    siteStore.id && (
      <BaseLayout title={t("common.edge_controllers")}>
        <section className="list-section">
          <ListActions filters={menuFilters}>
            <Link
              to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/edge-controllers/create/`}
            >
              <Button
                rounded
                severity="info"
                icon="fa-regular fa-plus"
                label={t("common.create")}
              />
            </Link>
          </ListActions>

          <ListLayout
            columns={columns}
            value={items}
            loading={loading}
            filters={filters}
            getDetailUrl={getDetailUrl}
            getDeleteUrl={getDeleteUrl}
            onRowClick={onRowClick}
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            setLimit={setLimit}
            count={count}
            actionsBodyTemplate={actionsBodyTemplate}
          />
        </section>
      </BaseLayout>
    )
  )
}
