import React from "react"

import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { useTranslation } from "react-i18next"
import { MAPPING_CURRENCY_SYMBOL } from "/utils/constants/bill"

export default function BillSchedulePriceFields(props) {
  const { t } = useTranslation()

  const update = (value, key) => {
    props.setPrice(props.index, { ...props.price, [key]: value })
  }

  const updatePrice = (value, key) => {
    const values = { ...props.price.value }
    values[key] = value
    update(values, "value")
  }

  return (
    <>
      <div className="flex gap-4 align-items-center">
        <div className="field-container flex-grow-1">
          <div className="field inputtext">
            <label className="label label-required">{t("common.name_schedule_prices")}</label>
            <div className="field-input">
              <InputText
                required
                value={props.price.name}
                onChange={e => update(e.target.value, "name")}
              />
            </div>
          </div>
        </div>
        <i
          className="fa-solid fa-trash ml-auto cursor-pointer"
          onClick={() => props.removePrice(props.index)}
        />
      </div>

      <span className="schedule-prices-label">
        {t("common.schedule_prices_currency", {
          currency: MAPPING_CURRENCY_SYMBOL[props.currency]
        })}
      </span>
      {Object.entries(props.price.value).map(([key, value], index) => {
        return (
          <div
            key={index}
            className="field-container mt-3"
          >
            <div className="field inputtext">
              <label className="label label-required">{key}</label>
              <div className="field-input">
                <InputNumber
                  required
                  value={value}
                  onChange={e => updatePrice(e.value, key)}
                  min={0}
                  showButtons
                  step={0.01}
                  maxFractionDigits={6}
                />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
