import axios from "axios"
import { config } from "/utils/api"

export async function retrieveMarketScheduleGroup(flexId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_FLEX_URL}/flex/${flexId}/market_schedule_group`,
    config({ token, queryParams })
  )
}

export async function retrieveListCurtailSchedule(flexId, groupId, token) {
  return await axios.get(
    `${window.BACKEND_FLEX_URL}/flex/${flexId}/market_schedule_group/${groupId}/load_curtail_schedule`,
    config({ token })
  )
}

export async function retrieveListUnavailabilityPeriods(siteId, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/operation/sites/${siteId}/unavailability_periods/jira/`,
    config({ token, queryParams })
  )
}
