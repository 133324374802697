import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { handleErrors } from "/utils/error.js"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getUnavailabilityCategories, update } from "../api"
import { UnavailabilityPeriod, UnavailabilityPeriodOverview } from "../models"
import { UnavailabilityPeriodRouteContext } from "../routes"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const { unavailabilityPeriod, retrieveDetail } = useContext(UnavailabilityPeriodRouteContext)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingUnavailabilityCategories, setLoadingUnavailabilityCategories] = useState(false)
  const [unavailabilityCategories, setUnavailabilityCategories] = useState([])

  const retrieveCategories = async () => {
    setLoadingUnavailabilityCategories(true)
    getUnavailabilityCategories(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setUnavailabilityCategories(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.unavailability_periods"), message)]))
      })
      .finally(() => setLoadingUnavailabilityCategories(false))
  }

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)
    await update(
      siteStore.id,
      unavailabilityPeriod.id,
      {
        // send only field needed by backend
        unavailability_category_id: data.unavailability_category_id,
        comment: data.comment,
        start_date: data.start_date,
        start_time: data.start_time,
        end_date: data.end_date,
        end_time: data.end_time,
        updated_by: auth.userData.profile.preferred_username
      },
      auth.userData.access_token
    )
      .then(() => {
        dispatch(
          addNotifications([
            successNotification(t("common.unavailability_period"), t("common.update_success"))
          ])
        )
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.unavailability_period"))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (unavailabilityPeriod) {
      setLoading(false)
      setData(unavailabilityPeriod)
    }
  }, [unavailabilityPeriod])

  useEffect(() => {
    edit && retrieveCategories()
  }, [edit])

  return (
    unavailabilityPeriod && (
      <>
        <div className="base-content-title">
          <h2>
            {unavailabilityPeriod.start_date} {unavailabilityPeriod.start_time}
            {unavailabilityPeriod.end_date && (
              <span className="arrow">
                <i className="fa-solid fa-arrow-right" />
              </span>
            )}
            {unavailabilityPeriod.end_date} {unavailabilityPeriod.end_time}
          </h2>
        </div>
        <OverView
          object={unavailabilityPeriod}
          model={UnavailabilityPeriod(
            unavailabilityCategories,
            loadingUnavailabilityCategories,
            data.start_date,
            data.end_date,
            data.start_time
          )}
          overviewModel={UnavailabilityPeriodOverview()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          loading={loading}
          processing={processing}
          setEdit={setEdit}
          deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/${unavailabilityPeriod.id}/delete/`}
        />
      </>
    )
  )
}
