import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { buildQueryParams } from "/utils/queryparams"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Dialog } from "primereact/dialog"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getPointInfo, retrieveList } from "../api"

const PointInfo = ({ siteId, pointId }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [point, setPoint] = useState({})

  useEffect(() => {
    const retrieveInfo = async () => {
      await getPointInfo(siteId, pointId)
        .then(response => {
          setPoint(response.data)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification(t("common.point"), error.message)]))
        })
    }
    if (pointId) {
      retrieveInfo()
    }
  }, [pointId])

  const stateColumns = [
    { field: "state_name", header: t("common.state_name") },
    { field: "value", header: t("common.value") },
    { field: "active", header: t("common.active") }
  ]

  const edgeBlockColumn = [
    { field: "name", header: t("common.name") },
    { field: "priority", header: t("common.priority") },
    { field: "condition", header: t("common.condition") },
    { field: "action", header: t("common.action") }
  ]

  return (
    <>
      <div className="base-content-title">
        <h2>
          {t("common.summary_for")} {point.name}
        </h2>
      </div>

      <section className="list-section">
        <div className="list-section-head">
          <h3>{t("common.inhibit_states")}</h3>
        </div>

        <ListLayout
          columns={stateColumns}
          value={point.inhibit_states || []}
          actionsBodyTemplate={null}
        />
      </section>

      <section className="list-section">
        <div className="list-section-head">
          <h3>{t("common.constraint_states")}</h3>
        </div>
        <ListLayout
          columns={stateColumns}
          value={point.constraint_states || []}
          actionsBodyTemplate={null}
        />
      </section>

      <section className="list-section">
        <div className="list-section-head">
          <h3>{t("common.safe_states")}</h3>
        </div>
        <ListLayout
          columns={stateColumns}
          value={point.safe_states || []}
          actionsBodyTemplate={null}
        />
      </section>

      <section className="list-section">
        <div className="list-section-head">
          <h3>{t("common.edge_blocks")}</h3>
        </div>
        <ListLayout
          columns={edgeBlockColumn}
          value={point.edge_blocks || []}
          actionsBodyTemplate={null}
        />
      </section>
    </>
  )
}

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [visible, setVisible] = useState(false)
  const [selectedPointId, setSelectedPointId] = useState(null)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  const DEFAULT_FILTER = { write: ["True"] }

  const retrieve = async (queryParams = `?limit=${limit}&offset=${offset}&write=True`) => {
    setLoading(true)
    await retrieveList(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.points"), error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const columns = [
    { field: "equipment_name", header: t("common.equipment"), sortable: true },
    { field: "name", header: t("common.point"), sortable: true },
    { field: "node", header: t("common.node"), sortable: true },
    { field: "gateway_name", header: t("common.gateway"), sortable: true }
  ]

  const menuFilters = { items, columns, filters, setFilters }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && retrieve(buildQueryParams(limit, offset, DEFAULT_FILTER))
  }, [siteStore.id, limit, offset])
  return (
    siteStore.id && (
      <>
        <Dialog
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => {
            setVisible(false)
            setSelectedPointId(null)
          }}
        >
          {selectedPointId && (
            <PointInfo
              siteId={siteStore.id}
              pointId={selectedPointId}
            />
          )}
        </Dialog>

        <section className="list-section">
          <ListActions filters={menuFilters} />

          <ListLayout
            columns={columns}
            value={items}
            loading={loading}
            filters={filters}
            onRowClick={pointId => {
              setSelectedPointId(pointId)
              setVisible(true)
            }}
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            setLimit={setLimit}
            count={count}
          />
        </section>
      </>
    )
  )
}
