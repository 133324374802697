import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { FLEX_CONTRACT_FIELDS, FullFlexContractForm, createOverviewInstance } from "../models"
import { OverView, Loader } from "@software-engineering/hivolution-frontend-utils"
import { FlexContractRoutesContext } from "../context"

export default function Detail() {
  const { siteId, organizationId, flexId } = useParams()
  // submit button loading
  const [edit, setEdit] = useState(false)

  // to populate with instance data
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  const {
    retrieveFlexContractById,
    flexContract,
    isLoading,
    isProcessing,
    updateCurrentFlexContract
  } = useContext(FlexContractRoutesContext)

  const [fieldOptions, setFieldOptions] = useState({
    [FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]: {
      disabled: true
    },
    [FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY]: {
      disabled: true
    },
    [FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY]: {
      disabled: true
    },
    [FLEX_CONTRACT_FIELDS.CONTRACT_TYPE_KEY]: {
      disabled: true
    },
    [FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY]: {
      disabled: true
    },
    [FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY]: {
      disabled: true
    }
  })

  const onSubmit = async e => {
    e.preventDefault()
    const finalData = {
      ...data,
      [FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]: [
        parseInt(data[FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY])
      ]
    }
    updateCurrentFlexContract(finalData)
      .then(response => {
        // update the form and flexContract with the new data
        setData({ ...data, ...response.data })
        setEdit(false)
      })
      .catch(error => {
        setErrors(error?.response?.data ?? error)
      })
  }

  useEffect(() => {
    !flexContract && retrieveFlexContractById(flexId)
  }, [siteId, organizationId, flexId])

  useEffect(() => {
    if (flexContract) {
      const newValues = {
        [FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY]: {
          disabled: true,
          options: [
            {
              name: flexContract["energy_company_name"],
              id: flexContract[FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY]
            }
          ]
        },
        [FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]: {
          disabled: true,
          options: [
            {
              name: flexContract["optimization_gateways_names"],
              id: flexContract[FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]
            }
          ]
        },
        [FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY]: {
          disabled: true,
          options: [
            {
              meter_id: flexContract["energy_delivery_point_meter_id"],
              id: flexContract[FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY]
            }
          ]
        },

        [FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY]: {
          disabled: true,
          options: [
            {
              value: flexContract[FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY],
              label: flexContract[FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY]
            }
          ]
        },

        [FLEX_CONTRACT_FIELDS.SCHEDULE_DURATION_KEY]: {
          options: {
            value: flexContract[FLEX_CONTRACT_FIELDS.SCHEDULE_DURATION_KEY]
          }
        }
      }
      setFieldOptions({
        ...fieldOptions,
        ...newValues
      })
      setData(flexContract)
    }
  }, [flexContract])

  const getDeleteUrl = () => {
    return `/organizations/${organizationId}/sites/${siteId}/optimization/flexibility/${flexId}/delete/`
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    flexContract && (
      <OverView
        object={edit ? flexContract : createOverviewInstance(flexContract)}
        model={FullFlexContractForm(fieldOptions)}
        updateObject={onSubmit}
        data={data}
        setData={setData}
        errors={errors}
        edit={edit}
        loading={false}
        processing={isProcessing}
        setEdit={setEdit}
        deleteUrl={getDeleteUrl()}
      />
    )
  )
}
