import { SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"
import { SMALL_INT_MAX_VALUE, SMALL_INT_MIN_VALUE } from "/utils/constants/input"

export const SMControllerNode = (spaces, retrieveSMNodes, getAllValues) => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: i18n.t("common.system_manager"),
        field: SelectArray,
        options: {
          getItems: retrieveSMNodes,
          getAllValues: getAllValues,
          keyLabel: "name",
          columns: [
            { field: "name", header: i18n.t("common.name") },
            { field: "model", header: i18n.t("common.model") },
            { field: "manufacturer", header: i18n.t("common.manufacturer") },
            { field: "proto", header: i18n.t("common.protocol") },
            { field: "sm_type", header: i18n.t("common.sm_type") },
            { field: "software", header: i18n.t("common.software") },
            { field: "user", header: i18n.t("common.user") }
          ],
          required: true
        }
      },
      node_addr: {
        label: i18n.t("common.node_address"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          useGrouping: false,
          max: SMALL_INT_MAX_VALUE,
          min: SMALL_INT_MIN_VALUE
        }
      },
      device_id: {
        label: i18n.t("common.device_id"),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      device_type: {
        label: i18n.t("common.device_type"),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      suction_id: {
        label: i18n.t("common.suction_id"),
        field: InputNumber,
        options: {
          showButtons: true,
          min: SMALL_INT_MIN_VALUE,
          max: SMALL_INT_MAX_VALUE
        }
      },
      num_suction: {
        label: i18n.t("common.num_suction"),
        field: InputNumber,
        options: {
          showButtons: true,
          min: SMALL_INT_MIN_VALUE,
          max: SMALL_INT_MAX_VALUE
        }
      },
      multicasename: {
        label: i18n.t("common.multi_case_name"),
        field: InputText,
        options: { maxLength: 255 }
      },
      rack_id: {
        label: i18n.t("common.rack_id"),
        field: InputNumber,
        options: {
          showButtons: true,
          min: SMALL_INT_MIN_VALUE,
          max: SMALL_INT_MAX_VALUE
        }
      }
    }
  }
}

export const SMControllerOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node: {
        label: i18n.t("common.system_manager"),
        getValue: item => item?.name ?? ""
      },
      node_addr: { label: i18n.t("common.node_address") },
      device_id: { label: i18n.t("common.device_id") },
      device_type: { label: i18n.t("common.device_type") },
      suction_id: { label: i18n.t("common.suction_id") },
      num_suction: { label: i18n.t("common.num_suction") },
      multicasename: { label: i18n.t("common.multi_case_name") },
      rack_id: { label: i18n.t("common.rack_id") }
    }
  }
}
