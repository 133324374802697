import { NetworkRoutesContext } from "/apps/assets/communication/network/routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  InnerTabView,
  ListLayout,
  Loader,
  OverView
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { TabPanel } from "primereact/tabview"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getSpaces, update } from "../api"
import { IPNetworkOverview, Network, SerialNetworkOverview } from "../models"

const GatewayInterfaces = ({ siteId, networkId }) => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const retrieve = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(
      `connectivity/sites/${siteId}/gateway_ip_interfaces/?network=${networkId}`,
      auth.userData.access_token
    )()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(
          addNotifications([errorNotification(t("common.gateway_interfaces"), error.message)])
        )
      })

    // TODO: uncomment when the API will no longer paginate
    /*
    await getGatewayInterfaces(siteId, networkId, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    })
    */
  }

  const columns = [
    { field: "gateway_name", header: t("common.gateway") },
    { field: "ip_addr", header: t("common.ip_address") }
  ]

  useEffect(() => {
    retrieve()
  }, [networkId])

  return (
    <section className="list-section">
      <ListLayout
        value={items}
        columns={columns}
        loading={loading}
        hideActions={true}
      />
    </section>
  )
}

const NodeInterfaces = ({ siteId, networkId, networkType }) => {
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const retrieve = async () => {
    // TODO: remove when the api will no longer paginate
    await paginatedRequest(
      `asset/sites/${siteId}/node_interfaces/?network=${networkId}`,
      auth.userData.access_token
    )()
      .then(results => {
        setItems(results)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        dispatch(addNotifications([errorNotification(t("common.node_interfaces"), error.message)]))
      })

    // TODO: uncomment when the API will no longer paginate
    /* await getNodeInterfaces(siteId, networkId, auth.userData.access_token).then(response => {
      if (response.data) {
        setItems(response.data.results)
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      dispatch(addNotifications([errorNotification("Error", error.message)]))
    }) */
  }

  const columns =
    networkType === "ipnetwork"
      ? [
          { field: "node_name", header: t("common.node") },
          { field: "ip_address", header: t("common.ip_address") },
          { field: "transport_type", header: t("common.transport_type") },
          { field: "port", header: t("common.port") }
        ]
      : [
          { field: "node_name", header: t("common.node") },
          { field: "mstp_address", header: t("common.mstp_address") }
        ]

  useEffect(() => {
    retrieve()
  }, [networkId])

  return (
    <section className="list-section">
      <ListLayout
        value={items}
        columns={columns}
        loading={loading}
        hideActions={true}
      />
    </section>
  )
}

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)
  const [spaces, setSpaces] = useState([])
  const { network, retrieveDetail } = useContext(NetworkRoutesContext)

  const updateObject = async e => {
    e.preventDefault()

    const dataToSend = {
      ...data,
      ...{ bacnet_network_number: data.is_bacnet ? data.bacnet_network_number : null }
    }

    setProcessing(true)
    await update(siteStore.id, network.id, dataToSend, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([successNotification(t("common.network"), t("common.update_success"))])
        )
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification(t("common.network"), message)]))
      })
      .finally(() => setProcessing(false))
  }

  const getSP = async () => {
    setLoadingSpaces(true)
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.network"), message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  useEffect(() => {
    network && setLoading(false)
  }, [network])

  useEffect(() => {
    edit && getSP()
  }, [edit])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return network ? (
    <>
      <div className="base-content-title">
        <h2>
          {network.type} – {network.name ? network.name : network.ip_address_range}
        </h2>
      </div>
      <InnerTabView>
        <TabPanel header={t("common.overview")}>
          <OverView
            object={network}
            model={Network(spaces, loadingSpaces, network.id)}
            overviewModel={
              network && network.type === "ipnetwork"
                ? IPNetworkOverview()
                : SerialNetworkOverview()
            }
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing}
            setEdit={setEdit}
            deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/networks/${network.id}/delete/`}
          />
        </TabPanel>
        <TabPanel header={t("common.gateway_interfaces")}>
          <GatewayInterfaces
            siteId={siteStore.id}
            networkId={network.id}
          />
        </TabPanel>
        <TabPanel header={t("common.node_interfaces")}>
          <NodeInterfaces
            siteId={siteStore.id}
            networkId={network.id}
            networkType={network.type}
          />
        </TabPanel>
      </InnerTabView>
    </>
  ) : (
    <Loader />
  )
}
