export const NODE_TYPES = {
  MODBUS: "modbusnode",
  BACNET: "bacnetnode",
  SM: "smnode",
  SM_NETWORK: "smnetworknode",
  SM_IO: "smionode",
  SM_METER: "smmeternode",
  SM_CONTROLLER: "smcontrollernode",
  OPCUA: "opcuanode",
  CAREL: "carelnode",
  CAREL_NETWORK: "carelnetworknode",
  EXTERNAL_API: "externalapinode"
}

export const NODE_DEFAULT_VALUES = {
  [NODE_TYPES.MODBUS]: {},
  [NODE_TYPES.BACNET]: {},
  [NODE_TYPES.SM]: {},
  [NODE_TYPES.SM_IO]: {},
  [NODE_TYPES.SM_METER]: {},
  [NODE_TYPES.SM_CONTROLLER]: {
    suction_id: 0,
    num_suction: 0,
    rack_id: 0
  },
  [NODE_TYPES.OPCUA]: {},
  [NODE_TYPES.CAREL]: {},
  [NODE_TYPES.CAREL_NETWORK]: {},
  [NODE_TYPES.EXTERNAL_API]: {}
}
