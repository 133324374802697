import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const ExternalAPI = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      external_data_type: {
        label: i18n.t("common.external_point_type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "sensinov", label: i18n.t("common.sensinov") },
            { value: "mysirius", label: i18n.t("common.mysirius") },
            { value: "sigfox", label: i18n.t("common.sigfox") },
            { value: "grdf", label: i18n.t("common.grdf") },
            { value: "koovea", label: i18n.t("common.koovea") },
            { value: "mobilealerts", label: i18n.t("common.mobilealerts") }
          ]
        }
      },
      external_id: {
        label: i18n.t("common.external_id"),
        field: InputText,
        options: {
          required: true
        }
      }
    }
  }
}

export const ExternalAPIOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      external_data_type: { label: i18n.t("common.external_point_type") },
      external_id: { label: i18n.t("common.external_id") }
    }
  }
}
