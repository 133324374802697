import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"

export const NetworkInterfaceOverview = () => {
  return {
    gateway_name: { label: i18n.t("common.gateway") },
    network_label: { label: i18n.t("common.network") },
    ip_addr: { label: i18n.t("common.ip_address") }
  }
}

export const NetworkInterface = () => {
  return {
    gateway_name: {
      label: i18n.t("common.gateway"),
      field: InputText,
      options: {
        disabled: true
      }
    },
    network_label: {
      label: i18n.t("common.network"),
      field: InputText,
      options: {
        disabled: true
      }
    },
    ip_addr: {
      label: i18n.t("common.ip_address"),
      field: InputText,
      helpText: i18n.t("common.cidr_format", { format: "192.168.3.56" }),
      options: {
        required: true,
        pattern:
          "((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))"
      }
    }
  }
}

export const NetworkIPInterface = (networks, loadingNetworks) => {
  return {
    network: {
      label: i18n.t("common.network"),
      field: Dropdown,
      options: {
        required: true,
        options: networks,
        optionLabel: "label",
        optionValue: "id",
        loading: loadingNetworks,
        placeholder: loadingNetworks
          ? i18n.t("common.loading")
          : i18n.t("common.select", {
              value: i18n.t("common.network").toLowerCase()
            })
      }
    },
    ip_addr: {
      label: i18n.t("common.ip_address"),
      field: InputText,
      helpText: i18n.t("common.cidr_format", { format: "192.168.3.56" }),
      options: {
        required: true,
        pattern:
          "((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))"
      }
    }
  }
}
