import ConnectorInstanceRoutes from "/apps/connectivity/connector-instance/routes"
import Deployment from "/apps/connectivity/gateway/components/Deployment"
import NetworkInterfaceRoutes from "/apps/connectivity/network-interface/routes"
import StoreRoutes from "/apps/connectivity/store/routes"
import { GATEWAY_TYPES } from "/utils/constants/gateway"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Loader, TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import { retrieve } from "./api"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import List from "./components/List"

const GatewayRoutesContext = createContext(null)

const GatewayLayout = () => {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const { gatewayId } = useParams()
  const auth = useAuth()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [gateway, setGateway] = useState(null)
  const [tabs, setTabs] = useState()

  const retrieveDetail = async () => {
    retrieve(siteStore.id, gatewayId, auth.userData.access_token)
      .then(response => {
        setGateway(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.gateway"), error.message)]))
      })
  }

  useEffect(() => {
    siteStore.id && gatewayId && retrieveDetail()
  }, [siteStore.id, gatewayId])

  useEffect(() => {
    const tabs = [
      {
        name: t("common.base_informations"),
        active: location.pathname.match(`/gateways/${gatewayId}/?$`),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gatewayId}/`
      }
    ]

    const additionnalTabs = [
      {
        name: t("common.network_interfaces"),
        active: location.pathname.includes("network-interfaces"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gatewayId}/network-interfaces/`
      },
      {
        name: t("common.connector_instances"),
        active: location.pathname.includes("connector-instances"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gatewayId}/connector-instances/`
      },
      {
        name: t("common.stores"),
        active: location.pathname.includes("stores"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gatewayId}/stores/`
      }
    ]

    const deployTab = [
      {
        name: t("common.deployment"),
        active: location.pathname.includes("deployment"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gatewayId}/deployment/`
      }
    ]

    gateway &&
      gateway.type &&
      setTabs(gateway.type === GATEWAY_TYPES.BOX ? tabs.concat(additionnalTabs, deployTab) : tabs)
  }, [gateway])

  return (
    <GatewayRoutesContext.Provider value={{ gateway, retrieveDetail }}>
      {gateway && tabs ? (
        <TabsLayout
          title={`${t("common.gateway")} ${gateway.name}`}
          tabs={tabs}
          setTabs={setTabs}
        />
      ) : (
        <Loader />
      )}
    </GatewayRoutesContext.Provider>
  )
}

export { GatewayRoutesContext }
export default function GatewayRoutes() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    }
  }, [siteStore.id, organizationStore.id])

  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":gatewayId"
        element={<GatewayLayout />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          path="network-interfaces/*"
          element={<NetworkInterfaceRoutes />}
        />
        <Route
          path="connector-instances/*"
          element={<ConnectorInstanceRoutes />}
        />
        <Route
          path="stores/*"
          element={<StoreRoutes />}
        />
        <Route
          path="deployment"
          element={<Deployment />}
        />
      </Route>
    </Routes>
  )
}
