import {
  deployConfiguration,
  downloadConfiguration,
  getDeployHistory
} from "/apps/connectivity/gateway/api"
import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { containSpaceOnly } from "/utils/string"
import {
  ButtonWithLoader,
  ConfirmModal,
  ListActions,
  ListLayout
} from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { InputTextarea } from "primereact/inputtextarea"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

/**
 * Gateway configuration deployment view
 *
 * @returns {Element}
 */
const Deployment = () => {
  const auth = useAuth()
  const dispatch = useDispatch()
  const { gateway } = useContext(GatewayRoutesContext)
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [history, setHistory] = useState([])
  const [deployChanges, setDeployChanges] = useState("")
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false)
  const [deleteQueue, setDeleteQueue] = useState()
  const { t } = useTranslation()

  /**
   * Columns configurations
   */
  const columns = [
    { field: "name", header: t("common.name"), sortable: true },
    { field: "created_by", header: t("common.generated_by"), sortable: true },
    {
      field: "created_at",
      header: t("common.generated_at"),
      sortable: true,
      processValue: value => new Date(value).toLocaleString()
    },
    { field: "comment", header: t("common.changes") }
  ]

  /**
   * Retrieve history
   *
   * @returns {Promise<void>}
   */
  const retrieve = async () => {
    setLoading(true)
    await getDeployHistory(gateway.name, auth.userData.access_token)
      .then(({ data }) => {
        data && setHistory(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message

        dispatch(addNotifications(errorNotification(t("common.gateway_configuration"), message)))
      })
      .finally(() => setLoading(false))
  }

  /**
   * Deploy configuration
   *
   * @returns {Promise<void>}
   */
  const deploy = async () => {
    setProcessing(true)

    await deployConfiguration(
      { comment: deployChanges, device_id: gateway.name },
      auth.userData.access_token
    )
      .then(({ data }) => {
        resetChanges()
        retrieve()

        data && data.deployment_history_id && download(data.deployment_history_id)

        dispatch(
          addNotifications(
            successNotification(
              t("common.gateway_configuration"),
              t("common.gateway_configuration_deploy_success")
            )
          )
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications(errorNotification(t("common.gateway_configuration"), message)))
      })
      .finally(() => setProcessing(false))
  }

  /**
   * Launch file download
   *
   * @param id
   * @param element
   * @returns {Promise<void>}
   */
  const download = async (id, element = null) => {
    const button = element && element.currentTarget

    button && animateDownloadButton(button, true)

    await downloadConfiguration(id, auth.userData.access_token)
      .then(response => {
        const fileInfos = response.headers["content-disposition"].match(
          /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/i
        )
        fileInfos && fileInfos[1] && fileDownload(response.data, fileInfos[1])
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message

        dispatch(addNotifications(errorNotification(t("common.gateway_configuration"), message)))
      })
      .finally(() => button && animateDownloadButton(button))
  }

  /**
   * Display delete confirm dialog & add `id` in queue
   *
   * @param id
   * @returns {Promise<void>}
   */
  /*
  const confirmDelete = id => {
    setConfirmDeleteVisible(true)
    setDeleteQueue(id)
  }
  */

  /**
   * Process deletion
   * Download file first then request deletion
   *
   * @returns {Promise<void>}
   */
  const remove = async () => {
    await download(deleteQueue)
      .then(() => {
        // @todo Waiting BackEnd API
        console.info("Remove %s", deleteQueue)
      })
      .finally(resetDeleteQueue)
  }

  /**
   * Animate download button
   *
   * @param element
   * @param active
   */
  const animateDownloadButton = (element, active) => {
    const icon = element.querySelector(".icon")

    if (active) {
      element.classList.add("blocked")
      icon.classList.remove("fa-download")
      icon.classList.add("fa-spinner", "fa-spin")
    } else {
      icon.classList.remove("fa-spinner", "fa-spin")
      icon.classList.add("fa-check")

      setTimeout(() => {
        icon.classList.remove("fa-check")
        icon.classList.add("fa-download")
        element.classList.remove("blocked")
      }, 1000)
    }
  }

  /**
   * Reset changes
   * Clear modal textarea
   */
  const resetChanges = () => setDeployChanges("")

  /**
   * Reset delete queue
   */
  const resetDeleteQueue = () => setDeleteQueue(null)

  /**
   * Set active className on current gateway config list entry
   *
   * @param data
   * @returns {string}
   */
  const rowClassName = data => data.active && "active"

  /**
   * Confirmation dialog box
   *
   * @returns {React.JSX.Element}
   */
  const DialogContent = () => (
    <>
      <div className="field-container">
        <div className="field">
          <label className="label label-required">{t("common.changes")}</label>
          <div className="field-input">
            <InputTextarea
              required
              value={deployChanges}
              onChange={e => setDeployChanges(e.target.value)}
            />
          </div>
        </div>
      </div>
      <p>{t("common.confirm_gateway_deployment_message")}</p>
    </>
  )

  /**
   * List entry actions
   *
   * @param data
   * @returns {React.JSX.Element}
   */
  const actionsBodyTemplate = data => (
    <div className="p-datatable-action-buttons">
      {/*
      {!data.active && (
        <button onClick={e => confirmDelete(data.id, e)}>
          <i
            className={`fa-solid ${data.id === deleteQueue ? "fa-spinner fa-spin" : "fa-trash-can"}`}
          />
        </button>
      )}
      */}

      <button onClick={e => download(data.id, e)}>
        <i className="icon fa-solid fa-download" />
      </button>
    </div>
  )

  /**
   * Handle gateway mutation
   * Retrieve deployment history
   */
  useEffect(() => {
    gateway && retrieve()
  }, [gateway])

  return (
    <>
      <section className="list-section">
        <ListActions>
          <ButtonWithLoader
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("common.generate")}
            loading={processing}
            onClick={() => setConfirmVisible(true)}
          />
        </ListActions>
        <ListLayout
          columns={columns}
          value={history}
          loading={loading}
          count={history.length}
          rowClassName={rowClassName}
          actionsBodyTemplate={actionsBodyTemplate}
          sortField="created_at"
          sortOrder={-1}
        />
      </section>

      {/* Deploy confirm modal */}
      <ConfirmModal
        visible={confirmVisible}
        header={t("common.confirm_gateway_deployment")}
        accept={deploy}
        pt={{
          acceptButton: {
            disabled: !deployChanges || containSpaceOnly(deployChanges)
          }
        }}
        closable={false}
        dismissableMask={false}
        message={DialogContent}
        onHide={() => setConfirmVisible(false)}
      />

      {/* Delete confirmation modal */}
      <ConfirmModal
        visible={confirmDeleteVisible}
        header={t("common.confirm_gateway_deployment_history_delete")}
        accept={remove}
        reject={resetDeleteQueue}
        closable={false}
        dismissableMask={false}
        message={t("common.confirm_gateway_deployment_history_delete_message")}
        onHide={() => setConfirmDeleteVisible(false)}
        onClose={resetDeleteQueue}
      />
    </>
  )
}

export default Deployment
