import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"
import i18n from "i18next"

export const CarelNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      scheme: {
        label: i18n.t("common.scheme"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "HTTP", label: i18n.t("common.http") },
            { value: "HTTPS", label: i18n.t("common.https") }
          ]
        }
      },
      target: {
        label: i18n.t("common.target"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "PlantVisorPRO", label: "common.plant_visor_pro" },
            { value: "boss", label: "common.boss" }
          ]
        }
      },
      tls_version: {
        label: i18n.t("common.tls_version"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "TLSv1", label: "common.tls_version_1" },
            { value: "TLSv1_1", label: "common.tls_version_1_1" },
            { value: "TLSv1_2", label: "common.tls_version_1_2" }
          ]
        }
      },
      low_cipher: {
        label: i18n.t("common.low_cipher"),
        field: InputSwitch,
        options: {}
      },
      user: {
        label: i18n.t("common.user"),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      password: {
        label: i18n.t("common.password"),
        field: InputText,
        options: {
          required: true,
          type: "password",
          maxLength: 255
        }
      },
      confirm_password: {
        label: i18n.t("common.confirm_password"),
        field: InputText,
        options: {
          required: true,
          type: "password",
          maxLength: 255
        }
      }
    }
  }
}

export const CarelOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      scheme: { label: i18n.t("common.scheme") },
      target: { label: i18n.t("common.target") },
      tls_version: { label: i18n.t("common.tls_version") },
      low_cipher: { label: i18n.t("common.low_cipher") },
      user: { label: i18n.t("common.user") }
    }
  }
}
