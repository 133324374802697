import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { removeSpaces } from "../api"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")

  const bulkDelete = async () => {
    setProcessing(true)
    await removeSpaces(siteStore.id, { space_ids: ids }, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (status === 204) {
          dispatch(
            addNotifications([successNotification(t("common.spaces"), t("common.delete_success"))])
          )
          navigate(
            `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/`
          )
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.spaces"), message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    <DeleteConfirmation
      objectType={t("common.spaces")}
      objectNames={[`${ids.length} ${t("common.spaces")}`]}
      remove={bulkDelete}
      processing={processing}
      goBack={() => navigate(-1)}
    />
  )
}

export default DeleteBulk
