import { CardCreateItem, Cards, Loader } from "@software-engineering/hivolution-frontend-utils"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { organization } from "utils/store/organization"
import { site } from "utils/store/site"
import { useSelector } from "react-redux"
import { FlexContractRoutesContext } from "../context"
import { useNavigate } from "react-router-dom"

export default function Home() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { flexContract, isLoading, retrieveOneFlexContractCurrentSite } =
    useContext(FlexContractRoutesContext)

  useEffect(() => {
    if (flexContract) {
      navigate(
        `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/${flexContract.contract_id}/parameters/`
      )
    } else {
      retrieveOneFlexContractCurrentSite()
    }
  }, [flexContract])

  return isLoading ? (
    <Loader />
  ) : (
    <Cards>
      <CardCreateItem
        url={`/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/create/`}
        title={t("common.create_asset", { asset: t("common.flex_contract").toLowerCase() })}
        icon="fa-solid fa-circle-plus add-icon"
      />
    </Cards>
  )
}
