import { paginatedRequest } from "/utils/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { site } from "/utils/store/site"
import { CardCreateItem, Cards, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)

  const getDetailUrl = gatewayId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gatewayId}/`
  }

  const retrieve = async () => {
    setLoading(true)
    await paginatedRequest(
      `connectivity/sites/${siteStore.id}/gateways/`,
      auth.userData.access_token
    )()
      .then(results => {
        setItems(results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.gateway"), error.message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    siteStore.id && retrieve()
  }, [siteStore.id])

  return loading ? (
    <Loader />
  ) : (
    <Cards
      items={items.map(item => {
        return {
          icon: "fa-solid fa-house-signal",
          url: getDetailUrl(item.id),
          title: item.name
        }
      })}
    >
      <CardCreateItem
        url={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/create/`}
        title={t("common.create")}
        icon="fa-solid fa-circle-plus add-icon"
      />
    </Cards>
  )
}
