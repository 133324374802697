import axios from "axios"
import { config } from "/utils/api"

export async function list(siteId, queryParams, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/v2/sites/${siteId}/states/`,
    config({ token, queryParams })
  )
}

// deprecated
export async function retrieveFilterValues(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/allvalues`,
    config({ token })
  )
}

export async function retrieve(siteId, stateId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/v2/sites/${siteId}/states/${stateId}/`,
    config({ token })
  )
}

export async function add(siteId, data, token) {
  return await axios.post(
    `${window.BACKEND_URL}/optimization/v2/sites/${siteId}/states/`,
    data,
    config({ token })
  )
}

export async function update(siteId, stateId, data, token) {
  return await axios.put(
    `${window.BACKEND_URL}/optimization/v2/sites/${siteId}/states/${stateId}/`,
    data,
    config({ token })
  )
}

export async function remove(siteId, stateId, token) {
  return await axios.delete(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/state/history/${stateId}`,
    config({ token })
  )
}

export async function getStateArchive(siteId, stateId, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/optimization/sites/${siteId}/state/${stateId}/download`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function listGatewayBySite(siteId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/connectivity/sites/${siteId}/gateways/`,
    config({ token })
  )
}
