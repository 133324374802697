import { retrieve } from "./api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader, TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import { site } from "/utils/store/site"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"

import List from "./components/List"
import { organization } from "/utils/store/organization"
import { useTranslation } from "react-i18next"

const EdgeControllerRoutesContext = createContext(null)

const EdgeControllerContent = () => {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { edgeControllerId } = useParams()

  const { t } = useTranslation()
  const auth = useAuth()
  const [edgeController, setEdgeController] = useState()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    if (siteStore.id && edgeControllerId) {
      setTabs([
        {
          name: t("common.overview"),
          active: location.pathname.endsWith(`/edge-controllers/${edgeControllerId}/`),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/edge-controllers/${edgeControllerId}/`
        }
      ])

      retrieveDetail()
    }
  }, [siteStore.id, edgeControllerId])

  const retrieveDetail = async () => {
    await retrieve(siteStore.id, edgeControllerId, auth.userData.access_token)
      .then(response => {
        setEdgeController(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.edge_controller"), error.message)]))
      })
  }

  return (
    <EdgeControllerRoutesContext.Provider value={{ edgeController, retrieveDetail }}>
      {edgeController ? (
        <TabsLayout
          title={`${t("common.edge_controller")} #${edgeController.id}`}
          tabs={tabs}
          setTabs={setTabs}
        />
      ) : (
        <Loader />
      )}
    </EdgeControllerRoutesContext.Provider>
  )
}

export { EdgeControllerRoutesContext }
export default function EdgeControllerRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":edgeControllerId"
        element={<EdgeControllerContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
