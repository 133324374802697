{
  "Aggregator": "Agrégateur",
  "Aggregators": "Agrégateurs",
  "Back to edge block template list": "Retour à la liste des modèles de blocs Edge",
  "Boxes": "Boxes",
  "Cancel": "Annuler",
  "City": "Ville",
  "Country": "Pays",
  "Country code": "Code de pays",
  "Create": "Créer",
  "Create aggregator": "Créer un agrégateur",
  "Create box": "Créer une box",
  "Create Distributor": "Créer un distributeur",
  "Create edge block template": "Créer un modèle de bloc edge",
  "Create Retailer": "Créer un détaillant",
  "Create TSO": "Créer un TSO",
  "Delete gateway box link to a site is not allowed": "Supprimer une passerelle box reliée à un site n'est pas autorisé.",
  "Description": "Description",
  "Distributor": "Distributeur",
  "Distributors": "Distributeurs",
  "Edge block template": "Modèle de bloc Edge",
  "Edge block templates": "Modèles de bloc Edge",
  "Energy companies": "Compagnies d'énergie",
  "Equipment type": "Type d'équipement",
  "Gateway box": "Gateway box",
  "Gateway boxes": "Gateway boxes",
  "Gateways": "Passerelles",
  "Manufacturer": "Manufactureur",
  "Model": "Modèle",
  "Name": "Nom",
  "OWM City ID": "OWM ville ID",
  "Priority": "Priorité",
  "Quantity": "Quantité",
  "Retailer": "Détaillant",
  "Retailers": "Détaillants",
  "Save": "Enregistrer",
  "Select Country": "Choisir un pays",
  "Serial ID": "Numéro de série",
  "The aggregator has been created": "L'agrégateur a été créé.",
  "The aggregator has been removed": "L'agrégateur a été supprimé.",
  "The aggregator has been updated": "L'agrégateur a été mis à jour.",
  "The distributor has been created": "Le distributeur a été créé.",
  "The distributor has been removed": "Le distributeur a été supprimé.",
  "The distributor has been updated": "Le distributeur a été mis à jour.",
  "The edge block template has been created": "Le modèle de bloc Edge a été créé.",
  "The edge block template has been removed": "Le modèle de bloc Edge a été supprimé.",
  "The edge block template has been updated": "Le modèle de bloc Edge a été mis à jour.",
  "The gateway box has been created": "La gateway box a été créée.",
  "The gateway box has been removed": "La gateway box a été supprimée.",
  "The gateway box has been updated": "La gateway box a été mise à jour.",
  "The retailer has been created": "Le détaillant a été créé.",
  "The retailer has been removed": "Le détaillant a été supprimé.",
  "The retailer has been updated": "Le détaillant a été mis à jour.",
  "The TSO has been created": "Le TSO a été créé.",
  "The TSO has been removed": "Le TSO a été supprimé.",
  "The TSO has been updated": "Le TSO a été mis à jour.",
  "The weather zone has been activated": "La zone météorologique a été activé",
  "The weather zone has been desactivated": "La zone météorologique a été desactivé",
  "Timezone": "Fuseau horaire",
  "TSO": "TSO",
  "Unit": "Unité",
  "Units & quantities": "Unités & quantités",
  "Updated by": "Mis à jour par",
  "Weather zones": "Zones météorologique"
}
