{
  "Active": "Actif",
  "Add": "Ajouter",
  "Aggregator Response": "Réponse de l'agrégateur",
  "Base informations": "Informations de base",
  "Batch size": "Taille du lot",
  "Bills": "Facturations",
  "Cancel": "Annuler",
  "CIDR format: {{format}}": "CIDR format: {{format}}",
  "Coef": "Coef",
  "Comment": "Commentaire",
  "Connect": "Connecter",
  "Connect a network": "Connecter un réseau",
  "Connectivity view": "Vue de la connectivité",
  "Connector instance": "Instance de connecteur",
  "connector_instances": "Instances de connecteurs",
  "Create": "Créer",
  "Create connector instance": "Créer une instance de connecteur",
  "Create energy delivery point": "Créer un point de livraison d'énergie",
  "Create flex opti": "Créer une optimisation flexible",
  "Create store": "Créer un magasin",
  "Curtail capacity": "Réduire la capacité",
  "Curtail end": "Fin de la réduction",
  "Curtail start": "Début de la réduction",
  "delete": {
    "success": "Supprimé avec succès"
  },
  "Delivery points": "Points de livraison",
  "Description": "Description",
  "Distributor": "Distributeur",
  "End validity": "Fin de validité",
  "Energy": "Énergie",
  "Energy delivery point": "Point de livraison d'énergie",
  "Energy type": "Type d'énergie",
  "Equivalent metering": "Mesurage équivalent",
  "error": "Erreur",
  "Gateway interface": "Interface de passerelle",
  "Gateway point": "Point de passerelle",
  "History": "Historique",
  "Infos": "Infos",
  "IP Address": "Adresse IP",
  "Load curtailment for": "Réduction de charge pour",
  "Manage modbus response timeouts": "Gérer les délais d'attente de réponse Modbus",
  "Manage timeout": "Gérer le délai d'attente",
  "Market schedule group proposal history": "Historique des propositions de groupe de calendrier de marché",
  "Meter ID": "ID de compteur",
  "Name": "Nom",
  "Network": "Réseau",
  "Network interface": "Interface réseau",
  "Network interfaces": "Interfaces réseau",
  "Network interfaces has been removed": "Les interfaces réseaux ont été supprimées.",
  "Network IP Range": "Plage IP du réseau",
  "No": "Non",
  "Node": "Nœud",
  "Number of points": "Nombre de points",
  "Number of stores": "Nombre de magasins",
  "Overview": "Vue d'ensemble",
  "Port": "Port",
  "Proposal for aggregator": "Proposition pour l'agrégateur",
  "Proposal sent": "Proposition envoyée",
  "Proposed by": "Proposé par",
  "Proposed time": "Temps proposé",
  "Protocol instance": "Instance de protocole",
  "Protocol instances": "Instances de protocole",
  "Protocol instances has been removed": "Les instances de protocole ont été supprimées.",
  "Protocols": "Protocoles",
  "Pulling interval": "Intervalle de tirage",
  "Request time": "Temps de demande",
  "Requested by": "Demandé par",
  "Requested?": "Demandé ?",
  "Response timeout": "Délai de réponse",
  "Response timeouts has been updated": "Les délais de réponse ont été mis à jour",
  "Save": "Enregistrer",
  "Schedule date": "Date de planification",
  "Send to aggregator": "Envoyer à l'agrégateur",
  "Sent by": "Envoyé par",
  "Sleep time": "Temps de sommeil",
  "Start validity": "Début de validité",
  "Store": "Stockage",
  "Stores": "Stockages",
  "Stores has been removed": "Les stockages ont été supprimés.",
  "The connector instance has been created": "L'instance de connecteur a été créée.",
  "The connector instance has been removed": "L'instance de connecteur a été supprimée.",
  "The connector instance has been updated": "L'instance de connecteur a été mise à jour.",
  "The energy delivery point has been created": "Le point de livraison d'énergie a été créé.",
  "The energy delivery point has been removed": "Le point de livraison d'énergie a été supprimé.",
  "The energy delivery point has been updated": "Le point de livraison d'énergie a été mis à jour.",
  "The flex opti has been created": "L'optimisation flexible a été créée.",
  "The flex opti has been removed": "L'optimisation flexible a été supprimée.",
  "The flex opti has been updated": "L'optimisation flexible a été mise à jour.",
  "The gateway interface has been created": "L'interface de passerelle a été créée.",
  "The gateway interface has been removed": "L'interface de passerelle a été supprimée.",
  "The network interface has been updated": "L'interface réseau a été mise à jour.",
  "The proposal for aggregator has been created": "La proposition pour l'agrégateur a été créée.",
  "The proposal for aggregator has been removed": "La proposition pour l'agrégateur a été supprimée.",
  "The proposal has been sent to the aggregator": "La proposition a été envoyée à l'agrégateur.",
  "The protocol instance has been created": "L'instance de protocole a été créée.",
  "The protocol instance has been removed": "L'instance de protocole a été supprimée.",
  "The protocol instance has been updated": "L'instance de protocole a été mise à jour.",
  "The store has been created": "Le magasin a été créé.",
  "The store has been removed": "Le magasin a été supprimé.",
  "The store has been updated": "Le magasin a été mis à jour.",
  "Timeouts": "Délais d'attente",
  "Type": "Type",
  "Update response timeouts": "Mettre à jour les délais d'attente de réponse",
  "Updated by": "Mis à jour par",
  "Updated time": "Heure de mise à jour",
  "Yes": "Oui"
}
