import { useTranslation } from "react-i18next"
import { Dropdown } from "@software-engineering/hivolution-frontend-utils"

export const GatewayVersion = (versions, loading) => {
  const { t } = useTranslation()

  return {
    version: {
      label: t("common.version"),
      field: Dropdown,
      options: {
        required: true,
        options: versions,
        optionLabel: "version",
        optionValue: "version",
        loading: loading,
        placeholder: loading
          ? t("common.loading")
          : t("common.select", { value: t("common.version") })
      }
    }
  }
}
