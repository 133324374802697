import { header } from "/utils/api.js"
import menu from "/utils/menu"
import { organization } from "/utils/store/organization.js"
import { sidebar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { SideBar } from "@software-engineering/hivolution-frontend-utils"
import axios from "axios"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"

export default function SideBarManager() {
  const auth = useAuth()
  const menuStore = useSelector(sidebar)
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const location = useLocation()
  const [logo, setLogo] = useState()
  const [mainTitle, setMainTitle] = useState(null)
  const [siteName, setSiteName] = useState(null)
  const [apiInfos, setApiInfos] = useState()
  const [loading, setLoading] = useState(true)

  const getAPIInfos = async () => {
    setLoading(true)
    await axios
      .get(`${window.BACKEND_URL}/openapi.json`, header(auth.userData.access_token))
      .then(({ data }) => {
        setApiInfos(data)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    switch (menuStore.menuName) {
      case "site":
        siteStore.pretty_name && setSiteName(siteStore.pretty_name)
        break

      case "organizations":
        organizationStore.id && setMainTitle(organizationStore.name)
        organizationStore.logo && setLogo(organizationStore.logo)
        siteStore.id && setMainTitle(siteStore.client_name)
        setSiteName(null)
        break

      default:
        setMainTitle("BeeBryte")
        setSiteName(null)
        setLogo(null)
        break
    }
  }, [menuStore.menuName, menuStore.siteId, siteStore.id, organizationStore])

  useEffect(() => {
    getAPIInfos()
  }, [])

  return (
    <SideBar
      className="main-sidebar"
      menu={menu(menuStore.menuName, organizationStore.id, menuStore.siteId)}
      currentUrl={location.pathname}
      logoUrl={organizationStore.id ? `/organizations/${organizationStore.id}/sites/` : "/"}
      siteUrl={siteStore ? `/organizations/${organizationStore.id}/sites/${siteStore.id}` : "/"}
      mainTitle={mainTitle}
      siteName={siteName}
      logo={logo}
      apiInfos={apiInfos}
      loadingApiInfos={loading}
    />
  )
}
