import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"

import {
  BaseLayout,
  Loader,
  OverView,
  ReturnButton
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { useNavigate, useParams } from "react-router-dom"

import { retrieve, update } from "../api"
import { EdgeBlockTemplate } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [object, setObject] = useState(null)
  const { id } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)

  const retrieveDetail = async () => {
    await retrieve(id, auth.userData.access_token)
      .then(response => {
        setObject(response.data)
        setLoading(false)
      })
      .catch(error => {
        dispatch(
          addNotifications(errorNotification(t("common.edge_block_template"), error.message))
        )
      })
  }

  useEffect(() => {
    retrieveDetail()
  }, [id])

  const updateObject = async function (e) {
    e.preventDefault()
    await update(id, data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([
            successNotification("common.edge_block_template", t("common.update_success"))
          ])
        )
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        dispatch(
          addNotifications([errorNotification(t("common.edge_block_template"), error.message)])
        )
      })
  }

  return object ? (
    <>
      <ReturnButton
        label={t("common.back_edge_block_template_list")}
        onClick={() => navigate("/optimization/edge-block-templates")}
      />
      <BaseLayout title={`${t("common.edge_block_template")} ${object.name}`}>
        <OverView
          object={object}
          model={EdgeBlockTemplate()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          loading={loading}
          setEdit={setEdit}
          deleteUrl={`/configuration/edge-block-templates/${id}/delete/`}
        />
      </BaseLayout>
    </>
  ) : (
    <Loader />
  )
}
