{
  "Coef": "Coef",
  "Data step": "Data step",
  "Description": "Description",
  "Distributor": "Distributor",
  "Electricity": "Electricity",
  "End validity": "End validity",
  "Equivalent metering equation": "Equivalent metering equation",
  "Equivalent metering intercept": "Equivalent metering intercept",
  "Equivalent Metering Intercept": "Equivalent Metering Intercept",
  "Gas": "Gas",
  "Meter ID": "Meter ID",
  "Point": "Point",
  "Start validity": "Start validity",
  "Transmission company": "Transmission company",
  "Transmission Company": "Transmission Company",
  "Type": "Type"
}
