import { retrieve } from "/apps/configuration/energy-company/distributor/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import Table from "./components/List"

const DistributorRoutesContext = createContext(null)

const DistributorContent = () => {
  const auth = useAuth()
  const { id } = useParams()
  const [distributor, setDistributor] = useState()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const retrieveDetail = async () => {
    await retrieve(id, auth.userData.access_token)
      .then(response => {
        setDistributor(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.distributor"), error.message)]))
      })
  }

  useEffect(() => {
    id && retrieveDetail()
  }, [id])

  return (
    <DistributorRoutesContext.Provider value={{ distributor, retrieveDetail }}>
      {distributor ? <Outlet /> : <Loader />}
    </DistributorRoutesContext.Provider>
  )
}

export { DistributorRoutesContext }
export default function DistributorRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<Table />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":id"
        element={<DistributorContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
