import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom"
import { getVersions } from "../api"
import { GatewayVersion } from "../models"

export default function Version() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to select version
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/version" element={<Version />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [data, setData] = useState({})
  const [versions, setVersions] = useState([])
  const [loading, setLoading] = useState(false)
  const [errors] = useState({})
  const gatewayIds = searchParams.getAll("gateway_id")

  // Get last 10 versions
  const retrieveVersions = async (queryParams = { limit: 10, sort: "version:DESC" }) => {
    setLoading(true)
    await getVersions(auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.content && setVersions(data.content)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification("common.versions", message)]))
      })
      .finally(() => setLoading(false))
  }

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const goToConfirm = () => {
    console.log(gatewayIds)
    navigate({
      pathname: "/configuration/gateways/update-software/update/",
      search: createSearchParams({
        gateway_id: gatewayIds,
        version_id: versions.find(element => element.version === data.version).id
      }).toString()
    })
  }

  useEffect(() => {
    retrieveVersions()
  }, [])

  return (
    data && (
      <>
        <div className="base-content-title">
          <h2>{t("common.updated_version")}</h2>
        </div>
        <form
          className="form-view"
          onSubmit={goToConfirm}
        >
          <FormContent
            model={GatewayVersion(versions, loading)}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("common.save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
