import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const commonsFields = spaces => {
  return {
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },
    model: {
      label: i18n.t("common.model"),
      field: InputText,
      options: { maxLength: 255 }
    },
    manufacturer: {
      label: i18n.t("common.manufacturer"),
      field: InputText,
      options: { maxLength: 255 }
    },
    space: {
      label: i18n.t("common.space"),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    }
  }
}

export const commonsOverview = () => {
  return {
    name: { label: i18n.t("common.name") },
    model: { label: i18n.t("common.model") },
    manufacturer: { label: i18n.t("common.manufacturer") },
    space_name: { label: i18n.t("common.space") },
    description: { label: i18n.t("common.description") }
  }
}
