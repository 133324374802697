import React, { createContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAuth } from "oidc-react"
import { Message } from "primereact/message"

import { errorNotification, successNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { site } from "utils/store/site"
import { organization } from "utils/store/organization"

import {
  createFlexContract as createFlexContractApi,
  retrieveFlexContratBySite,
  retrieveFlexContratByContractId,
  deleteFlexContract,
  updateFlexContract
} from "./api"
import { FLEX_CONTRACT_FIELDS } from "./models"
import FlexibilityRoutes from "./routes"

export const FlexContractRoutesContext = createContext(null)

export function FlexContractContextProvider() {
  const auth = useAuth()
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [flexContract, setFlexContract] = useState(null)
  const [isFetchingFlexContractError, setIsFetchingFlexContractError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const initFlexContractDetails = data => {
    /**
     * In front end we can only have one optimization gateway, that not the case in the backend
     * so we take the first element of the array
     */
    if (!data) return

    // @todo ugly
    data[FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY] =
      data[FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY][0]
    data["optimization_gateways_names"] = data["optimization_gateways_names"][0]

    setFlexContract(data)
    return data
  }

  const retrieveOneFlexContractCurrentSite = async () => {
    setIsLoading(true)
    setIsFetchingFlexContractError(false)
    return await retrieveFlexContratBySite(siteStore.id, auth.userData.access_token)
      .then(response => {
        // this is a paginated response, for now we just take the first element as the flex contract
        if (response?.data?.results?.length > 0) {
          return initFlexContractDetails(response.data.results[0])
        }
      })
      .catch(error => {
        setIsFetchingFlexContractError(true)
        dispatch(addNotifications([errorNotification(t("common.flex_contract"), error.message)]))
      })
      .finally(() => setIsLoading(false))
  }

  const retrieveFlexContractById = async id => {
    setIsLoading(true)
    setIsFetchingFlexContractError(false)
    return await retrieveFlexContratByContractId(siteStore.id, id, auth.userData.access_token)
      .then(response => {
        if (response.data) return initFlexContractDetails(response.data)
      })
      .catch(error => {
        setIsFetchingFlexContractError(true)
        dispatch(addNotifications([errorNotification(t("common.flex_contract"), error.message)]))
      })
      .finally(() => setIsLoading(false))
  }

  const createFlexContract = async payload => {
    setIsProcessing(true)
    return await createFlexContractApi(siteStore.id, payload, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([successNotification(t("common.create"), t("common.creation_success"))])
        )

        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/${response.data.contract_id}/parameters/`
        )
        setFlexContract(response.data)
        return response.data
      })
      .catch(error => {
        if (error?.response?.data?.message) {
          dispatch(
            addNotifications([
              errorNotification(t("common.flex_contract"), error.response.data.message)
            ])
          )
        } else {
          dispatch(
            addNotifications([errorNotification(t("common.flex_contract"), error.message ?? error)])
          )
        }
        throw error
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const deleteCurrentFlexContract = async () => {
    if (!flexContract) return
    setIsProcessing(true)
    await deleteFlexContract(siteStore.id, flexContract.contract_id, auth.userData.access_token)
      .then(() => {
        setFlexContract(null)
        dispatch(
          addNotifications([
            successNotification(t("common.flex_contract"), t("common.delete_success"))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/`
        )
      })
      .catch(error => {
        const message = error?.response?.data?.message
          ? error.response.data.message
          : (error.message ?? t("common.delete_error"))
        dispatch(addNotifications([errorNotification(t("common.flex_contract"), message)]))
      })
      .finally(() => setIsProcessing(false))
  }

  const updateCurrentFlexContract = async data => {
    setIsProcessing(true)
    return updateFlexContract(
      siteStore.id,
      flexContract.contract_id,
      data,
      auth.userData.access_token
    )
      .then(response => {
        // update the form and flexContract with the new data
        setFlexContract({ ...flexContract, ...response.data })
        dispatch(
          addNotifications([
            successNotification(t("common.flex_contract"), t("common.update_success"))
          ])
        )
        return response.data
      })
      .catch(error => {
        if (error?.response?.data?.message) {
          dispatch(
            addNotifications([
              errorNotification(t("common.flex_contract"), error.response.data.message)
            ])
          )
        } else {
          dispatch(
            addNotifications([errorNotification(t("common.flex_contract"), error.message ?? error)])
          )
        }
        throw error
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const context = {
    isLoading, // loading flex contract instance
    isProcessing, // form processing (create, update, delete)
    flexContract,
    retrieveOneFlexContractCurrentSite,
    retrieveFlexContractById,
    createFlexContract,
    updateCurrentFlexContract,
    deleteCurrentFlexContract
  }

  if (isFetchingFlexContractError) {
    return (
      <Message
        className="mt-2 w-full"
        severity="error"
        text={t("common.error_fetching_data")}
      />
    )
  }

  return (
    <FlexContractRoutesContext.Provider value={context}>
      <FlexibilityRoutes />
    </FlexContractRoutesContext.Provider>
  )
}
