import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { update } from "../api"
import { PointRoutesContext } from "../routes"
import {
  AUTOMATION_POINT_TYPES,
  MAPPING_NODE_TYPES,
  READ_WRITE
} from "/utils/constants/automation_point"
import { handleErrors } from "/utils/error"
import { get_form_model, get_overview_model } from "../models/utils"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [object, setObject] = useState(null)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingNodes, setLoadingNodes] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [nodes, setNodes] = useState([])
  const { point, retrieveDetail } = useContext(PointRoutesContext)

  const updateObject = async e => {
    e.preventDefault()
    setProcessing(true)

    const pointData = { ...data }
    if (pointData.type === AUTOMATION_POINT_TYPES.SM_CONTROLLER) {
      pointData.rw = pointData.writable ? READ_WRITE.WRITE : READ_WRITE.READ
    }

    await update(siteStore.id, point.id, pointData, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([
            successNotification(t("common.automation_point"), t("common.update_success"))
          ])
        )
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.automation_point"))
      })
      .finally(() => setProcessing(false))
  }

  const retrieveNodes = async () => {
    setLoadingNodes(true)
    await paginatedRequest(
      `asset/sites/${siteStore.id}/nodes/?type=${MAPPING_NODE_TYPES[object.type]}`,
      auth.userData.access_token
    )()
      .then(results => {
        setNodes(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.automation_point"), message)]))
      })
      .finally(() => setLoadingNodes(false))
  }

  useEffect(() => {
    if (point) {
      setLoading(false)
      setObject(point)
    }
  }, [point])

  useEffect(() => {
    edit && object && object.type && retrieveNodes()
  }, [siteStore.id, object, edit])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    object &&
    siteStore.id && (
      <>
        <div className="base-content-title">
          <h2>
            {object.type} – {object.name}
          </h2>
        </div>
        <OverView
          object={object}
          model={data && get_form_model(data.type, nodes, loadingNodes)}
          overviewModel={object && get_overview_model(object.type)}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          loading={loading}
          processing={processing || loadingNodes}
          setEdit={setEdit}
          deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/${point.id}/delete/`}
        />
      </>
    )
  )
}
