import CM from "@uiw/react-codemirror"
import * as React from "react"

export default function CodeMirror(props) {
  const { invalid, ...options } = props

  const onChange = value => {
    props.onChange(value)
  }

  const options2 = {
    ...options,
    onChange: onChange
  }

  return (
    <div className={`codemirror-wrapper ${!!invalid && "p-invalid"}`}>
      {React.createElement(CM, options2)}
    </div>
  )
}

CodeMirror.displayName = "CodeMirror"
