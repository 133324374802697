import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Sigfox = ({ spaces, loadingSpaces }) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: {
        required: true
      }
    },
    external_id: {
      label: i18n.t("common.external_id"),
      field: InputText,
      options: {
        required: true
      }
    },
    space: {
      label: i18n.t("common.space"),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true,
        loading: loadingSpaces,
        placeholder: loadingSpaces
          ? i18n.t("common.loading")
          : i18n.t("common.select_a", { value: i18n.t("common.space").toLowerCase() })
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    }
  }
}

export const SigfoxOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("common.name") },
    external_id: { label: i18n.t("common.external_id") },
    space: { label: i18n.t("common.space") },
    description: { label: i18n.t("common.description") }
  }
}
