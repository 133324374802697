import { PROTOCOL_INSTANCE_TYPE } from "/utils/constants/protocol-instance"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { GatewayRoutesContext } from "../../../gateway/routes"
import { ConnectorInstanceRoutesContext } from "../../routes"
import { retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [selected, setSelected] = useState([])
  const { connector } = useContext(ConnectorInstanceRoutesContext)
  const { gateway } = useContext(GatewayRoutesContext)

  const retrieve = async () => {
    await retrieveList(siteStore.id, connector.id, auth.userData.access_token)
      .then(response => {
        setItems(
          response.data.results.map(protocolInstance => {
            return {
              ...protocolInstance,
              ...{
                label: PROTOCOL_INSTANCE_TYPE[protocolInstance.type],
                gateway_ip_interface: protocolInstance.gateway_ip_interface.id,
                gateway_ip_interface_label: protocolInstance.gateway_ip_interface.label,
                gateway_ip_interface_info: protocolInstance.gateway_ip_interface
              }
            }
          })
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.protocol_instance"), message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && connector && gateway && retrieve()
  }, [siteStore.id, gateway, connector])

  const getDetailUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${objectId}/`

  const getDeleteUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${objectId}/delete/`

  const onRowClick = objectId => navigate(getDetailUrl(objectId))

  const infoBody = rowData => {
    return (
      <div className="flex">
        {rowData.type === "bacnetip" && `${t("common.port")} : ${rowData.gateway_udp_port}`}
        {rowData.type === "smxmlapi" &&
          `${t("common.batch_size")} : ${rowData.batch_size} - ${t("common.sleep_time")} : ${rowData.sleep_time}`}
        {rowData.type === "modbusip" && (
          <button
            className="list-action-button"
            onClick={() =>
              navigate(
                `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/${rowData.id}/?editTimeout=1`
              )
            }
          >
            <i className="fa-solid fa-gears" />
            {t("common.manage_timeout")}
          </button>
        )}
      </div>
    )
  }

  const columns = [
    { field: "label", header: t("common.type") },
    { field: "gateway_ip_interface", header: t("common.gateway_interface") },
    { field: "info", header: t("common.infos"), body: infoBody }
  ]

  const menuEntries = []

  const menuFilters = { columns, items, filters, setFilters }

  return (
    <section className="list-section">
      <ListActions
        entries={menuEntries}
        filters={menuFilters}
      >
        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/create/`}
        >
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("common.create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
      />
    </section>
  )
}
