import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const CircuitBreaker = (spaces, circuitBreakers, loadingSpaces, loadingCircuitBreakers) => ({
  /*
   * List of fields for the model, like this
   */
  name: {
    label: i18n.t("common.name"),
    field: InputText,
    options: {
      required: true
    }
  },
  description: {
    label: i18n.t("common.description"),
    field: InputTextarea,
    options: {}
  },
  cabinet: {
    label: i18n.t("common.cabinet_name"),
    field: InputText,
    options: {}
  },
  space_id: {
    label: i18n.t("common.space"),
    field: Dropdown,
    options: {
      options: spaces,
      optionLabel: "name",
      optionValue: "id",
      filter: true,
      loading: loadingSpaces,
      placeholder: loadingSpaces
        ? i18n.t("common.loading")
        : i18n.t("common.select", {
            value: i18n.t("common.space").toLowerCase()
          })
    }
  },
  nominal_intensity: {
    label: i18n.t("common.nominal_intensity"),
    field: InputNumber,
    options: {
      showButtons: true
    }
  },
  unspecified_loads: {
    label: i18n.t("common.unspecified_loads"),
    field: InputSwitch,
    options: {}
  },
  parent_circuit_breaker_id: {
    label: i18n.t("common.parent_circuit_breaker"),
    field: Dropdown,
    options: {
      options: circuitBreakers,
      optionLabel: "name",
      optionValue: "id",
      filter: true,
      loading: loadingCircuitBreakers,
      placeholder: loadingCircuitBreakers
        ? i18n.t("common.loading")
        : i18n.t("common.select", {
            value: i18n.t("common.parent_circuit_breaker").toLowerCase()
          })
    }
  }
})

export const CircuitBreakerOverview = () => {
  return {
    name: {
      label: i18n.t("common.name")
    },
    description: {
      label: i18n.t("common.description")
    },
    cabinet: {
      label: i18n.t("common.cabinet_name")
    },
    space_name: {
      label: i18n.t("common.space")
    },
    nominal_intensity: {
      label: i18n.t("common.nominal_intensity"),
      field: InputNumber,
      options: {
        default: null,
        required: true
      }
    },
    unspecified_loads: {
      label: i18n.t("common.unspecified_loads")
    },
    parent_circuit_breaker_name: {
      label: i18n.t("common.parent_circuit_breaker")
    }
  }
}
