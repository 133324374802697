import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "utils/constants/api"
import { errorNotification, successNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { updateSideBar } from "utils/store/sidebar"
import { site } from "utils/store/site"
import { organization } from "utils/store/organization"
import {
  ListActions,
  ListLayout,
  CopyButton,
  BaseLayout
} from "@software-engineering/hivolution-frontend-utils"

import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { SortOrder } from "primereact/api"
import { list, retrieveFilterValues, getStateArchive } from "../api"

export default function List() {
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [count, setCount] = useState(0)

  const [loadingFilters, setLoadingFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState({})
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(null)
  const [sortField, setSortField] = useState(null)
  const [sortOrder, setSortOrder] = useState(SortOrder.ASC)

  const [exportLoading, setExportLoading] = useState(false)

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const buildQueryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  const retrieve = async queryParams => {
    setLoading(true)
    await list(siteStore.id, queryParams, auth.userData.access_token)
      .then(({ data }) => {
        setCount(data.count)
        if (data.results) {
          setItems(
            data.results.map(stateData => {
              return {
                ...stateData,
                updated_at: new Date(stateData.updated_at).toLocaleString()
              }
            })
          )
        }
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.states"), message)]))
      })
      .finally(() => setLoading(false))
  }

  // deprecated we should not retrieve all values for the filters
  const retrieveFilterValueCall = async () => {
    setLoadingFilters(true)
    await retrieveFilterValues(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setFilterValues({
          ...data,
          updated_at: Array.from(
            new Set(
              data.updated_at.map(date => {
                return {
                  label: new Date(date).toLocaleString(),
                  value: date
                }
              })
            )
          )
        })
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.filter_values"), message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const getDetailUrl = stateId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/${stateId}/`
  }

  const getDeleteUrl = stateId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/${stateId}/delete`
  }

  const onRowClick = stateId => {
    navigate(getDetailUrl(stateId))
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    }
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id &&
      (offset !== REQUEST_DEFAULT_OFFSET
        ? setOffset(REQUEST_DEFAULT_OFFSET)
        : retrieve(buildQueryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && offset !== null && retrieve(buildQueryParams())
  }, [limit, offset])

  useEffect(() => {
    retrieveFilterValueCall()
  }, [siteStore.id])

  const columns = [
    { field: "gateway_name", header: t("common.gateway"), sortable: true },
    { field: "type", header: t("common.type"), sortable: true },
    { field: "updated_by", header: t("common.modified_by"), sortable: true },
    { field: "updated_at", header: t("common.modified_at"), sortable: true }
  ]

  const menuFilters = {
    items,
    columnsFilter: columns,
    filters,
    setFilters, // add the translation of date to string
    filterValues
  }

  const exportStateDownload = (e, state) => {
    e.preventDefault()
    setExportLoading(true)
    getStateArchive(siteStore.id, state.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `${state.gateway_name}_${state.type}_${state.name}.zip`)
        dispatch(
          addNotifications([successNotification(t("common.export"), t("common.export_success"))])
        )
      })
      .catch(error => {
        const message = error?.response?.data?.message ?? error.message
        dispatch(addNotifications([errorNotification(t("common.export"), message)]))
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  const actionsBodyTemplate = state => {
    return (
      <div className="p-datatable-action-buttons">
        <CopyButton
          isUrl
          icon="fa-solid fa-link"
          value={getDetailUrl(state.id)}
        />

        {exportLoading && (
          <div className="p-component">
            <i className="fa-solid fa-spinner fa-spin" />
          </div>
        )}

        {!exportLoading && (
          <div
            className="p-component"
            onClick={e => exportStateDownload(e, state)}
          >
            <i className="fa-solid fa-download" />
          </div>
        )}

        <Link
          className="delete-button"
          to={getDeleteUrl(state.id)}
        >
          <i className="fa-regular fa-trash-can" />
        </Link>
      </div>
    )
  }

  return (
    siteStore.id && (
      <BaseLayout title={t("common.state")}>
        <section className="list-section">
          <ListActions
            filters={menuFilters}
            filterBackend={true}
            loading={loadingFilters}
          >
            <Link
              to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/create/`}
            >
              <Button
                rounded
                icon="fa-regular fa-plus"
                label={t("common.create")}
              />
            </Link>
          </ListActions>

          <ListLayout
            columns={columns}
            value={items}
            loading={loading}
            filters={filters}
            getDetailUrl={getDetailUrl}
            getDeleteUrl={getDeleteUrl}
            onRowClick={onRowClick}
            offset={offset}
            limit={limit}
            setOffset={setOffset}
            setLimit={setLimit}
            count={count}
            onSort={onSort}
            sortOrder={sortOrder}
            sortField={sortField}
            backendSort={true}
            actionsBodyTemplate={actionsBodyTemplate}
          />
        </section>
      </BaseLayout>
    )
  )
}
