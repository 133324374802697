import { OrganizationRoutesContext } from "/apps/organization/routes"
import { site } from "/utils/store/site"
import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useContext, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation } from "react-router-dom"
import AutomationNodeRoutes from "./automation-node/routes"
import AutomationPointRoutes from "./automation-point/routes"
import NetworkRoutes from "./network/routes"

export default function CommunicationRoutes() {
  const siteStore = useSelector(site)
  const location = useLocation()
  const { t } = useTranslation()
  const { organization } = useContext(OrganizationRoutesContext)
  const [tabs, setTabs] = useState([])

  useLayoutEffect(() => {
    setTabs([
      {
        name: t("common.networks"),
        active: location.pathname.includes("networks"),
        url: `/organizations/${organization.id}/sites/${siteStore.id}/assets/communication/networks/`
      },
      {
        name: t("common.automation_nodes"),
        active: location.pathname.includes("automation-nodes"),
        url: `/organizations/${organization.id}/sites/${siteStore.id}/assets/communication/automation-nodes/`
      },
      {
        name: t("common.automation_points"),
        active: location.pathname.includes("automation-points"),
        url: `/organizations/${organization.id}/sites/${siteStore.id}/assets/communication/automation-points/`
      }
    ])
  }, [location])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("common.communication")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="/networks/*"
          element={<NetworkRoutes />}
        />
        <Route
          index
          path="/automation-nodes/*"
          element={<AutomationNodeRoutes />}
        />
        <Route
          index
          path="/automation-points/*"
          element={<AutomationPointRoutes />}
        />
      </Route>
    </Routes>
  )
}
