import { paginatedRequest } from "/utils/api"
import { REQUEST_DEFAULT_LIMIT } from "/utils/constants/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { add } from "../api"
import { EnergyDeliveryPoint } from "../models"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const { siteId } = useParams()
  const [data, setData] = useState({ site: siteId, equivalent_metering_terms: [] })
  const [errors, setErrors] = useState({})
  const [distributors, setDistributors] = useState([])
  const [transmissionCompanies, setTransmissionCompanies] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingDistributors, setLoadingDistributors] = useState(true)
  const [loadingTransmissions, setLoadingTransmissions] = useState(true)

  const getDC = async () => {
    setLoadingDistributors(true)
    await paginatedRequest(
      `energy/energy_companies/?company_type=Distributor&limit=${REQUEST_DEFAULT_LIMIT}&offset=0`,
      auth.userData.access_token
    )()
      .then(results => {
        setDistributors(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.energy_delivery_point"), message)]))
      })
      .finally(() => setLoadingDistributors(false))
  }

  const getTC = async () => {
    setLoadingTransmissions(true)
    await paginatedRequest(
      `energy/energy_companies/?company_type=Transmission&limit=${REQUEST_DEFAULT_LIMIT}&offset=0`,
      auth.userData.access_token
    )()
      .then(results => {
        setTransmissionCompanies(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.energy_delivery_point"), message)]))
      })
      .finally(() => setLoadingTransmissions(false))
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await add(siteId, data, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([
            successNotification(t("common.energy_delivery_point"), t("common.creation_success"))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteId}/energy/energy-delivery-points/${response.data.id}`
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification(t("common.energy_delivery_point"), message)]))
      })
      .finally(() => setProcessing(false))
  }

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))

      getDC()
      getTC()
    }
  }, [siteStore.id])

  return (
    data && (
      <>
        <div className="base-content-title">
          <h2>
            {t("common.create_asset", { asset: t("common.energy_delivery_point").toLowerCase() })}
          </h2>
        </div>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={EnergyDeliveryPoint(
              distributors,
              transmissionCompanies,
              loadingDistributors,
              loadingTransmissions
            )}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              disabled={processing || loadingDistributors || loadingTransmissions}
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              disabled={processing || loadingDistributors || loadingTransmissions}
              label={t("common.save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
