import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const EdgeBlockTemplate = () => {
  return {
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: { required: true, maxLength: 255 }
    },
    priority: {
      label: i18n.t("common.priority"),
      field: InputNumber,
      options: { min: 1, max: 9, required: true }
    },
    condition: {
      label: i18n.t("common.condition"),
      field: InputTextarea,
      options: { required: true }
    },
    action: {
      label: i18n.t("common.action"),
      field: InputTextarea,
      options: { required: true }
    },
    description: { label: i18n.t("common.description"), field: InputTextarea },
    updated_by: {
      label: i18n.t("common.updated_by"),
      field: InputText,
      options: { required: true, disabled: true }
    }
  }
}
