import { SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { commonsFields, commonsOverview } from "./commons"
import { SMALL_INT_MAX_VALUE, SMALL_INT_MIN_VALUE } from "/utils/constants/input"

export const SMMeterNode = (spaces, retrieveSMNodes, getAllValues) => {
  return {
    ...commonsFields(spaces),
    ...{
      sm_node: {
        label: i18n.t("common.system_manager"),
        field: SelectArray,
        options: {
          getItems: retrieveSMNodes,
          getAllValues: getAllValues,
          keyLabel: "name",
          columns: [
            { field: "name", header: i18n.t("common.name") },
            { field: "model", header: i18n.t("common.model") },
            { field: "manufacturer", header: i18n.t("common.manufacturer") },
            { field: "proto", header: i18n.t("common.protocol") },
            { field: "sm_type", header: i18n.t("common.sm_type") },
            { field: "software", header: i18n.t("common.software") },
            { field: "user", header: i18n.t("common.user") }
          ],
          required: true
        }
      },
      node_addr: {
        label: i18n.t("common.node_address"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          max: SMALL_INT_MAX_VALUE,
          min: SMALL_INT_MIN_VALUE,
          useGrouping: false
        }
      },
      meter_id: {
        label: i18n.t("common.meter_id"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          max: SMALL_INT_MAX_VALUE,
          min: SMALL_INT_MIN_VALUE,
          useGrouping: false
        }
      }
    }
  }
}

export const SMMeterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      sm_node: {
        label: i18n.t("common.system_manager"),
        getValue: item => item?.name ?? ""
      },
      node_addr: { label: i18n.t("common.node_address") },
      meter_id: { label: i18n.t("common.meter_id") }
    }
  }
}
