import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { commonsFields, commonsOverview } from "./commons"
import { INT_MAX_VALUE } from "/utils/constants/input"

export const ModbusNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      slave_id: {
        label: i18n.t("common.slave_id"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 247,
          showButtons: true
        }
      },
      response_timeout: {
        label: i18n.t("common.response_timeout_seconds"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 0,
          max: INT_MAX_VALUE
        }
      }
    }
  }
}

export const ModbusOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      slave_id: { label: i18n.t("common.slave_id") },
      response_timeout: { label: i18n.t("common.response_timeout_seconds") }
    }
  }
}
