import { organization } from "/utils/store/organization"
import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import EnergyDeliveryPointRoutes from "./energy-delivery-point/routes"
import BillRoutes from "./bills/routes"

export default function EnergyRoutes() {
  const organizationStore = useSelector(organization)
  const location = useLocation()
  const { t } = useTranslation()
  const { siteId } = useParams()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        name: t("common.delivery_points"),
        active: location.pathname.includes("points"),
        url: `/organizations/${organizationStore.id}/sites/${siteId}/energy/energy-delivery-points/`
      },
      {
        name: t("common.bills"),
        active: location.pathname.includes("bills"),
        url: `/organizations/${organizationStore.id}/sites/${siteId}/energy/bills/`
      }
    ])
  }, [location])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("common.energy")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="energy-delivery-points/*"
          element={<EnergyDeliveryPointRoutes />}
        />
        <Route
          index
          path="bills/*"
          element={<BillRoutes />}
        />
      </Route>
    </Routes>
  )
}
