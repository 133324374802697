import { TransmissionRoutesContext } from "/apps/configuration/energy-company/transmission/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const { t } = useTranslation()
  const { transmission } = useContext(TransmissionRoutesContext)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(transmission.id, auth.userData.access_token)
      .then(() => {
        displayNotification([successNotification(t("common.tso"), t("common.delete_success"))])
        navigate("/configuration/energy-company/transmissions/")
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification(t("common.tso"), message)])
      })
      .finally(() => setProcessing(false))
  }

  return (
    transmission && (
      <DeleteConfirmation
        objectType={t("common.tso")}
        objectNames={[transmission.name]}
        remove={removeObject}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}
