import i18n from "i18next"

export const EVENT_TYPES = {
  BULK_IMPORT_NETWORK: "BULK_IMPORT_NETWORK",
  BULK_IMPORT_BACNET_NODE: "BULK_IMPORT_BACNET_NODE",
  BULK_IMPORT_MODBUS_NODE: "BULK_IMPORT_MODBUS_NODE",
  BULK_IMPORT_SM_NODE: "BULK_IMPORT_SM_NODE",
  BULK_IMPORT_CAREL_NODE: "BULK_IMPORT_CAREL_NODE",
  BULK_IMPORT_EQUIPMENT: "BULK_IMPORT_EQUIPMENT",
  BULK_IMPORT_BACNET_POINT: "BULK_IMPORT_BACNET_POINT",
  BULK_IMPORT_MODBUS_POINT: "BULK_IMPORT_MODBUS_POINT",
  BULK_IMPORT_SM_POINT: "BULK_IMPORT_SM_POINT",
  BULK_IMPORT_OPCUA_POINT: "BULK_IMPORT_OPCUA_POINT",
  BULK_IMPORT_CAREL_POINT: "BULK_IMPORT_CAREL_POINT",
  BULK_IMPORT_STATE_VALUE: "BULK_IMPORT_STATE_VALUE",
  BULK_IMPORT_SPACE: "BULK_IMPORT_SPACE",
  BULK_IMPORT_POINT: "BULK_IMPORT_POINT",
  BULK_IMPORT_CIRCUIT_BREAKER: "BULK_IMPORT_CIRCUIT_BREAKER",
  BULK_IMPORT_CIRCUIT_BREAKER_RELATIONS: "BULK_IMPORT_CIRCUIT_BREAKER_RELATIONS"
}

export const getEventTypeLabels = () => {
  /**
   * Kind of ugly, it's done because the direct import of a dict with i18n.t() will not working
   * The following code not working:
   * export const EVENT_TYPES_LABELS = {
   *  [EVENT_TYPES.BULK_IMPORT_NETWORK]: i18n.t("common.bulk_import_network"),
   *  ...
   * }
   * The i18n.t() is not working in the import statement (it return undefined for all keys)
   */
  return {
    [EVENT_TYPES.BULK_IMPORT_NETWORK]: i18n.t("common.bulk_import_network"),
    [EVENT_TYPES.BULK_IMPORT_BACNET_NODE]: i18n.t("common.bulk_import_bacnet_node"),
    [EVENT_TYPES.BULK_IMPORT_MODBUS_NODE]: i18n.t("common.bulk_import_modbus_node"),
    [EVENT_TYPES.BULK_IMPORT_SM_NODE]: i18n.t("common.bulk_import_sm_node"),
    [EVENT_TYPES.BULK_IMPORT_CAREL_NODE]: i18n.t("common.bulk_import_carel_node"),
    [EVENT_TYPES.BULK_IMPORT_EQUIPMENT]: i18n.t("common.bulk_import_equipment"),
    [EVENT_TYPES.BULK_IMPORT_BACNET_POINT]: i18n.t("common.bulk_import_bacnet_point"),
    [EVENT_TYPES.BULK_IMPORT_MODBUS_POINT]: i18n.t("common.bulk_import_modbus_point"),
    [EVENT_TYPES.BULK_IMPORT_SM_POINT]: i18n.t("common.bulk_import_sm_point"),
    [EVENT_TYPES.BULK_IMPORT_OPCUA_POINT]: i18n.t("common.bulk_import_opcua_point"),
    [EVENT_TYPES.BULK_IMPORT_CAREL_POINT]: i18n.t("common.bulk_import_carel_point"),
    [EVENT_TYPES.BULK_IMPORT_STATE_VALUE]: i18n.t("common.bulk_import_state_values"),
    [EVENT_TYPES.BULK_IMPORT_SPACE]: i18n.t("common.bulk_import_space"),
    [EVENT_TYPES.BULK_IMPORT_POINT]: i18n.t("common.bulk_import_point"),
    [EVENT_TYPES.BULK_IMPORT_CIRCUIT_BREAKER]: i18n.t("common.bulk_import_circuit_breaker"),
    [EVENT_TYPES.BULK_IMPORT_CIRCUIT_BREAKER_RELATIONS]: i18n.t(
      "common.bulk_import_circuit_breaker_relations"
    )
  }
}

export const EVENT_STATE = {
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  FAILED: "FAILED"
}

export const getEventStateLabels = () => {
  /**
   * Kind of ugly, it's done because the direct import of a dict with i18n.t() will not working
   * The following code not working:
   * export const EVENT_TYPES_LABELS = {
   *  [EVENT_TYPES.BULK_IMPORT_NETWORK]: i18n.t("common.bulk_import_network"),
   *  ...
   * }
   * The i18n.t() is not working in the import statement (it return undefined for all keys)
   */
  return {
    [EVENT_STATE.IN_PROGRESS]: i18n.t("common.in_progress"),
    [EVENT_STATE.DONE]: i18n.t("common.done"),
    [EVENT_STATE.FAILED]: i18n.t("common.failed")
  }
}
