import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { NODE_TYPES } from "/utils/constants/node"
import { INT_MAX_VALUE } from "/utils/constants/input"

export const Interface = (networks, nodeType, networkType, loadingNetworks) => {
  const bacnetField = {
    mstp_address: {
      label: i18n.t("common.mstp_address"),
      field: InputText,
      dependsOn: "network",
      options: {
        required: true
      }
    }
  }

  const ipFields = {
    ip_address: {
      label: i18n.t("common.ip_address"),
      field: InputText,
      helpText: i18n.t("common.cidr_format", { format: "192.168.3.56" }),
      options: {
        required: true,
        pattern:
          "((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))"
      }
    },
    port: {
      label: i18n.t("common.port"),
      field: InputNumber,
      options: {
        required: true,
        showButtons: true,
        min: 0,
        max: INT_MAX_VALUE
      }
    }
  }

  const networkField = {
    network: {
      label: i18n.t("common.network"),
      field: Dropdown,
      options: {
        options: networks,
        required: true,
        optionValue: "id",
        optionLabel: "context_name",
        loading: loadingNetworks,
        placeholder: loadingNetworks
          ? i18n.t("common.loading")
          : i18n.t("common.select", {
              value: i18n.t("common.network").toLowerCase()
            })
      }
    }
  }

  if (nodeType === NODE_TYPES.BACNET && networkType === "serialnetwork") {
    return {
      ...networkField,
      ...bacnetField
    }
  }
  if (networkType === "ipnetwork") {
    return {
      ...networkField,
      ...ipFields
    }
  }
  return networkField
}
