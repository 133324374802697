import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Network = (spaces, loadingSpaces, currentNetwork = false) => {
  return {
    /*
     * List of fields for the model, like this
     */
    type: {
      label: i18n.t("common.type"),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { value: "ipnetwork", label: i18n.t("common.ip") },
          { value: "serialnetwork", label: i18n.t("common.serial") }
        ],
        disabled: currentNetwork
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    },
    space: {
      label: i18n.t("common.space"),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingSpaces,
        placeholder: loadingSpaces
          ? i18n.t("common.loading")
          : i18n.t("common.select", {
              value: i18n.t("common.space").toLowerCase()
            }),
        filter: true
      }
    },
    ip_address_range: {
      label: i18n.t("common.ip_range"),
      field: InputText,
      dependsOn: "type",
      dependsOnValue: "ipnetwork",
      helpText: i18n.t("common.cidr_format", { format: "192.168.3.56/22" }),
      options: {
        required: true,
        type: "text",
        pattern:
          "((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))/[0-9]+",
        placeholder: "0.0.0.0/0"
      }
    },
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      dependsOn: "type",
      dependsOnValue: "serialnetwork",
      options: {
        required: true,
        maxLength: 255
      }
    },
    is_bacnet: {
      label: i18n.t("common.bacnet_network"),
      field: InputSwitch,
      options: {}
    },
    bacnet_network_number: {
      label: i18n.t("common.bacnet_number"),
      field: InputNumber,
      dependsOn: "is_bacnet",
      dependsOnValue: true,
      options: { required: true, showButtons: true }
    }
  }
}

export const IPNetworkOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    type: { label: i18n.t("common.type") },
    description: { label: i18n.t("common.description") },
    space_name: { label: i18n.t("common.space") },
    ip_address_range: { label: i18n.t("common.ip_range") },
    is_bacnet: { label: i18n.t("common.bacnet_network") },
    bacnet_network_number: { label: i18n.t("common.bacnet_number") }
  }
}

export const SerialNetworkOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    type: { label: i18n.t("common.type") },
    description: { label: i18n.t("common.description") },
    space_name: { label: i18n.t("common.space") },
    name: { label: i18n.t("common.name") },
    is_bacnet: { label: i18n.t("common.bacnet_network") },
    bacnet_network_number: { label: i18n.t("common.bacnet_number") }
  }
}
