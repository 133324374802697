import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputTextarea } from "primereact/inputtextarea"
import { DateInput, Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"

/**
 * Fields names for the Flex contract model
 */
export const FLEX_CONTRACT_FIELDS = {
  NAME: "name",
  OPTIMISATION_GATEWAY_KEY: "optimization_gateways",
  ENERGY_DELIVERY_POINT_KEY: "energy_delivery_point",
  ENERGY_COMPANY_KEY: "energy_company",
  CONTRACT_TYPE_KEY: "contract_type",
  START_VALIDITY_KEY: "start_validity",
  END_VALIDITY_KEY: "end_validity",
  CONTROL_METHOD_KEY: "control_method",
  SCHEDULE_DURATION_KEY: "schedule_duration",
  MAX_SCHEDULES_PER_DAY_KEY: "max_schedules_per_day",
  NEUTRALIZATION_DURATION_KEY: "neutralization_duration",
  REBOUND_CORRECTION_KEY: "rebound_correction",
  ACTIVE_KEY: "active",
  COMMENT_KEY: "comment"
}

/**
 * Possible values for the control method field
 */
export const CURTAIL_CONTROL_METHODS = {
  RECTANGLE: "Rectangle"
}

/**
 * Possible values for the contract type field
 */
export const FLEX_CONTRACT_TYPES = {
  FLEXIBILITY: "Flexibility"
}

/**
 * Fields for the Flex contract model (only the contract part)
 * @param {Object} extraOptions : extra options for the fields
 * @returns {Object} : The fields for the contract
 */
export const FlexContract = extraOptions => {
  const getExtraOptions = key => {
    return extraOptions[key] ?? {}
  }

  return {
    [FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]: {
      label: i18n.t("common.optimization_gateway"),
      field: Dropdown,
      options: {
        options: [],
        optionValue: "id",
        optionLabel: "name",
        display: "chips",
        required: true,
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY)
      }
    },
    [FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY]: {
      label: i18n.t("common.energy_delivery_point"),
      field: Dropdown,
      options: {
        options: [],
        required: true,
        optionValue: "id",
        optionLabel: "meter_id",
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY)
      }
    },

    [FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY]: {
      label: i18n.t("common.energy_company"),
      field: Dropdown,
      options: {
        options: [],
        required: true,
        optionValue: "id",
        optionLabel: "name",
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY)
      }
    },

    // method of control computation for curtail parameters, (for now only Rectangle)
    // this shouldn't be here, it should be in the CurtailParameters
    // but we want it in this form for now
    [FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY]: {
      label: i18n.t("common.control_method"),
      field: Dropdown,
      options: {
        required: true,
        disabled: true,
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY)
      }
    },

    [FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY]: {
      label: i18n.t("common.start_validity"),
      field: DateInput,
      options: {
        required: true,
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY)
      }
    },

    [FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY]: {
      label: i18n.t("common.end_validity"),
      field: DateInput,
      options: {
        required: true,
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY)
      }
    }
  }
}

/**
 * Fields for the Flex contract model (only the curtailParameters part)
 * @param {*} extraOptions
 * @returns {Object} : The fields for the curtail parameters
 */
export const CurtailParameters = extraOptions => {
  const getExtraOptions = key => {
    return extraOptions[key] ? extraOptions[key] : {}
  }

  return {
    [FLEX_CONTRACT_FIELDS.SCHEDULE_DURATION_KEY]: {
      label: i18n.t("common.schedule_duration"),
      field: InputNumber,
      options: {
        min: 0,
        required: true,
        showButtons: true,
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.SCHEDULE_DURATION_KEY)
      }
    },
    [FLEX_CONTRACT_FIELDS.MAX_SCHEDULES_PER_DAY_KEY]: {
      label: i18n.t("common.max_schedules_per_day"),
      field: InputNumber,
      options: {
        min: 0,
        required: true,
        showButtons: true,
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.MAX_SCHEDULES_PER_DAY_KEY)
      }
    },
    [FLEX_CONTRACT_FIELDS.NEUTRALIZATION_DURATION_KEY]: {
      label: i18n.t("common.neutralization_duration"),
      field: InputNumber,
      options: {
        min: 0,
        required: true,
        showButtons: true,
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.NEUTRALIZATION_DURATION_KEY)
      }
    }
  }
}

/**
 * Fields for the Flex contract model (only the optimizationActivation part)
 * @param {*} extraOptions
 * @returns {Object} : The fields for the optimization activation
 */
export const OptimizationActivation = extraOptions => {
  const getExtraOptions = key => {
    return extraOptions[key] ? extraOptions[key] : {}
  }
  return {
    // active
    [FLEX_CONTRACT_FIELDS.ACTIVE_KEY]: {
      label: i18n.t("common.active"),
      field: InputSwitch,
      options: {
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.ACTIVE_KEY)
      }
    },
    [FLEX_CONTRACT_FIELDS.COMMENT_KEY]: {
      label: i18n.t("common.comment"),
      field: InputTextarea,
      options: {
        ...getExtraOptions(FLEX_CONTRACT_FIELDS.COMMENT_KEY)
      }
    }
  }
}

export const FullFlexContractForm = extraOptions => {
  return {
    ...FlexContract(extraOptions),
    ...CurtailParameters(extraOptions),
    ...OptimizationActivation(extraOptions)
  }
}

/**
 * This function create an object for the overview component from the flex contract details object (`flexContract`)
 *
 * Put directly the `organization_gateways_name` in the in `optimization_gateways` to display the name instead of the id.
 *
 * Put directly the `energy_delivery_point_name` in the in `energy_delivery_point` to display the name instead of the id.
 *
 * Format the date fields to display them in the correct format
 * @param {Object} flexContract - The flex contract details
 * @returns {Object} - The object for the overview component
 */
export const createOverviewInstance = flexContract => {
  return {
    [FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]: flexContract?.optimization_gateways_names,
    [FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY]: flexContract?.energy_delivery_point_meter_id,
    [FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY]: flexContract?.energy_company_name,
    [FLEX_CONTRACT_FIELDS.CONTRACT_TYPE_KEY]: flexContract?.contract_type,
    [FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY]:
      flexContract?.start_validity && new Date(flexContract.start_validity).toLocaleDateString(),
    [FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY]:
      flexContract?.end_validity && new Date(flexContract.end_validity).toLocaleDateString(),
    [FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY]: i18n.t(
      `common.aofd_nebef_${flexContract.control_method.toLowerCase()}`
    ),
    [FLEX_CONTRACT_FIELDS.SCHEDULE_DURATION_KEY]: `${flexContract?.schedule_duration ?? ""}`,
    [FLEX_CONTRACT_FIELDS.MAX_SCHEDULES_PER_DAY_KEY]: `${flexContract?.max_schedules_per_day ?? ""}`,
    [FLEX_CONTRACT_FIELDS.NEUTRALIZATION_DURATION_KEY]: `${flexContract?.neutralization_duration ?? ""}`,
    [FLEX_CONTRACT_FIELDS.REBOUND_CORRECTION_KEY]: `${flexContract?.rebound_correction ?? ""}`,
    [FLEX_CONTRACT_FIELDS.ACTIVE_KEY]: flexContract?.active,
    [FLEX_CONTRACT_FIELDS.COMMENT_KEY]: flexContract?.comment
  }
}
