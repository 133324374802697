import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import { STATE_TYPES } from "utils/constants/state"

export const StateForm = gateways => {
  return {
    name: {
      label: i18n.t("common.state_name"),
      field: InputText,
      options: { required: true }
    },
    comment: {
      label: i18n.t("common.comment"),
      field: InputTextarea,
      options: {}
    },
    type: {
      label: i18n.t("common.type"),
      field: Dropdown,
      options: {
        required: true,
        options: [
          {
            label: i18n.t("common.inhibit_state"),
            value: STATE_TYPES.INHIBIT
          },
          {
            label: i18n.t("common.safe_state"),
            value: STATE_TYPES.SAFESTATE
          },
          {
            label: i18n.t("common.constraint_state"),
            value: STATE_TYPES.CONSTRAINT
          },
          {
            label: i18n.t("common.curtail_state"),
            value: STATE_TYPES.CURTAIL
          },
          {
            label: i18n.t("common.precool_state"),
            value: STATE_TYPES.PRECOOL
          },
          {
            label: i18n.t("common.postcool_state"),
            value: STATE_TYPES.POSTCOOL
          }
        ],
        placeholder: i18n.t("common.not_selected_state")
      }
    },
    gateway: {
      label: i18n.t("common.gateway"),
      field: Dropdown,
      options: {
        required: true,
        options: gateways,
        optionLabel: "name",
        optionValue: "id",
        filter: true,
        placeholder: i18n.t("common.not_selected_gateway")
      }
    }
  }
}

export const StateOverview = () => {
  return {
    name: { label: i18n.t("common.state_name") },
    comment: { label: i18n.t("common.comment") },
    type: { label: i18n.t("common.type") },
    gateway_name: { label: i18n.t("common.gateway") }
  }
}
