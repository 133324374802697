export const MAPPING_CURRENCY_SYMBOL = {
  EUR: "€",
  SGD: "S$",
  MYR: "RM",
  THB: "THB"
}

export const MAPPING_CURRENCY = {
  FR: "EUR",
  SG: "SGD",
  MY: "MYR",
  TH: "THB"
}

export const DEFAULT_CURRENCY = "EUR"

export const BILL_PRICE_TYPES = {
  FIX: "FIX",
  SCHEDULE: "SCH"
}
