import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"

export const CircuitBreakerRelation = (equipments = []) => ({
  /*
   * List of fields for the model, like this
   */
  relationship_type: {
    label: i18n.t("common.relation_type"),
    field: Dropdown,
    options: {
      required: true,
      options: [
        { value: "IS_MEASURED_BY", label: i18n.t("common.is_measured_by") },
        { value: "POWERS", label: i18n.t("common.powers") }
      ]
    }
  },
  equipment_id: {
    label: i18n.t("common.equipment"),
    field: Dropdown,
    options: {
      disabled: equipments.length === 0,
      required: true,
      optionLabel: "name",
      optionValue: "id",
      options: equipments
    }
  }
})

export const CircuitBreakerRelationOverview = () => ({
  relationship_type: { label: i18n.t("common.relation_type") },
  equipment_name: { label: i18n.t("common.equipment") }
})
