import React, { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"

import { BaseLayout } from "@software-engineering/hivolution-frontend-utils"

import { errorNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { updateSideBar } from "utils/store/sidebar"
import { getNextDay, dateStr } from "utils/date"

import {
  getAllEnergyDeliveryPointsOfSite,
  getAllEnergyAggregatorsCompanies,
  getAllGatewayOfSite
} from "../api"
import { FlexContractRoutesContext } from "../context"

import { FLEX_CONTRACT_FIELDS, CURTAIL_CONTROL_METHODS, FLEX_CONTRACT_TYPES } from "../models"
import Form from "./Form"
export default function Create() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const { siteId, organizationId } = useParams()

  const { isProcessing, createFlexContract } = useContext(FlexContractRoutesContext)

  const [data, setData] = useState({
    [FLEX_CONTRACT_FIELDS.CONTRACT_TYPE_KEY]: FLEX_CONTRACT_TYPES.FLEXIBILITY,
    [FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY]: dateStr(new Date()),
    [FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY]: dateStr(getNextDay(new Date())),

    [FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY]: CURTAIL_CONTROL_METHODS.RECTANGLE,
    [FLEX_CONTRACT_FIELDS.SCHEDULE_DURATION_KEY]: 0,
    [FLEX_CONTRACT_FIELDS.MAX_SCHEDULES_PER_DAY_KEY]: 0,
    [FLEX_CONTRACT_FIELDS.NEUTRALIZATION_DURATION_KEY]: 0,
    [FLEX_CONTRACT_FIELDS.REBOUND_CORRECTION_KEY]: 1,

    [FLEX_CONTRACT_FIELDS.ACTIVE_KEY]: false,
    [FLEX_CONTRACT_FIELDS.COMMENT_KEY]: ""
  })

  /*
    Loading for the mandatory values in the form:
    Mandatory values are:
    - energy delivery point
    - gateway
    Without these values, the form cannot be filled properly
  */
  const [loadingMandatoryValues, setLoadingMandatoryValues] = useState(true)
  const [isThereGatewayOnThisSite, setIsThereGatewayOnThisSite] = useState(true)
  const [isThereADeliveryPointOnThisSite, setIsThereADeliveryPointOnThisSite] = useState(true)
  // submit button loading
  const [errors, setErrors] = useState({})
  const [fieldOptions, setFieldOptions] = useState({
    [FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY]: {
      min: dateStr(new Date())
    },
    [FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY]: {
      min: dateStr(getNextDay(new Date()))
    },

    [FLEX_CONTRACT_FIELDS.CONTROL_METHOD_KEY]: {
      options: [
        {
          label: t("common.aofd_nebef_rectangle"),
          value: CURTAIL_CONTROL_METHODS.RECTANGLE
        }
      ]
    }
  })

  /**
   * Update the options of a field (passed to extraOptions on the model)
   * @param {string} field - the field to update from the constants defined in the model
   * @param {object} options - the extra options to update
   */
  const updateFieldOptions = (field, options) => {
    setFieldOptions(currentFieldOption => {
      return {
        ...currentFieldOption,
        [field]: {
          ...currentFieldOption[field],
          ...options
        }
      }
    })
  }

  const setDataHook = newDatas => {
    setData(newDatas)
    // Error handling for the form:
    // check if the start validity date is greater than the end validity date (if both are set)
    if (
      newDatas[FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY] &&
      newDatas[FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY] &&
      newDatas[FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY] >=
        newDatas[FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY]
    ) {
      setErrors({
        [FLEX_CONTRACT_FIELDS.START_VALIDITY_KEY]: t("common.start_greater_than_end"),
        [FLEX_CONTRACT_FIELDS.END_VALIDITY_KEY]: t("common.end_smaller_than_start")
      })
    } else {
      setErrors({})
    }
  }

  const updateData = (key, value) => {
    setData(currentData => {
      return {
        ...currentData,
        [key]: value
      }
    })
  }

  const getEnergyDeliveryPoints = async () => {
    updateFieldOptions(FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY, {
      loading: true
    })
    // TODO: remove when the api will no longer paginate
    getAllEnergyDeliveryPointsOfSite(siteId, auth.userData.access_token)
      .then(results => {
        updateFieldOptions(FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY, {
          options: results,
          disabled: results.length === 1
        })
        if (results.length === 1) {
          updateData(FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY, results[0].id)
        }
        setIsThereADeliveryPointOnThisSite(results.length > 0)
      })
      .catch(error => {
        dispatch(
          addNotifications([errorNotification(t("common.energy_delivery_points"), error.message)])
        )
      })
      .finally(() => {
        updateFieldOptions(FLEX_CONTRACT_FIELDS.ENERGY_DELIVERY_POINT_KEY, {
          loading: false
        })
      })
  }

  const getEnergyCompanies = async () => {
    updateFieldOptions(FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY, {
      loading: true
    })
    return getAllEnergyAggregatorsCompanies(auth.userData.access_token)
      .then(results => {
        updateFieldOptions(FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY, {
          disabled: results.length === 1,
          options: results
        })
        if (results.length === 1) {
          updateData(FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY, results[0].id)
        }
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.energy_companies"), error.message)]))
      })
      .finally(() => {
        updateFieldOptions(FLEX_CONTRACT_FIELDS.ENERGY_COMPANY_KEY, {
          loading: false
        })
      })
  }

  const getGateways = () => {
    updateFieldOptions(FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY, {
      loading: true
    })
    return getAllGatewayOfSite(siteId, auth.userData.access_token)
      .then(results => {
        updateFieldOptions(FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY, {
          disabled: results.length === 1,
          options: results
        })
        if (results.length === 1) {
          updateData(FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY, results[0].id)
        }
        setIsThereGatewayOnThisSite(results.length > 0)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.gateways"), error.message)]))
      })
      .finally(() => {
        updateFieldOptions(FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY, {
          loading: false
        })
      })
  }

  // used on the component did mount
  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteId }))
    getEnergyDeliveryPoints()
    setLoadingMandatoryValues(true)
    const promises = [getEnergyCompanies(), getGateways()]
    Promise.all(promises).finally(() => {
      setLoadingMandatoryValues(false)
    })
  }, [])

  const create = async function (e) {
    e.preventDefault()
    const payload = {
      ...data
    }

    // @todo ugly
    if (payload[FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]) {
      // put the gateway in an array, the backend accept mutliple gateways for future use
      payload[FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY] = [
        data[FLEX_CONTRACT_FIELDS.OPTIMISATION_GATEWAY_KEY]
      ]
    }
    return createFlexContract(payload)
  }

  // if the mandatory values are not loaded yet, show a loading spinner
  if (loadingMandatoryValues) {
    return <BaseLayout loading />
  }

  // if there is no gateway or no delivery point, show a message
  if (!isThereGatewayOnThisSite || !isThereADeliveryPointOnThisSite) {
    return (
      <BaseLayout title={t("common.flex")}>
        <h3>{t("common.flex_contract")}</h3>
        {!isThereGatewayOnThisSite && (
          <div>
            <p className="flex-contract-mandatory-missing">{t("common.gateway_missing")}</p>
            <Button
              label={t("common.add_gateway")}
              icon="fa-solid fa-plus"
              rounded
              onClick={() =>
                navigate(`/organizations/${organizationId}/sites/${siteId}/assets/gateways/create/`)
              }
            />
          </div>
        )}
        {!isThereADeliveryPointOnThisSite && (
          <div>
            <p className="flex-contract-mandatory-missing">
              {t("common.energy_delivery_point_missing")}
            </p>
            <Button
              label={t("common.add_energy_delivery_point")}
              icon="fa-solid fa-plus"
              rounded
              onClick={() =>
                navigate(
                  `/organizations/${organizationId}/sites/${siteId}/energy/energy-delivery-points/`
                )
              }
            />
          </div>
        )}
      </BaseLayout>
    )
  }
  // Render the form (all mandatory values are loaded and at least one gateway and one delivery point are available)
  return (
    data && (
      <Form
        header={
          <div className="base-content-title mb-6 mt-6">
            <h2>{t("common.flex")}</h2>
          </div>
        }
        data={data}
        setData={setDataHook}
        errors={errors}
        fieldOptions={fieldOptions}
        onSubmit={create}
        processing={isProcessing}
      />
    )
  )
}
