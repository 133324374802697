import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"

export const ProtocolInstanceOverview = () => ({
  label: { label: i18n.t("common.type") },
  gateway_ip_interface_label: { label: i18n.t("common.gateway_interface") }
})

export const ProtocolInstance = (gatewayInterfaces, loadingGateway, editMode = false) => {
  return {
    type: {
      label: i18n.t("common.type"),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { value: "bacnetip", label: i18n.t("common.bacnet_ip") },
          { value: "modbusip", label: i18n.t("common.modbus_ip") },
          { value: "smxmlapi", label: i18n.t("common.sm_xml_api") },
          { value: "opcua", label: i18n.t("common.opscua") },
          { value: "carelxmlapi", label: i18n.t("common.carel") }
        ],
        placeholder: i18n.t("common.select", { value: i18n.t("common.type").toLowerCase() }),
        disabled: editMode
      }
    },
    gateway_ip_interface: {
      label: i18n.t("common.gateway_interface"),
      field: Dropdown,
      dependsOn: "type",
      options: {
        required: true,
        optionLabel: "label",
        optionValue: "id",
        options: gatewayInterfaces,
        loading: loadingGateway,
        placeholder: loadingGateway
          ? i18n.t("common.loading")
          : i18n.t("common.select", {
              value: i18n.t("common.gateway_interface").toLowerCase()
            })
      }
    },
    gateway_udp_port: {
      label: i18n.t("common.gateway_udp_port"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "bacnetip",
      options: {
        required: true
      }
    },
    batch_size: {
      label: i18n.t("common.batch_size"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    batch_size_write: {
      label: i18n.t("common.batch_size_writing"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    sleep_time: {
      label: i18n.t("common.sleep_time"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "smxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    },
    keepalive_timer: {
      label: i18n.t("common.keep_alive_timer"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    connect_timeout: {
      label: i18n.t("common.connect_timeout"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    read_timeout: {
      label: i18n.t("common.read_timeout"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    write_timeout: {
      label: i18n.t("common.write_timeout"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    backoff_max_wait: {
      label: i18n.t("common.backoff_max_wait"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "opcua",
      options: {
        required: true,
        showButtons: true
      }
    },
    set_wait_time: {
      label: i18n.t("common.set_wait_time"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "carelxmlapi",
      options: {
        required: true,
        showButtons: true
      }
    }
  }
}
