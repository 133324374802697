{
  "Aggregator": "Aggregator",
  "Aggregators": "Aggregators",
  "Back to edge block template list": "Back to edge block template list",
  "Boxes": "Boxes",
  "Cancel": "Cancel",
  "City": "City",
  "Country": "Country",
  "Country code": "Country code",
  "Create": "Create",
  "Create aggregator": "Create aggregator",
  "Create box": "Create box",
  "Create Distributor": "Create Distributor",
  "Create edge block template": "Create edge block template",
  "Create Retailer": "Create Retailer",
  "Create TSO": "Create TSO",
  "Delete gateway box link to a site is not allowed": "Delete gateway box link to a site is not allowed.",
  "Description": "Description",
  "Distributor": "Distributor",
  "Distributors": "Distributors",
  "Edge block template": "Edge block template",
  "Edge block templates": "Edge block templates",
  "Energy companies": "Energy companies",
  "Equipment type": "Equipment type",
  "Gateway box": "Gateway box",
  "Gateway boxes": "Gateway boxes",
  "Gateways": "Gateways",
  "Manufacturer": "Manufacturer",
  "Model": "Model",
  "Name": "Name",
  "OWM City ID": "OWM City ID",
  "Priority": "Priority",
  "Quantity": "Quantity",
  "Retailer": "Retailer",
  "Retailers": "Retailers",
  "Save": "Save",
  "Select Country": "Select Country",
  "Serial ID": "Serial ID",
  "The aggregator has been created": "The aggregator has been created.",
  "The aggregator has been removed": "The aggregator has been removed.",
  "The aggregator has been updated": "The aggregator has been updated.",
  "The distributor has been created": "The distributor has been created.",
  "The distributor has been removed": "The distributor has been removed.",
  "The distributor has been updated": "The distributor has been updated.",
  "The edge block template has been created": "The edge block template has been created.",
  "The edge block template has been removed": "The edge block template has been removed",
  "The edge block template has been updated": "The edge block template has been updated.",
  "The gateway box has been created": "The gateway box has been created.",
  "The gateway box has been removed": "The gateway box has been removed.",
  "The gateway box has been updated": "The gateway box has been updated.",
  "The retailer has been created": "The retailer has been created.",
  "The retailer has been removed": "The retailer has been removed.",
  "The retailer has been updated": "The retailer has been updated.",
  "The TSO has been created": "The TSO has been created.",
  "The TSO has been removed": "The TSO has been removed.",
  "The TSO has been updated": "The TSO has been updated.",
  "The weather zone has been activated": "The weather zone has been activated",
  "The weather zone has been desactivated": "The weather zone has been desactivated",
  "Timezone": "Timezone",
  "TSO": "TSO",
  "Unit": "Unit",
  "Units & quantities": "Units & quantities",
  "Updated by": "Updated by",
  "Weather zones": "Weather zones"
}
