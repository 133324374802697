import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { commonsFields, commonsOverview } from "./commons"

export const ModbusBit = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      address: {
        label: i18n.t("common.address"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: i18n.t("common.writable"),
        field: InputSwitch,
        options: {}
      },
      bit_type: {
        label: i18n.t("common.bit_type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "COIL", label: i18n.t("common.coil") },
            { value: "DISCRETE_INPUT", label: i18n.t("common.discrete_input") }
          ]
        }
      }
    }
  }
}

export const ModbusBitOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: i18n.t("common.address") },
      read_write: { label: i18n.t("common.read_write") },
      bit_type: { label: i18n.t("common.bit_type") }
    }
  }
}

export const ModbusRegister = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      address: {
        label: i18n.t("common.address"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_words: {
        label: i18n.t("common.words_number"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: i18n.t("common.writable"),
        field: InputSwitch,
        options: {}
      },
      register_type: {
        label: i18n.t("common.register_type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INPUT_REGISTER", label: i18n.t("common.input_register") },
            { value: "HOLDING_REGISTER", label: i18n.t("common.holding_register") }
          ]
        }
      },
      register_format: {
        label: i18n.t("common.format"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INT", label: i18n.t("common.int") },
            { value: "UINT", label: i18n.t("common.uint") },
            { value: "FLOAT", label: i18n.t("common.float") }
          ]
        }
      },
      swap_words: {
        label: i18n.t("common.swap_words"),
        field: InputSwitch,
        options: {}
      },
      swap_bytes: {
        label: i18n.t("common.swap_bytes"),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const ModbusRegisterOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: i18n.t("common.address") },
      number_of_words: { label: i18n.t("common.words_number") },
      read_write: { label: i18n.t("common.read_write") },
      register_type: { label: i18n.t("common.register_type") },
      register_format: { label: i18n.t("common.format") },
      swap_words: { label: i18n.t("common.swap_words") },
      swap_bytes: { label: i18n.t("common.swap_bytes") }
    }
  }
}

export const ModbusRegisterBitExtract = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      address: {
        label: i18n.t("common.address"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_words: {
        label: i18n.t("common.words_number"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      register_type: {
        label: i18n.t("common.register_type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INPUT_REGISTER", label: i18n.t("common.input_register") },
            { value: "HOLDING_REGISTER", label: i18n.t("common.holding_register") }
          ]
        }
      },
      register_format: {
        label: i18n.t("common.format"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "INT", label: i18n.t("common.int") },
            { value: "UINT", label: i18n.t("common.uint") },
            { value: "FLOAT", label: i18n.t("common.float") }
          ]
        }
      },
      swap_words: {
        label: i18n.t("common.swap_words"),
        field: InputSwitch,
        options: {}
      },
      swap_bytes: {
        label: i18n.t("common.swap_bytes"),
        field: InputSwitch,
        options: {}
      },
      start_bit: {
        label: i18n.t("common.bit_start"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      number_of_bits: {
        label: i18n.t("common.bits_number"),
        field: InputNumber,
        options: {
          required: true,
          min: 1,
          max: 999,
          showButtons: true
        }
      },
      writable: {
        label: i18n.t("common.writable"),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const ModbusRegisterBitExtractOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      address: { label: i18n.t("common.address") },
      number_of_words: { label: i18n.t("common.words_number") },
      register_type: { label: i18n.t("common.register_type") },
      register_format: { label: i18n.t("common.format") },
      swap_words: { label: i18n.t("common.swap_words") },
      swap_bytes: { label: i18n.t("common.swap_bytes") },
      start_bit: { label: i18n.t("common.bit_start") },
      number_of_bits: { label: i18n.t("common.bits_number") },
      read_write: { label: i18n.t("common.read_write") }
    }
  }
}
