import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import CodeMirror from "./components/CodeMirrorInput"
import { InputText } from "primereact/inputtext"

export const EdgeController = (gateways, edgeControllerTypes) => {
  return {
    /*
     * List of fields for the model, like this
     */

    gateway_id: {
      label: i18n.t("common.gateway"),
      field: Dropdown,
      options: {
        required: true,
        options: gateways,
        optionLabel: "name",
        optionValue: "id",
        filter: true,
        placeholder: i18n.t("common.not_selected_gateway")
      }
    },
    type: {
      label: i18n.t("common.type"),
      field: Dropdown,
      options: {
        required: true,
        options: edgeControllerTypes,
        placeholder: i18n.t("common.not_selected_type")
      }
    },
    comment: {
      label: i18n.t("common.comment"),
      field: InputText,
      options: {}
    },
    config: {
      label: i18n.t("common.configuration"),
      field: CodeMirror,
      options: {
        height: "400px",
        readOnly: false
      }
    }
  }
}

export const EdgeControllerOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */

    gateway_name: { label: i18n.t("common.gateway") },
    type: { label: i18n.t("common.type") },
    comment: { label: i18n.t("common.comment") }
  }
}
