{
  "Action": "Action",
  "Condition": "Condition",
  "Constraint states": "Constraint states",
  "Edge blocks": "Edge blocks",
  "Edge Controller": "Edge Controller",
  "Equipment": "Equipment",
  "Flex": "Flex",
  "Gateway": "Gateway",
  "Inhibit states": "Inhibit states",
  "Name": "Name",
  "Node": "Node",
  "Point": "Point",
  "Priority": "Priority",
  "Safe states": "Safe states",
  "State name": "State name",
  "States": "States",
  "Summary for": "Summary for"
}
