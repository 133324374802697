import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getCount, remove } from "../api"
import { UnavailabilityCategoryRouteContext } from "../routes"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { unavailabilityCategory } = useContext(UnavailabilityCategoryRouteContext)
  const [relatedObjects, setRelatedObjects] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const count = async () => {
    setLoadingRelatedObject(true)
    await getCount(siteStore.id, unavailabilityCategory.id, auth.userData.access_token)
      .then(response => {
        setRelatedObjects(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification(errorNotification(t("common.unavailability_category"), message))
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(siteStore.id, unavailabilityCategory.id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification(t("common.unavailability_category"), t("common.delete_success"))
        ])
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/`
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification(t("common.unavailability_category"), message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && unavailabilityCategory && unavailabilityCategory.id) {
      count()
    }
  }, [siteStore.id, unavailabilityCategory])

  return (
    unavailabilityCategory && (
      <DeleteConfirmation
        objectType={t("common.unavailability_category")}
        objectNames={[unavailabilityCategory.name]}
        remove={removeObject}
        relatedObjects={relatedObjects}
        loadingRelatedObject={loadingRelatedObject}
        goBack={() => navigate(-1)}
        processing={processing}
      />
    )
  )
}
