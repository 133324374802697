import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { Checkbox } from "primereact/checkbox"
import i18n from "i18next"

export const StateValue = () => ({
  /*
    Point corresponds to the name of the equipment followed by the name of the point name
    Ex: "equipment_name.point_name"
    The equipment and the point are related to the StateValue
  */
  point: {
    label: i18n.t("common.name"),
    field: InputText,
    options: {
      required: true
    }
  },
  /*
    Value of the StateValue, can be a number (float or int) or null
  */
  value: {
    label: i18n.t("common.value"),
    field: InputNumber,
    options: {
      required: false,
      showButtons: true,
      step: 0.001
    }
  },
  batch: {
    label: i18n.t("common.batch"),
    field: Checkbox
  }
})
