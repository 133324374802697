import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Store = (connectorInstances, loadingConnectorInstances) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    },
    pulling_interval: {
      label: i18n.t("common.pulling_interval"),
      field: InputNumber,
      options: { required: true, showButtons: true }
    },
    type: {
      label: i18n.t("common.type"),
      field: Dropdown,
      options: {
        required: true,
        options: [
          { label: i18n.t("common.status"), value: "status" },
          { label: i18n.t("common.gauge"), value: "gauge" }
        ],
        placeholder: i18n.t("common.select", { value: i18n.t("common.type").toLowerCase() })
      }
    },
    connector_instance: {
      label: i18n.t("common.connector_instance"),
      field: Dropdown,
      options: {
        required: true,
        options: connectorInstances,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingConnectorInstances,
        placeholder: loadingConnectorInstances
          ? i18n.t("common.loading")
          : i18n.t("common.select", {
              value: i18n.t("common.connector_instance").toLowerCase()
            })
      }
    },
    resolution_interval: {
      label: i18n.t("common.resolution_interval"),
      field: InputNumber,
      dependsOn: "type",
      dependsOnValue: "gauge",
      options: {
        required: true,
        showButtons: true
      }
    }
  }
}

export const StoreStatusOverview = () => {
  return {
    name: { label: i18n.t("common.name") },
    description: { label: i18n.t("common.description") },
    pulling_interval: { label: i18n.t("common.pulling_interval") },
    type: { label: i18n.t("common.type") },
    connector_instance_name: { label: i18n.t("common.connector_instance") }
  }
}

export const StoreGaugeOverview = () => {
  return {
    name: { label: i18n.t("common.name") },
    description: { label: i18n.t("common.description") },
    pulling_interval: { label: i18n.t("common.pulling_interval") },
    type: { label: i18n.t("common.type") },
    connector_instance_name: { label: i18n.t("common.connector_instance") },
    resolution_interval: { label: i18n.t("common.resolution_interval") }
  }
}
