import { successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import { GatewayBox } from "../models"
import { handleErrors } from "/utils/error.js"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await add(data, auth.userData.access_token)
      .then(res => {
        dispatch(
          addNotifications([
            successNotification(t("common.gateway_box"), t("common.creation_success"))
          ])
        )
        navigate(`/configuration/gateways/boxes/${res.data.id}`)
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.gateway_box"))
      })
      .finally(() => setProcessing(false))
  }

  return (
    data && (
      <>
        <div className="base-content-title">
          <h2>{t("common.create_asset", { asset: t("common.box").toLowerCase() })}</h2>
        </div>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={GatewayBox()}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              disabled={processing}
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("common.save")}
              type="submit"
              disabled={processing}
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
