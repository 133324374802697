import React, { useEffect, useState } from "react"
import {
  CurtailScheduleCalendar,
  ListLayout,
  ListActions,
  Loader
} from "@software-engineering/hivolution-frontend-utils"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import {
  retrieveListCurtailSchedule,
  retrieveListUnavailabilityPeriods,
  retrieveMarketScheduleGroup
} from "../api"
import { useAuth } from "oidc-react"
import { useDispatch } from "react-redux"
import { addNotifications } from "/utils/store/notification"
import { errorNotification, infoNotification } from "/utils/notification"
import { UNAVAILABILITY_SCOPES } from "/utils/constants/unavailability"
import { dateStr, getNextDay } from "/utils/date"

export default function View() {
  const { t } = useTranslation()
  const { siteId, flexId } = useParams()
  const auth = useAuth()
  const [loadingMarketScheduleGroup, setLoadingMarksetScheduleGroup] = useState(true)
  const [loadingCurtailSchedule, setLoadingCurtailSchedule] = useState(false)
  const [loadingUnavailabilityPeriods, setLoadingUnavailabilityPeriods] = useState(true)
  const dispatch = useDispatch()

  const [marksetScheduleGroupID, setMarketScheduleGroupID] = useState(null)
  const [schedules, setSchedules] = useState([])
  const [unavailabilityPeriods, setUnavailabilityPeriods] = useState([])
  const [currentDate, setCurrentDate] = useState(getNextDay(new Date()))

  const [calendarItems, setCalendarItems] = useState([])

  const [filters, setFilters] = useState([])

  const getMarketScheduleGroup = async () => {
    const queryParams = {
      schedule_date: dateStr(currentDate)
    }
    setLoadingMarksetScheduleGroup(true)
    await retrieveMarketScheduleGroup(flexId, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        if (data.count === 0) {
          setSchedules([])
          setMarketScheduleGroupID(null)
          dispatch(
            addNotifications([
              infoNotification(
                t("common.market_schedule_group"),
                t("flex.no_market_schedule_group")
              )
            ])
          )
        } else if (data.count > 1) {
          setSchedules([])
          setMarketScheduleGroupID(null)
          dispatch(
            addNotifications([
              errorNotification(
                t("common.market_schedule_group"),
                t("flex.more_than_one_market_schedule_group")
              )
            ])
          )
        } else {
          setMarketScheduleGroupID(data.results[0].id)
        }
      })
      .catch(error => {
        setMarketScheduleGroupID(null)
        setSchedules([])
        dispatch(
          addNotifications([errorNotification(t("common.market_schedule_group"), error.message)])
        )
      })
      .finally(() => {
        setLoadingMarksetScheduleGroup(false)
      })
  }

  const retrieveCurtailSchedules = async () => {
    setLoadingCurtailSchedule(true)
    await retrieveListCurtailSchedule(flexId, marksetScheduleGroupID, auth.userData.access_token)
      .then(({ data }) => {
        data.results &&
          setSchedules(
            data.results.map(schedule => {
              return {
                ...schedule,
                curtail_start_pretty: new Date(schedule.curtail_start).toLocaleString(),
                curtail_end_pretty: new Date(schedule.curtail_end).toLocaleString(),
                propose_time: new Date(schedule.create_time).toLocaleString(),
                confirmed_time: schedule.request_time
                  ? new Date(schedule.request_time).toLocaleString()
                  : ""
              }
            })
          )
      })
      .catch(error => {
        dispatch(
          addNotifications([errorNotification(t("common.curtail_schedules"), error.message)])
        )
      })
      .finally(() => {
        setLoadingCurtailSchedule(false)
      })
  }

  const retrieveUnavailabilityPeriods = async () => {
    const queryParams = {
      unavailable_on: dateStr(currentDate),
      category_scope: UNAVAILABILITY_SCOPES.FLEXIBILITY
    }
    setLoadingUnavailabilityPeriods(true)
    await retrieveListUnavailabilityPeriods(siteId, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.results && setUnavailabilityPeriods(data.results)
      })
      .catch(error => {
        dispatch(
          addNotifications([errorNotification(t("common.unavailability_periods"), error.message)])
        )
      })
      .finally(() => {
        setLoadingUnavailabilityPeriods(false)
      })
  }

  useEffect(() => {
    if (currentDate) {
      retrieveUnavailabilityPeriods()
      getMarketScheduleGroup()
    }
  }, [currentDate])

  useEffect(() => {
    marksetScheduleGroupID && retrieveCurtailSchedules()
  }, [marksetScheduleGroupID])

  useEffect(() => {
    const items = schedules.map(schedule => {
      const unavailableAtSchedule = unavailabilityPeriods.filter(period => {
        const start_unavailability = new Date(period.start)
        const end_unavailability = new Date(period.end)
        const start_schedule = new Date(schedule.curtail_start)
        const end_schedule = new Date(schedule.curtail_end)
        // Lot of condition to check if an unvailability_period is on a schedule
        return (
          (start_unavailability <= start_schedule && end_schedule <= end_unavailability) ||
          (start_unavailability >= start_schedule && end_schedule >= end_unavailability) ||
          (start_unavailability >= start_schedule && end_schedule > start_unavailability) ||
          (end_unavailability >= start_schedule && end_schedule > end_unavailability)
        )
      })

      return {
        start: schedule.curtail_start,
        end: schedule.curtail_end,
        proposed: unavailableAtSchedule.length === 0,
        confirmed: unavailableAtSchedule.length === 0 && schedule.execution_desired
      }
    })
    setCalendarItems(items)
  }, [schedules, unavailabilityPeriods])

  const columns = [
    {
      field: "curtail_start_pretty",
      header: t("common.curtail_start"),
      sortable: true
    },
    {
      field: "curtail_end_pretty",
      header: t("common.curtail_end"),
      sortable: true
    },
    { field: "curtail_capacity", header: t("common.curtail_capacity"), sortable: true },
    { field: "propose_time", header: t("common.propose_time"), sortable: true },
    { field: "confirmed_time", header: t("common.confirmed_time"), sortable: true },
    {
      field: "execution_desired",
      header: t("common.confirmed"),
      sortable: true,
      processValue: value => `${value ? t("common.yes") : t("common.no")}`
    }
  ]

  const menuFilters = { items: schedules, columns, filters, setFilters }

  return loadingMarketScheduleGroup || loadingUnavailabilityPeriods ? (
    <Loader />
  ) : (
    <>
      <div className="curtail-schedule-container">
        <h3>{t("common.overview")}</h3>
        <CurtailScheduleCalendar
          schedules={calendarItems}
          gap="half" // todo : switch to quarter when loadcurtailschedule will have period of 15mn
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
        />
      </div>
      <div className="curtail-schedule-container">
        <h3>{t("flex.list_curtail_schedule", { date: currentDate.toLocaleDateString() })}</h3>

        <ListActions filters={menuFilters} />
        <ListLayout
          columns={columns}
          value={schedules}
          filters={filters}
          loading={loadingCurtailSchedule}
          count={schedules.length}
        />
      </div>
    </>
  )
}
