import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const OPCUA = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      nodeid: {
        label: i18n.t("common.node_id"),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      access_level: {
        label: i18n.t("common.access_level"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "1", label: i18n.t("common.read") },
            { value: "2", label: i18n.t("common.write") },
            { value: "3", label: i18n.t("common.read_write") }
          ]
        }
      },
      data_type: {
        label: i18n.t("common.data_type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "Boolean", label: i18n.t("common.boolean") },
            { value: "Int16", label: i18n.t("common.int_value", { value: 16 }) },
            { value: "UInt16", label: i18n.t("common.uint_value", { value: 16 }) },
            { value: "Int32", label: i18n.t("common.int_value", { value: 32 }) },
            { value: "UInt32", label: i18n.t("common.uint_value", { value: 32 }) },
            { value: "Int64", label: i18n.t("common.int_value", { value: 64 }) },
            { value: "UInt64", label: i18n.t("common.uint_value", { value: 64 }) },
            { value: "Float", label: i18n.t("common.float") },
            { value: "Double", label: i18n.t("common.double") },
            { value: "String", label: i18n.t("common.string") }
          ]
        }
      }
    }
  }
}

export const OPCUAOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      nodeid: { label: i18n.t("common.node_id") },
      access_level: { label: i18n.t("common.access_level") },
      data_type: { label: i18n.t("common.data_type") }
    }
  }
}
