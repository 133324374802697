import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { ListLayout, ListActions } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { retrieveAllValues, retrieveList } from "../api"
import { organization } from "/utils/store/organization"
import { site } from "/utils/store/site"
import { CircuitBreakerRouteContext } from "../../circuit-breaker/routes"
import { updateSideBar } from "/utils/store/sidebar"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { Button } from "primereact/button"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const { circuitBreaker } = useContext(CircuitBreakerRouteContext)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)

  const columns = [
    { field: "equipment_name", header: t("common.equipment") },
    { field: "relationship_type", header: t("common.relation_type") }
  ]

  const columnsFilter = [
    { field: "equipment_name", header: t("common.equipment") },
    { field: "relationship_type", header: t("common.relation_type") }
  ]

  const getDetailUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreaker.id}/circuit-breaker-relations/${objectId}/`

  const getDeleteUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreaker.id}/circuit-breaker-relations/${objectId}/delete/`

  const onRowClick = objectId => navigate(getDetailUrl(objectId))

  const DEFAULT_FILTER = {
    circuit_breaker_id: [circuitBreaker.id]
  }

  const retrieve = async (queryParams = { ...DEFAULT_FILTER, limit, offset }) => {
    setLoading(true)

    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setItems(data.results)
        setCount(data.count)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(
          addNotifications([errorNotification(t("common.circuit_breaker_relation"), message)])
        )
      })
      .finally(() => setLoading(false))
  }

  const getAllValues = async () => {
    await retrieveAllValues(siteStore.id, auth.userData.access_token, DEFAULT_FILTER)
      .then(({ data }) => {
        setFilterValues(data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(
          addNotifications([errorNotification(t("common.circuit_breaker_relations"), message)])
        )
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    siteStore.id && getAllValues()
  }, [siteStore.id])

  const queryParams = () => {
    return {
      ...filters,
      ...DEFAULT_FILTER,
      limit,
      offset
    }
  }

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters])

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  return (
    <section className="list-section">
      <ListActions
        filterBackend={true}
        filters={menuFilters}
      >
        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreaker.id}/circuit-breaker-relations/create/`}
        >
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("common.create")}
          />
        </Link>
      </ListActions>
      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        count={count}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
      />
    </section>
  )
}
