import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const GatewayBox = () => {
  return {
    manufacturer: {
      label: i18n.t("common.manufacturer"),
      field: InputText,
      options: { maxLength: 255 }
    },
    model: {
      label: i18n.t("common.model"),
      field: InputText,
      options: { maxLength: 255 }
    },
    serial_id: {
      label: i18n.t("common.serial_id"),
      field: InputText,
      options: {
        required: true,
        keyfilter: /[\w\d_\-]/,
        pattern: "[^&é\"'(èçà)=^$*ù!:;,~#{[|]\\^@]}¤]*"
      }
    },
    description: { label: i18n.t("common.description"), field: InputTextarea }
  }
}
