{
  "Automation point type": "Type de point d'automatisation",
  "common": {
    "0_digital_input": "0 : entrée numérique",
    "1_digital_output": "1 : sortie numérique",
    "2_analog_input": "2 : entrée analogique",
    "3_analog_output": "3 : sortie analogique",
    "acceptable_values": "Valeurs acceptables",
    "access_level": "Niveau d'accès",
    "action": "common.action",
    "activated_success": "common.activated_success",
    "active": "Actif",
    "add": "common.add",
    "add_bill": "common.add_bill",
    "add_energy_delivery_point": "common.add_energy_delivery_point",
    "add_fix_price": "common.add_fix_price",
    "add_gateway": "common.add_gateway",
    "add_schedule_price": "common.add_schedule_price",
    "add_values": "Ajouter des valeurs",
    "address": "Adresse",
    "aggregator": "common.aggregator",
    "aggregator_response": "common.aggregator_response",
    "aggregators": "common.aggregators",
    "aksm": "AK-SM",
    "aksm_controller": "Contrôleur AK-SM",
    "aksm_controller_node": "Nœud de contrôleur AK-SM",
    "aksm_io": "Entrée/Sortie AK-SM",
    "aksm_io_node": "Nœud d'entrée/sortie AK-SM",
    "aksm_meter": "Compteur AK-SM",
    "aksm_meter_node": "Nœud de compteur AK-SM",
    "aksm_network_node": "Nœud de réseau AK-SM",
    "aksm_node": "Nœud AK-SM",
    "alarm_4": "Alarme (4)",
    "altitude": "Altitude",
    "analog_2": "Analogique (2)",
    "analog_input_0": "Entrée analogique (0)",
    "analog_output_1": "Sortie analogique (1)",
    "analog_value_2": "Valeur analogique (2)",
    "aofd_nebef_rectangle": "common.aofd_nebef_rectangle",
    "assets": "common.assets",
    "automation_node": "Nœud d'automatisation",
    "automation_nodes": "Nœuds d'automatisation",
    "automation_point": "Point d'automatisation",
    "automation_points": "Points d'automatisation",
    "back_edge_block_template_list": "common.back_edge_block_template_list",
    "backoff_max_wait": "common.backoff_max_wait",
    "bacnet": "Bacnet",
    "bacnet_ip": "common.bacnet_ip",
    "bacnet_network": "Réseau Bacnet",
    "bacnet_number": "Numéro Bacnet",
    "bacnet_priority": "Priorité Bacnet",
    "bacnet_priority_uninhibition": "Désinhibition de priorité Bacnet",
    "bacnet_property": "Propriété Bacnet",
    "base_informations": "Informations de base",
    "batch": "common.batch",
    "batch_size": "common.batch_size",
    "batch_size_read": "Taille de lot en lecture",
    "batch_size_write": "Taille de lot en écriture",
    "batch_size_writing": "common.batch_size_writing",
    "bb_share": "Pourcentage de partage économique pour BeeBryte",
    "beebrytebox": "common.beebrytebox",
    "bill": "common.bill",
    "bills": "common.bills",
    "binary_input_3": "Entrée binaire (3)",
    "binary_output_4": "Sortie binaire (4)",
    "binary_value_5": "Valeur binaire (5)",
    "bit_start": "Début du bit",
    "bit_type": "Type de bit",
    "bits_number": "Nombre de bits",
    "boolean": "Booléen",
    "boxes": "common.boxes",
    "building": "Bâtiment",
    "bulk_import_bacnet_node": "Importation en masse de nœud Bacnet",
    "bulk_import_bacnet_point": "Importation en masse de point Bacnet",
    "bulk_import_carel_node": "Importation en masse de nœud Carel",
    "bulk_import_carel_point": "Importation en masse de point Carel",
    "bulk_import_circuit_breaker": "Importation en masse de disjoncteur",
    "bulk_import_circuit_breaker_relations": "Importation en masse de relations de disjoncteur",
    "bulk_import_equipment": "Importation en masse d'équipement",
    "bulk_import_modbus_node": "Importation en masse de nœud Modbus",
    "bulk_import_modbus_point": "Importation en masse de point Modbus",
    "bulk_import_network": "Importation en masse de réseau",
    "bulk_import_opcua_point": "Importation en masse de point OPCUA",
    "bulk_import_point": "Importation en masse de point",
    "bulk_import_sm_node": "Importation en masse de nœud SM",
    "bulk_import_sm_point": "Importation en masse de point SM",
    "bulk_import_space": "Importation en masse d'espace",
    "bulk_import_state_values": "Importation en masse de valeurs d'état",
    "cabinet": "Armoire",
    "cabinet_name": "Nom de l'armoire",
    "cancel": "Annuler",
    "cards": "common.cards",
    "carel": "Carel",
    "carel_network_node": "Nœud de réseau Carel",
    "carel_node": "Nœud Carel",
    "changes": "Modifications",
    "choose_node_type": "Choisir un type de nœud",
    "choose_sub_type": "Choisir un sous-type",
    "choose_type": "Choisir un type",
    "cid": "Cid",
    "cidr_format": "Format CIDR : {{format}}",
    "circuit_breaker": "Disjoncteur",
    "circuit_breaker_relation": "Relation de disjoncteur",
    "circuit_breaker_relations": "Relations de disjoncteur",
    "circuit_breakers": "Disjoncteurs",
    "circuit_breakers_relations": "Relations de disjoncteurs",
    "city": "Ville",
    "coef": "common.coef",
    "coil": "Bobine",
    "comment": "Commentaire",
    "communication": "Communication",
    "condition": "common.condition",
    "configuration": "common.configuration",
    "confirm_delete": "Êtes-vous sûr de vouloir supprimer ce {{asset}} ?",
    "confirm_gateway_deployment": "Confirmer le déploiement de la passerelle",
    "confirm_gateway_deployment_history_delete": "Confirmer la suppression de l'historique de déploiement",
    "confirm_gateway_deployment_history_delete_message": "Vous êtes sur le point de supprimer un historique de déploiement. Cette action est irréversible, veuillez confirmer.",
    "confirm_gateway_deployment_message": "Vous êtes sur le point de déployer la boîte. Ajoutez des informations sur les modifications qui s'appliquent à ce déploiement.",
    "confirm_password": "Confirmer le mot de passe",
    "confirmation_software_update": "common.confirmation_software_update",
    "confirmed": "Confirmé",
    "confirmed_time": "Heure confirmée",
    "connect": "Connecter",
    "connect_a_network": "common.connect_a_network",
    "connect_timeout": "common.connect_timeout",
    "connectivity": "common.connectivity",
    "connectivity_view": "common.connectivity_view",
    "connector_instance": "common.connector_instance",
    "connector_instances": "Instances de connecteur",
    "constraint_state": "common.constraint_state",
    "constraint_states": "common.constraint_states",
    "contract": "Contrat",
    "control_method": "common.control_method",
    "cooled": "Refroidi",
    "country": "Pays",
    "country_code": "common.country_code",
    "create": "Créer",
    "create_asset": "Créer {{asset}}",
    "creation_success": "Créé avec succès",
    "curtail_capacity": "Capacité de réduction",
    "curtail_end": "Fin de réduction",
    "curtail_parameters": "common.curtail_parameters",
    "curtail_schedule": "common.curtail_schedule",
    "curtail_schedules": "common.curtail_schedules",
    "curtail_start": "Début de réduction",
    "curtail_state": "common.curtail_state",
    "data_step": "common.data_step",
    "data_type": "Type de données",
    "dates": "common.dates",
    "deactivated_success": "common.deactivated_success",
    "delete": "common.delete",
    "delete_error": "common.delete_error",
    "delete_gateway_box_linked_site": "common.delete_gateway_box_linked_site",
    "delete_success": "Supprimé avec succès",
    "delivery_points": "common.delivery_points",
    "deployment": "Déploiement",
    "description": "Description",
    "device_id": "ID de l'appareil",
    "device_instance": "Instance de l'appareil",
    "device_type": "Type d'appareil",
    "digital_1": "Numérique (1)",
    "disable_storage": "Désactiver le stockage",
    "discrete_input": "Entrée discrète",
    "distributor": "common.distributor",
    "distributor_company": "common.distributor_company",
    "distributors": "common.distributors",
    "done": "Terminé",
    "double": "Double",
    "edge_block_template": "common.edge_block_template",
    "edge_block_templates": "common.edge_block_templates",
    "edge_blocks": "common.edge_blocks",
    "edge_controller": "common.edge_controller",
    "edge_controller_types": "common.edge_controller_types",
    "edge_controllers": "common.edge_controllers",
    "electrical": "Électrique",
    "electricity": "common.electricity",
    "end": "End",
    "end_date": "Date de fin",
    "end_smaller_than_start": "common.end_smaller_than_start",
    "end_time": "Heure de fin",
    "end_validity": "Fin de validité",
    "energy": "common.energy",
    "energy_companies": "common.energy_companies",
    "energy_company": "common.energy_company",
    "energy_delivery_point": "common.energy_delivery_point",
    "energy_delivery_point_missing": "common.energy_delivery_point_missing",
    "energy_delivery_points": "common.energy_delivery_points",
    "energy_type": "common.energy_type",
    "equipment": "Équipement",
    "equipment_sub_type": "Sous-type d'équipement",
    "equipment_sub_types": "Sous-types d'équipement",
    "equipment_type": "Type d'équipement",
    "equipment_types": "Types d'équipement",
    "equipments": "Équipements",
    "equipments_count": "Nombre d'équipements",
    "equipments_powered": "Équipements alimentés",
    "equivalent_metering": "common.equivalent_metering",
    "equivalent_metering_equation": "common.equivalent_metering_equation",
    "equivalent_metering_intercept": "common.equivalent_metering_intercept",
    "error": "Erreur",
    "error_config": "common.error_config",
    "error_fetching_data": "common.error_fetching_data",
    "event": "Événement",
    "events": "Événements",
    "export": "Exporter",
    "export_button": "common.export_button",
    "export_success": "common.export_success",
    "export_with_batch": "common.export_with_batch",
    "export_without_batch": "common.export_without_batch",
    "external_api": "API externe",
    "external_api_node": "Nœud API externe",
    "external_id": "ID externe",
    "external_point_type": "Type de point externe",
    "failed": "Échoué",
    "false": "common.false",
    "filter_values": "Filtrer les valeurs",
    "fix_price_name": "common.fix_price_name",
    "fix_price_value": "common.fix_price_value",
    "fix_prices": "common.fix_prices",
    "flex": "common.flex",
    "flex_contract": "common.flex_contract",
    "flex_opti": "common.flex_opti",
    "flexibility": "common.flexibility",
    "float": "Flottant",
    "forced_stop_hours": "Heures d'arrêt forcé",
    "forced_stop_reset": "Réinitialisation de l'arrêt forcé",
    "format": "Format",
    "gas": "common.gas",
    "gateway": "Passerelle",
    "gateway_box": "common.gateway_box",
    "gateway_boxes": "common.gateway_boxes",
    "gateway_configuration": "Configuration de la passerelle",
    "gateway_configuration_deploy_success": "La configuration a été déployée avec succès",
    "gateway_interface": "common.gateway_interface",
    "gateway_interfaces": "Interfaces de la passerelle",
    "gateway_missing": "common.gateway_missing",
    "gateway_point": "common.gateway_point",
    "gateway_udp_port": "common.gateway_udp_port",
    "gateways": "Passerelles",
    "gauge": "Jauge",
    "generate": "Générer",
    "generated_at": "Généré à",
    "generated_by": "Généré par",
    "grdf": "GRDF",
    "hardware": "Matériel",
    "heated": "Chauffé",
    "height": "Hauteur (m)",
    "history": "common.history",
    "hiveoptimal_contract": "common.hiveoptimal_contract",
    "holding_register": "Registre de maintien",
    "http": "HTTP",
    "https": "HTTPS",
    "id_var": "ID Var",
    "import": "Importer",
    "import_button": "common.import_button",
    "import_error": "Une erreur s'est produite lors de l'importation, voir plus de détails dans le fichier de rapport d'erreur.",
    "import_success": "common.import_success",
    "import_with_batch": "common.import_with_batch",
    "import_without_batch": "common.import_without_batch",
    "in_progress": "En cours",
    "incoherent_dates": "common.incoherent_dates",
    "infos": "Infos",
    "inhibit_state": "common.inhibit_state",
    "inhibit_states": "common.inhibit_states",
    "input_register": "Registre d'entrée",
    "int": "Int",
    "int_value": "Int{{value}}",
    "integer_3": "Entier (3)",
    "interface": "Interface",
    "interfaces": "Interfaces",
    "io_node_type": "Type de nœud d'entrée/sortie",
    "ip": "IP",
    "ip_address": "Adresse IP",
    "ip_address_range": "Plage d'adresses IP",
    "ip_addresses": "Adresses IP",
    "ip_range": "Plage IP",
    "is_logic": "Est logique",
    "is_measured_by": "Est mesuré par",
    "keep_alive_timer": "common.keep_alive_timer",
    "koovea": "Koovea",
    "latitude": "Latitude",
    "line_id": "ID de ligne",
    "linear_constant": "Constante linéaire",
    "linear_multiplier": "Multiplicateur linéaire",
    "linked_equipments": "Équipements liés",
    "list": "Liste",
    "load_curtailment_for": "common.load_curtailment_for",
    "loading": "Chargement...",
    "logo_svg": "common.logo_svg",
    "longitude": "Longitude",
    "low_cipher": "Chiffrement faible",
    "manage_modbus_response_timeouts": "common.manage_modbus_response_timeouts",
    "manage_timeout": "common.manage_timeout",
    "manufacturer": "Fabricant",
    "map": "Carte",
    "market_player_interaction": "common.market_player_interaction",
    "market_schedule_group": "Groupe de calendrier de marché",
    "market_schedule_group_proposal_history": "common.market_schedule_group_proposal_history",
    "max_schedules_per_day": "common.max_schedules_per_day",
    "measured_by": "Mesuré par",
    "meter_id": "ID du compteur",
    "mobilealerts": "MobileAlerts",
    "mod": "Mod",
    "modbus": "Modbus",
    "modbus_bit": "Bit Modbus",
    "modbus_ip": "common.modbus_ip",
    "modbus_register": "Registre Modbus",
    "modbus_register_bit_extract": "Extraction de bit de registre Modbus",
    "mode": "Mode",
    "model": "Modèle",
    "modified_at": "common.modified_at",
    "modified_by": "common.modified_by",
    "mstp_address": "Adresse MSTP",
    "multi_case_name": "Nom de cas multiple",
    "multi_state_input_13": "Entrée multi-état (13)",
    "multi_state_input_14": "Entrée multi-état (14)",
    "mysirius": "MySirius",
    "name": "Nom",
    "name_schedule_prices": "common.name_schedule_prices",
    "namespace_index": "Index de l'espace de noms",
    "network": "Réseau",
    "network_interface": "common.network_interface",
    "network_interfaces": "Interfaces réseau",
    "network_ip_range": "common.network_ip_range",
    "networks": "Réseaux",
    "neutralization_duration": "common.neutralization_duration",
    "no": "Non",
    "no_bill": "common.no_bill",
    "no_fix_prices": "common.no_fix_prices",
    "no_manufacturer": "common.no_manufacturer",
    "no_organization_found": "common.no_organization_found",
    "no_requested_update": "common.no_requested_update",
    "no_schedule_prices": "common.no_schedule_prices",
    "no_weather_zone_found": "Aucune zone météorologique trouvée",
    "node": "Nœud",
    "node_address": "Adresse du nœud",
    "node_id": "ID du nœud",
    "node_interfaces": "Interfaces du nœud",
    "nominal_equipments_powered": "Équipements nominaux alimentés",
    "nominal_intensity": "Intensité nominale",
    "none": "Aucun",
    "not_selected_gateway": "common.not_selected_gateway",
    "not_selected_state": "common.not_selected_state",
    "not_selected_type": "common.not_selected_type",
    "num_suction": "Num aspiration",
    "number_of_sites": "common.number_of_sites",
    "object_instance": "Instance d'objet",
    "object_name": "Nom de l'objet",
    "object_type": "Type d'objet",
    "offline": "common.offline",
    "online": "common.online",
    "opcua": "OPCUA",
    "opcua_node": "Nœud OPCUA",
    "open": "Ouvrir",
    "operations": "common.operations",
    "opscua": "common.opscua",
    "optimization": "common.optimization",
    "optimization_gateway": "common.optimization_gateway",
    "optimized": "Optimisé",
    "organization": "Organisation",
    "organization_delete_not_allowed_site_linked": "common.organization_delete_not_allowed_site_linked",
    "organizations": "Organisations",
    "overview": "Aperçu",
    "owm_city_id": "common.owm_city_id",
    "parameter": "common.parameter",
    "parameters": "Paramètres",
    "parent_circuit_breaker": "Disjoncteur parent",
    "parent_space": "Espace parent",
    "password": "Mot de passe",
    "path": "Chemin",
    "permeable": "Perméable",
    "point": "Point",
    "point_values": "common.point_values",
    "points": "Points",
    "points_count": "Nombre de points",
    "points_number": "common.points_number",
    "port": "Port",
    "postcool_state": "common.postcool_state",
    "powers": "Puissances",
    "precool_state": "common.precool_state",
    "present_value": "Valeur actuelle",
    "pretty_name": "Nom joli",
    "price_zone": "Zone de prix",
    "price_zones": "Zones de prix",
    "priority": "Priorité {{priority}}",
    "priority_array": "Tableau de priorités",
    "proposal_for_aggregator": "common.proposal_for_aggregator",
    "proposal_sent": "common.proposal_sent",
    "propose_time": "Heure de proposition",
    "proposed_by": "common.proposed_by",
    "proposed_time": "common.proposed_time",
    "protocol": "Protocole",
    "protocol_instance": "common.protocol_instance",
    "protocol_instances": "common.protocol_instances",
    "protocol_type": "Type de protocole",
    "provide_date_site_timezone": "common.provide_date_site_timezone",
    "pulling_interval": "common.pulling_interval",
    "quantity": "common.quantity",
    "rack_id": "ID de rack",
    "read": "Lire",
    "read_timeout": "common.read_timeout",
    "read_write": "Lire / Écrire",
    "register_type": "Type de registre",
    "relation_between": "Relation entre",
    "relation_type": "Type de relation",
    "relinquish_default": "Valeur par défaut de renonciation",
    "remove": "common.remove",
    "request_time": "common.request_time",
    "request_type": "Type de demande",
    "requested": "common.requested",
    "requested_by": "common.requested_by",
    "resolution_interval": "common.resolution_interval",
    "response_timeout": "common.response_timeout",
    "response_timeout_seconds": "Délai d'attente de réponse (secondes)",
    "retailer": "common.retailer",
    "retailers": "common.retailers",
    "router": "Routeur",
    "running": "common.running",
    "safe_state": "common.safe_state",
    "safe_states": "common.safe_states",
    "same_passwords": "Les mots de passe doivent être identiques",
    "save": "Enregistrer",
    "saving_share": "Partage d'économies",
    "schedule_date": "common.schedule_date",
    "schedule_duration": "common.schedule_duration",
    "schedule_prices": "common.schedule_prices",
    "schedule_prices_currency": "common.schedule_prices_currency",
    "scheme": "Schéma",
    "scope": "common.scope",
    "section": "common.section",
    "security_mode": "Mode de sécurité",
    "security_policy": "Politique de sécurité",
    "select": "Sélectionner {{value}}",
    "select_a": "Sélectionner un {{value}}",
    "select_an": "Sélectionner une {{value}}",
    "send_success": "common.send_success",
    "send_to_aggregator": "common.send_to_aggregator",
    "sensinov": "Sensinov",
    "sent_by": "common.sent_by",
    "serial": "Série",
    "serial_id": "ID de série",
    "serial_number": "common.serial_number",
    "server_certificate_path": "Chemin du certificat du serveur",
    "set_filter": "common.set_filter",
    "set_wait_time": "common.set_wait_time",
    "sigfox": "Sigfox",
    "sign": "Signer",
    "sign_and_encrypt": "Signer et chiffrer",
    "site": "Site",
    "sites": "Sites",
    "sites_count_one": "common.sites_count",
    "sites_count_many": "common.sites_count",
    "sites_count_other": "common.sites_count",
    "slave_id": "ID esclave",
    "sleep_time": "common.sleep_time",
    "sm": "SM",
    "sm_nodes": "Nœuds SM",
    "sm_type": "Type SM",
    "sm_xml_api": "common.sm_xml_api",
    "software": "Logiciel",
    "space": "Espace",
    "space_constraints": "common.space_constraints",
    "spaces": "Espaces",
    "start": "Début",
    "start_date": "Date de début",
    "start_greater_than_end": "common.start_greater_than_end",
    "start_time": "Heure de début",
    "start_validity": "Début de validité",
    "state": "État",
    "state_name": "common.state_name",
    "state_value": "common.state_value",
    "states": "common.states",
    "status": "Statut",
    "status_flags": "Drapeaux de statut",
    "store": "Magasin",
    "store_name": "Nom du magasin",
    "store_type": "Type de magasin",
    "stores": "Magasins",
    "stores_number": "common.stores_number",
    "string": "Chaîne",
    "sub_type": "Sous-type",
    "suction_id": "ID d'aspiration",
    "summary_for": "common.summary_for",
    "surface_area": "Surface (m²)",
    "swap_bytes": "Échanger des octets",
    "swap_words": "Échanger des mots",
    "synchronize_legacy": "Synchroniser l'héritage",
    "system_manager": "Gestionnaire de système",
    "tag": "Étiquette",
    "target": "Cible",
    "template": "Modèle",
    "timeouts": "common.timeouts",
    "timezone": "Fuseau horaire",
    "tls_version": "Version TLS",
    "tls_version_1": "Version TLS 1",
    "tls_version_1_1": "Version TLS 1.1",
    "tls_version_1_2": "Version TLS 1.2",
    "transmission": "common.transmission",
    "transmission_company": "common.transmission_company",
    "transport_type": "Type de transport",
    "true": "common.true",
    "tso": "common.tso",
    "type": "Type",
    "uint": "UInt",
    "uint_value": "Valeur UInt{{value}}",
    "unavailability_categories": "common.unavailability_categories",
    "unavailability_category": "Catégorie d'indisponibilité",
    "unavailability_period": "Période d'indisponibilité",
    "unavailability_periods": "Périodes d'indisponibilité",
    "uninhibit_priority": "Priorité de désinhibition",
    "unit": "Unité",
    "units_and_quantities": "common.units_and_quantities",
    "unspecified_loads": "Charges non spécifiées",
    "up_to_date": "common.up_to_date",
    "update": "common.update",
    "update_last_bill": "common.update_last_bill",
    "update_response_timeouts": "common.update_response_timeouts",
    "update_software": "common.update_software",
    "update_status": "common.update_status",
    "update_success": "Mis à jour avec succès",
    "updated_by": "Modifié par",
    "updated_time": "common.updated_time",
    "updated_version": "common.updated_version",
    "user": "Utilisateur",
    "value": "common.value",
    "version": "common.version",
    "version_name": "common.version_name",
    "vid": "Vid",
    "warning_update": "common.warning_update",
    "weather_zone": "Zone météorologique",
    "weather_zones": "Zones météorologiques",
    "words_number": "Nombre de mots",
    "writable": "Modifiable",
    "write": "Écrire",
    "write_timeout": "common.write_timeout",
    "yes": "Oui",
    "zip_code": "Code postal",
    "zone_name": "Nom de la zone"
  },
  "flex": {
    "list_curtail_schedule": "Créneaux de réduction proposés et confirmés pour le {{date}}",
    "more_than_one_market_schedule_group": "flex.more_than_one_market_schedule_group",
    "no_market_schedule_group": "flex.no_market_schedule_group"
  },
  "Height (m)": "Hauteur (m)",
  "point": {
    "gateway_text": "Texte du point de la passerelle",
    "gateway_value": "Valeur du point de la passerelle",
    "original_value": "Valeur originale du point"
  },
  "Protocol instances": "Protocol instances",
  "Sign out": "Se déconnecter",
  "Surface area (m²)": "Surface area (m²)"
}
