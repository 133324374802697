import { DateInput, Dropdown, TimeInput } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputTextarea } from "primereact/inputtextarea"

export const UnavailabilityPeriod = (
  unavailabilityCategories,
  loadingUnavailabilityCategories,
  startDate,
  endDate,
  startTime
) => ({
  /*
   * List of fields for the model, like this
   */
  unavailability_category_id: {
    label: i18n.t("common.unavailability_category"),
    field: Dropdown,
    options: {
      required: true,
      options: unavailabilityCategories,
      optionLabel: "name",
      optionValue: "id",
      loading: loadingUnavailabilityCategories,
      placeholder: loadingUnavailabilityCategories
        ? i18n.t("common.loading")
        : i18n.t("common.select_an", {
            value: i18n.t("common.unavailability_category").toLowerCase()
          })
    }
  },
  comment: {
    label: i18n.t("common.comment"),
    field: InputTextarea,
    options: { required: true }
  },
  start_date: {
    introText: i18n.t("common.provide_date_site_timezone"),
    label: i18n.t("common.start_date"),
    field: DateInput,
    options: { required: true }
  },
  start_time: {
    label: i18n.t("common.start_time"),
    field: TimeInput,
    options: { required: true }
  },
  end_date: {
    label: i18n.t("common.end_date"),
    field: DateInput,
    min: startDate,
    options: {
      min: startDate
    }
  },
  end_time: {
    label: i18n.t("common.end_time"),
    field: TimeInput,
    options: {
      min: startDate === endDate ? startTime : undefined
    }
  }
})

export const UnavailabilityPeriodOverview = () => {
  return {
    category_name: { label: i18n.t("common.unavailability_category") },
    comment: { label: i18n.t("common.comment") },
    start_date_pretty: { label: i18n.t("common.start_date") },
    start_time: { label: i18n.t("common.start_time") },
    end_date_pretty: { label: i18n.t("common.end_date") },
    end_time: { label: i18n.t("common.end_time") }
  }
}
