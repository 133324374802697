import { SITE_FEATURES } from "/utils/constants/features"
import {
  CountryDropdown,
  DateInput,
  Dropdown
} from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"

const SiteBaseForm = (priceZones, organizations, loadingPriceZone, loadingOrganizations) => ({
  name: {
    label: i18n.t("common.name"),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  pretty_name: {
    label: i18n.t("common.pretty_name"),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  organization_id: {
    label: i18n.t("common.organization"),
    field: Dropdown,
    options: {
      required: true,
      options: organizations,
      filter: true,
      optionLabel: "name",
      optionValue: "id",
      emptyMessage: i18n.t("common.no_organization_found"),
      loading: loadingOrganizations,
      placeholder: loadingOrganizations
        ? i18n.t("common.loading")
        : i18n.t("common.select_an", {
            value: i18n.t("common.organizations").toLowerCase()
          })
    }
  },
  price_zone_id: {
    label: i18n.t("common.price_zone"),
    field: Dropdown,
    options: {
      required: true,
      options: priceZones,
      filter: true,
      optionLabel: "name",
      optionValue: "id",
      loading: loadingPriceZone,
      placeholder: loadingPriceZone
        ? i18n.t("common.loading")
        : i18n.t("common.select_a", {
            value: i18n.t("common.price_zone").toLowerCase()
          })
    }
  }
})

const SiteAddressForm = (weatherZones, loadingWeatherZone) => ({
  address: {
    label: i18n.t("common.address"),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  zip_code: {
    label: i18n.t("common.zip_code"),
    field: InputText,
    options: {
      required: true,
      keyfilter: "pint",
      maxLength: 255
    }
  },
  city: {
    label: i18n.t("common.city"),
    field: InputText,
    options: {
      required: true,
      maxLength: 255
    }
  },
  country: {
    label: i18n.t("common.country"),
    field: CountryDropdown,
    options: {
      required: true,
      filter: true
    }
  },
  latitude: {
    label: i18n.t("common.latitude"),
    field: InputNumber,
    options: {
      required: true,
      min: -90,
      max: 90,
      step: 0.0001,
      maxFractionDigits: 4
    }
  },
  longitude: {
    label: i18n.t("common.longitude"),
    field: InputNumber,
    options: {
      required: true,
      min: -180,
      max: 180,
      step: 0.0001,
      maxFractionDigits: 4
    }
  },
  altitude: {
    label: i18n.t("common.altitude"),
    field: InputNumber,
    options: {
      step: 1,
      maxFractionDigits: 4
    }
  },
  weather_zone_id: {
    label: i18n.t("common.weather_zone"),
    field: Dropdown,
    options: {
      required: true,
      options: weatherZones,
      optionLabel: "city_name",
      optionValue: "id",
      filter: true,
      loading: loadingWeatherZone,
      emptyMessage: i18n.t("common.no_weather_zone_found"),
      placeholder: loadingWeatherZone
        ? i18n.t("common.loading")
        : i18n.t("common.select_a", {
            value: i18n.t("common.weather_zone").toLowerCase()
          })
    }
  },
  weather_zone_timezone: {
    label: i18n.t("common.timezone"),
    field: InputText,
    options: { disabled: true }
  }
})

const SiteStateForm = () => {
  let model = {
    active: {
      label: i18n.t("common.active"),
      field: InputSwitch
    },
    hiveoptimal_contract: {
      label: i18n.t("common.hiveoptimal_contract"),
      field: InputSwitch
    }
  }

  if (SITE_FEATURES.SYNCHRONIZE_LEGACY) {
    model = {
      synchronise_legacy: {
        label: i18n.t("common.synchronize_legacy"),
        field: InputSwitch
      },
      ...model
    }
  }

  return model
}

const SiteOverviewForm = (priceZones, organizations, loadingPriceZone, loadingOrganizations) => ({
  ...SiteBaseForm(priceZones, organizations, loadingPriceZone, loadingOrganizations),
  ...SiteStateForm()
})

const SiteForm = (
  priceZones,
  weatherZones,
  organizations,
  loadingPriceZone,
  loadingWeatherZone,
  loadingOrganizations
) => ({
  ...SiteBaseForm(priceZones, organizations, loadingPriceZone, loadingOrganizations),
  ...SiteAddressForm(weatherZones, loadingWeatherZone),
  ...SiteStateForm()
})

const SiteContractForm = (
  edit = false,
  hiveoptimalContract = true,
  setStartDate,
  startDate,
  setEndDate,
  endDate
) => {
  const form = {}

  if (edit) {
    form.hiveoptimal_contract = {
      label: i18n.t("common.hiveoptimal_contract"),
      field: InputSwitch
    }
  }

  if (hiveoptimalContract) {
    form.name = {
      label: i18n.t("common.name"),
      field: InputText,
      options: {
        required: true,
        maxLength: 255,
        keyfilter: /[\w\d-.]/
      }
    }

    form.forced_stop_hours = {
      label: i18n.t("common.forced_stop_hours"),
      field: InputNumber,
      options: {
        required: true,
        showButtons: true,
        min: 1
      }
    }

    form.forced_stop_reset = {
      label: i18n.t("common.forced_stop_reset"),
      field: DateInput,
      options: {
        required: true
      }
    }

    form.bb_share = {
      label: i18n.t("common.bb_share"),
      field: InputNumber,
      options: {
        required: true,
        showButtons: true,
        min: 1,
        max: 100
      }
    }

    form.start_validity = {
      label: i18n.t("common.start_validity"),
      field: DateInput,
      options: {
        required: true,
        onChange: e => setStartDate(e.value),
        max: endDate
      }
    }

    form.end_validity = {
      label: i18n.t("common.end_validity"),
      field: DateInput,
      options: {
        required: true,
        onChange: e => setEndDate(e.value),
        min: startDate
      }
    }
  }

  return form
}

const SiteOverview = () => ({
  pretty_name: { label: i18n.t("common.pretty_name") },
  name: { label: i18n.t("common.name") },
  price_zone_name: { label: i18n.t("common.price_zone") }, // @todo: replace by price zone name
  synchronise_legacy: { label: i18n.t("common.synchronize_legacy") },
  active: { label: i18n.t("common.active") }
})

const SiteAddress = () => ({
  address: { label: i18n.t("common.address") },
  zip_code: { label: i18n.t("common.zip_code") },
  city: { label: i18n.t("common.city") },
  country: { label: i18n.t("common.country") },
  latitude: { label: i18n.t("common.latitude") },
  longitude: { label: i18n.t("common.longitude") },
  altitude: { label: i18n.t("common.altitude") },
  weather_zone_name: { label: i18n.t("common.weather_zone") },
  weather_zone_timezone: { label: i18n.t("common.timezone") }
})

const SiteContract = hiveoptimalContract => {
  const contract = {
    hiveoptimal_contract: { label: i18n.t("common.hiveoptimal_contract") }
  }

  if (hiveoptimalContract) {
    contract.name = { label: i18n.t("common.name") }
    contract.forced_stop_hours = { label: i18n.t("common.forced_stop_hours") }
    contract.forced_stop_reset = { label: i18n.t("common.forced_stop_reset") }
    contract.bb_share = {
      label: i18n.t("common.bb_share")
    }
    contract.start_validity = { label: i18n.t("common.start_validity") }
    contract.end_validity = { label: i18n.t("common.end_validity") }
  }

  return contract
}

export {
  SiteForm,
  SiteAddressForm,
  SiteOverviewForm,
  SiteContractForm,
  SiteOverview,
  SiteAddress,
  SiteContract
}
