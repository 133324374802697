import { retrieve } from "/apps/configuration/energy-company/aggregator/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { useTranslation } from "react-i18next"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import List from "./components/List"

const AggregatorRoutesContext = createContext(null)

const AggregatorContent = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const auth = useAuth()
  const [aggregator, setAggregator] = useState(null)
  const { t } = useTranslation()

  const retrieveDetail = async () => {
    await retrieve(id, auth.userData.access_token)
      .then(response => {
        setAggregator(response.data)
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.aggregator"), message)]))
      })
  }

  useEffect(() => {
    id && retrieveDetail()
  }, [id])

  return (
    <AggregatorRoutesContext.Provider value={{ aggregator, retrieveDetail }}>
      {aggregator ? <Outlet /> : <Loader />}
    </AggregatorRoutesContext.Provider>
  )
}

export { AggregatorRoutesContext }
export default function AggregatorRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":id"
        element={<AggregatorContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
