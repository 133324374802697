import { retrieve } from "/apps/optimization/state/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { site } from "/utils/store/site"
import { useDispatch, useSelector } from "react-redux"
import { organization } from "/utils/store/organization"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { Route, Routes, useParams } from "react-router-dom"
import { Loader, TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import { useTranslation } from "react-i18next"

import Create from "./components/Create"
import Delete from "./components/Delete"
import Detail from "./components/Detail"
import List from "./components/List"

import StateValueRoutes from "./value/routes"

const StateRoutesContext = createContext()

const StateContent = () => {
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { stateId } = useParams()
  const [state, setState] = useState()
  const [tabs, setTabs] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (siteStore.id && stateId) {
      setTabs([
        {
          name: t("common.overview"),
          active: location.pathname.endsWith(`/states/${stateId}/`),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/${stateId}/`
        },
        {
          name: t("common.point_values"),
          active: location.pathname.includes(`/states/${stateId}/values`),
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/${stateId}/values`
        }
      ])

      retrieveDetail()
    }
  }, [siteStore.id, stateId])

  const retrieveDetail = async () => {
    setLoading(true)
    await retrieve(siteStore.id, stateId, auth.userData.access_token)
      .then(response => {
        setState(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.state"), error.message)]))
      })
      .finally(() => setLoading(false))
  }

  return (
    <StateRoutesContext.Provider value={{ state, retrieveDetail }}>
      {state && !loading ? (
        <TabsLayout
          title={`${t("common.state")} ${state.name}`}
          tabs={tabs}
          setTabs={setTabs}
        />
      ) : (
        <Loader />
      )}
    </StateRoutesContext.Provider>
  )
}

export { StateRoutesContext }
export default function StateRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path=":stateId"
        element={<StateContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
        <Route
          index
          path="values/*"
          element={<StateValueRoutes />}
        />
      </Route>
    </Routes>
  )
}
