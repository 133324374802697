import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"

export const SMNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      proto: {
        label: i18n.t("common.protocol"),
        field: Dropdown,
        options: {
          options: [
            { value: "HTTP", label: i18n.t("common.http") },
            { value: "HTTPS", label: i18n.t("common.https") }
          ],
          required: true
        }
      },
      sm_type: {
        label: i18n.t("common.sm_type"),
        field: Dropdown,
        options: {
          options: [
            { value: "AK-SM8XX", label: "AK-SM8XX" },
            { value: "AK-SM8XXA", label: "AK-SM8XXA" },
            { value: "AK-SC355", label: "AK-SC355" }
          ],
          required: true
        }
      },
      store_name: {
        label: i18n.t("common.store_name"),
        field: InputText,
        options: { maxLength: 255 }
      },
      software: {
        label: i18n.t("common.software"),
        field: InputText,
        options: { maxLength: 255 }
      },
      user: {
        label: i18n.t("common.user"),
        field: InputText,
        options: {
          required: true,
          maxLength: 255
        }
      },
      password: {
        label: i18n.t("common.password"),
        field: InputText,
        options: {
          required: true,
          type: "password",
          maxLength: 255
        }
      },
      confirm_password: {
        label: i18n.t("common.confirm_password"),
        field: InputText,
        options: {
          required: true,
          type: "password",
          maxLength: 255
        }
      }
    }
  }
}

export const SMOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      proto: { label: i18n.t("common.protocol") },
      sm_type: { label: i18n.t("common.sm_type") },
      store_name: { label: i18n.t("common.store_name") },
      software: { label: i18n.t("common.software") },
      user: { label: i18n.t("common.user") }
    }
  }
}
