{
  "create_bill": "create_bill",
  "label_add_bill": "Ajouter une facturation",
  "label_add_fix_price": "Ajouter un prix fixe",
  "label_add_schedule_price": "Ajouter un prix planifié",
  "label_cancel": "Annuler",
  "label_dates": "Dates",
  "label_end_date": "Date de fin",
  "label_fix_price_name": "Nom du prix fixe",
  "label_fix_price_value": "Prix fixe ({{currency}}/kWh)",
  "label_fix_prices": "Prix fixes",
  "label_name_schedule_prices": "Nom du prix planifié",
  "label_save": "Sauvegarder",
  "label_schedule_prices": "Prix planifiés",
  "label_schedule_prices_currency": "Prix planifiés  ({{currency}}/kWh)",
  "label_start_date": "Date de début",
  "label_update_last_bill": "Modifier la dernière facturation",
  "message_bill_created": "La facturation a été créée",
  "message_bill_incoherent_dates": "La date de début de la facturation doit être postérieur à la date de fin de la facutration précédente",
  "message_bill_updated": "La facturation a été mise à jour",
  "message_no_bill": "Il n'y a pas de facturation configuré sur ce site, cliquez sur le bouton ci-dessus pour en créer une.",
  "message_no_fix_prices": "Il n'y a pas de prix fixes pour cette facturation",
  "message_no_schedule_prices": "Il n'y a pas de prix planifiés pour cette configuration"
}
