import { GatewayRoutesContext } from "apps/connectivity/gateway/routes"
import { GATEWAY_TYPES, VIRTUAL_MACHINE_TYPES } from "utils/constants/gateway"
import { handleErrors } from "utils/error"
import { errorNotification, successNotification } from "utils/notification"
import { addNotifications } from "utils/store/notification"
import { organization } from "utils/store/organization"
import { updateSideBar } from "utils/store/sidebar"
import { site } from "utils/store/site"
import { OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { getAvailableSensinovIDs, getSpaces, update } from "../api"
import { getFormModel, getOverviewModel as overviewModel } from "../models/utils"

import {
  getAllValues as getHardwareValues,
  retrieveList as retrieveHardwares
} from "apps/configuration/gateway/box/api"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [spaces, setSpaces] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [loadingSpaces, setLoadingSpaces] = useState(false)
  const [sensinovIDs, setSensinovIDs] = useState([])
  const [loadindSensinovIDs, setLoadingSensinovIDs] = useState(false)
  const { gateway, retrieveDetail } = useContext(GatewayRoutesContext)

  const updateObject = async e => {
    e.preventDefault()
    setProcessing(true)

    await update(
      siteStore.id,
      gateway.id,
      {
        ...data,
        hardware: data.hardware?.id ?? null,
        space: data.space ?? null
      },
      auth.userData.access_token
    )
      .then(() => {
        dispatch(
          addNotifications([successNotification(t("common.gateway"), t("common.update_success"))])
        )
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.gateway"))
      })
      .finally(() => setProcessing(false))
  }

  const retrieveAvailableHardwares = queryParams => {
    return retrieveHardwares(auth.userData.access_token, {
      available: 1,
      gateway: gateway.id,
      ...queryParams
    })
  }

  const listHardwareAllValues = () => {
    return getHardwareValues(auth.userData.access_token)
  }

  const retrieveSpaces = async () => {
    setLoadingSpaces(true)
    await getSpaces(siteStore.id, auth.userData.access_token)
      .then(({ data }) => {
        setSpaces(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.spaces"), message)]))
      })
      .finally(() => setLoadingSpaces(false))
  }

  const retrieveSensinovIDs = async () => {
    setLoadingSensinovIDs(true)
    await getAvailableSensinovIDs(auth.userData.access_token)
      .then(({ data }) => {
        setSensinovIDs([...data, { name: gateway.name, external_id: gateway.external_id }])
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.sensinov"), message)]))
      })
      .finally(() => setLoadingSensinovIDs(false))
  }

  const hardwareInfos = () =>
    gateway &&
    gateway.hardware_info && (
      <div className="list-section">
        <div className="list-section-head">
          <h3>{t("common.hardware")}</h3>
        </div>

        <dl className="overview-resume">
          <div className="overview-resume-item">
            <dt>{t("common.model")}</dt>
            <dd>{gateway.hardware_info.model}</dd>
          </div>
          <div className="overview-resume-item">
            <dt>{t("common.manufacturer")}</dt>
            <dd>{gateway.hardware_info.manufacturer}</dd>
          </div>
          <div className="overview-resume-item">
            <dt>{t("common.serial_id")}</dt>
            <dd>{gateway.hardware_info.serial_id}</dd>
          </div>
          <div className="overview-resume-item">
            <dt>{t("common.description")}</dt>
            <dd>{gateway.hardware_info.description}</dd>
          </div>
        </dl>
      </div>
    )

  const getModel = (type, subType) =>
    getFormModel({
      type,
      subType,
      modelParams: {
        spaces,
        loadingSpaces,
        sensinovIDs,
        loadindSensinovIDs,
        retrieveHardwares: retrieveAvailableHardwares,
        getHardwareValues: listHardwareAllValues
      }
    })

  const getOverviewModel = (type, subType) => overviewModel({ type, subType })

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (gateway) {
      setLoading(false)
      setData(gateway)
    }
  }, [gateway])

  useEffect(() => {
    // Only if edit mode
    if (edit) {
      retrieveSpaces()
      if (
        gateway.type === GATEWAY_TYPES.VIRTUAL_MACHINE &&
        gateway.virtual_machine_type === VIRTUAL_MACHINE_TYPES.SENSINOV
      ) {
        retrieveSensinovIDs()
      }
    }
  }, [edit])

  useEffect(() => {
    if (
      edit &&
      data.type === GATEWAY_TYPES.VIRTUAL_MACHINE &&
      data.virtual_machine_type === VIRTUAL_MACHINE_TYPES.SENSINOV
    ) {
      const { external_id } = sensinovIDs.find(item => item.name === data.name)
      setData({ ...data, external_id })
    }
  }, [data.name])

  return (
    gateway && (
      <>
        <nav>
          <Link
            to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/equipments/?gateway_name=${gateway.name}`}
          >
            <Button
              link
              rounded
              severity="secondary"
              label={t("common.equipments")}
              icon="fa-solid fa-square-arrow-up-right"
            />
          </Link>
          <Link
            to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/?gateway_name=${gateway.name}`}
          >
            <Button
              link
              rounded
              severity="secondary"
              label={t("common.points")}
              icon="fa-solid fa-square-arrow-up-right"
            />
          </Link>
        </nav>
        <OverView
          object={gateway}
          model={data && getModel(data.type, data.virtual_machine_type)}
          overviewModel={gateway && getOverviewModel(gateway.type, gateway.virtual_machine_type)}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          setEdit={setEdit}
          loading={loading || loadindSensinovIDs}
          processing={processing}
          deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/delete/`}
          additionalComponent={hardwareInfos}
        />
      </>
    )
  )
}
