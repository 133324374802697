import i18n from "i18next"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"

export const BacnetNode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      device_instance: {
        label: i18n.t("common.device_instance"),
        field: InputText,
        options: { maxLength: 255 }
      },
      router: {
        label: i18n.t("common.router"),
        field: InputSwitch,
        options: {}
      }
    }
  }
}

export const BacnetOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      device_instance: { label: i18n.t("common.device_instance") },
      router: { label: i18n.t("common.router") }
    }
  }
}
