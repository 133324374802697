import { errorNotification } from "utils/notification"
import { addNotifications } from "./store/notification"

const PYDANTIC_ERROR_CODE = 422
const UNKNOW_ERROR_KEY = "UNKNOW_ERROR"

function handleBasicErrors(errorPayload, dispatch, errorTitle) {
  dispatch(
    addNotifications([
      errorNotification(errorTitle, errorPayload?.response?.data?.message ?? errorPayload.message)
    ])
  )
}

export function handleErrors(errorPayload, setErrors, dispatch, errorTitle) {
  if (errorPayload.status === PYDANTIC_ERROR_CODE) {
    /**
     * Pydantic error are builded like this:
     *
     * data: {
     *  detail: [
     *    {
     *      type: "type_of_the_error",
     *      loc: [
     *        "body",
     *        "payload",
     *        ...
     *        "name_of_the_field"
     *      ],
     *      msg: "Message of the error"
     *    },
     *    ...
     *  ]
     * }
     *
     */

    // Take the last location of each errors to have the field names linked to errors messages

    const errors =
      errorPayload?.response?.data?.detail?.reduce((errors, error) => {
        let fieldName = error?.loc[error?.loc.length - 1] ?? UNKNOW_ERROR_KEY

        // Check for weird case of an array send to the back
        // case encoutered on optimization_gateways in flex contract
        if (Number.isInteger(fieldName)) {
          fieldName = error?.loc[error?.loc.length - 2] ?? UNKNOW_ERROR_KEY
        }

        errors[fieldName] = error?.msg ?? ""
        return errors
      }, {}) ?? {}

    // case: The pydantic error is malformated
    if (Object.keys(errors).length === 0) {
      dispatch(addNotifications([errorNotification(errorTitle, errorPayload.message)]))
      return
    }

    if (errors?.UNKNOW_ERROR_KEY) {
      // we have a strange error in error payload, this should not happen
      // better display something
      handleBasicErrors(errorPayload, dispatch)
    }

    // just let the form handle the errors
    setErrors(errors)
  } else {
    // other error
    handleBasicErrors(errorPayload, dispatch)
  }
}
