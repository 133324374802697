import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const commonsFields = (nodes, loadingNodes) => {
  return {
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },
    node: {
      label: i18n.t("common.node"),
      field: Dropdown,
      options: {
        required: true,
        options: nodes,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingNodes,
        placeholder: loadingNodes
          ? i18n.t("common.loading")
          : i18n.t("common.select", {
              value: i18n.t("common.node").toLowerCase()
            }),
        filter: true
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    }
  }
}

export const commonsOverview = () => {
  return {
    name: { label: i18n.t("common.name") },
    node_name: { label: i18n.t("common.node") },
    description: { label: i18n.t("common.description") }
  }
}
