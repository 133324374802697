import { NODE_TYPES } from "./node"

export const AUTOMATION_POINT_TYPES = {
  MODBUS: "modbus",
  BACNET: "bacnet",
  SM: "sm",
  OPCUA: "opcua",
  CAREL: "carel",
  EXTERNAL_API: "externalapi",
  SM_IO: "smio",
  SM_METER: "smmeter",
  SM_CONTROLLER: "smcontroller",
  MODBUS_BIT: "modbusbit",
  MODBUS_REGISTER: "modbusregister",
  MODBUS_REGISTER_BIT_EXTRACT: "modbusregisterbitextract"
}

export const AUTOMATION_POINT_DEFAULT_VALUES = {
  [AUTOMATION_POINT_TYPES.BACNET]: { bacnet_priority: 9 },
  [AUTOMATION_POINT_TYPES.OPCUA]: {},
  [AUTOMATION_POINT_TYPES.CAREL]: {
    write: false,
    is_logic: false
  },
  [AUTOMATION_POINT_TYPES.EXTERNAL_API]: {},
  [AUTOMATION_POINT_TYPES.SM_IO]: {},
  [AUTOMATION_POINT_TYPES.SM_METER]: {},
  [AUTOMATION_POINT_TYPES.SM_CONTROLLER]: {
    writable: false
  },
  [AUTOMATION_POINT_TYPES.MODBUS_BIT]: {
    writable: false
  },
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER]: {
    writable: false,
    swap_words: false,
    swap_bytes: false
  },
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER_BIT_EXTRACT]: {
    writable: false,
    swap_words: false,
    swap_bytes: false
  }
}

export const MAPPING_NODE_TYPES = {
  [AUTOMATION_POINT_TYPES.MODBUS_BIT]: NODE_TYPES.MODBUS,
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER]: NODE_TYPES.MODBUS,
  [AUTOMATION_POINT_TYPES.MODBUS_REGISTER_BIT_EXTRACT]: NODE_TYPES.MODBUS,
  [AUTOMATION_POINT_TYPES.BACNET]: NODE_TYPES.BACNET,
  [AUTOMATION_POINT_TYPES.OPCUA]: NODE_TYPES.OPCUA,
  [AUTOMATION_POINT_TYPES.CAREL]: NODE_TYPES.CAREL_NETWORK,
  [AUTOMATION_POINT_TYPES.SM_IO]: NODE_TYPES.SM_IO,
  [AUTOMATION_POINT_TYPES.SM_METER]: NODE_TYPES.SM_METER,
  [AUTOMATION_POINT_TYPES.SM_CONTROLLER]: NODE_TYPES.SM_CONTROLLER,
  [AUTOMATION_POINT_TYPES.EXTERNAL_API]: NODE_TYPES.EXTERNAL_API
}

export const READ_WRITE = {
  READ: "R",
  WRITE: "W"
}

export const BACNET_PRIORITY = {
  MIN: 1,
  MAX: 16
}
