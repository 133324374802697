import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"

import { commonsFields, commonsOverview } from "./commons"
import { BACNET_PRIORITY } from "/utils/constants/automation_point"

export const Bacnet = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      object_instance: {
        label: i18n.t("common.object_instance"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 0
        }
      },
      object_name: {
        label: i18n.t("common.object_name"),
        field: InputText,
        options: { maxLength: 255 }
      },
      object_type: {
        label: i18n.t("common.object_type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "ANALOG_INPUT", label: i18n.t("common.analog_input_0") },
            { value: "ANALOG_OUTPUT", label: i18n.t("common.analog_output_1") },
            { value: "ANALOG_VALUE", label: i18n.t("common.analog_value_2") },
            { value: "BINARY_INPUT", label: i18n.t("common.binary_input_3") },
            { value: "BINARY_OUTPUT", label: i18n.t("common.binary_output_4") },
            { value: "BINARY_VALUE", label: i18n.t("common.binary_value_5") },
            {
              value: "MULTI_STATE_INPUT",
              label: i18n.t("common.multi_state_input_13")
            },
            {
              value: "MULTI_STATE_OUTPUT",
              label: i18n.t("common.multi_state_input_14")
            }
          ]
        }
      },
      bacnet_property: {
        label: i18n.t("common.bacnet_property"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "PRESENT_VALUE", label: i18n.t("common.present_value") },
            { value: "STATUS_FLAGS", label: i18n.t("common.status_flags") },
            { value: "PRIORITY_ARRAY", label: i18n.t("common.priority_array") },
            { value: "RELINQUISH_DEFAULT", label: i18n.t("common.relinquish_default") },
            { value: "UNINHIBIT_PRIORITY", label: i18n.t("common.uninhibit_priority") },
            {
              value: "PRIORITY_1",
              label: i18n.t("common.priority", { priority: 1 })
            },
            {
              value: "PRIORITY_2",
              label: i18n.t("common.priority", { priority: 2 })
            },
            {
              value: "PRIORITY_3",
              label: i18n.t("common.priority", { priority: 3 })
            },
            {
              value: "PRIORITY_4",
              label: i18n.t("common.priority", { priority: 4 })
            },
            {
              value: "PRIORITY_5",
              label: i18n.t("common.priority", { priority: 5 })
            },
            {
              value: "PRIORITY_6",
              label: i18n.t("common.priority", { priority: 6 })
            },
            {
              value: "PRIORITY_7",
              label: i18n.t("common.priority", { priority: 7 })
            },
            {
              value: "PRIORITY_8",
              label: i18n.t("common.priority", { priority: 8 })
            },
            {
              value: "PRIORITY_9",
              label: i18n.t("common.priority", { priority: 9 })
            },
            {
              value: "PRIORITY_10",
              label: i18n.t("common.priority", { priority: 10 })
            },
            {
              value: "PRIORITY_11",
              label: i18n.t("common.priority", { priority: 11 })
            },
            {
              value: "PRIORITY_12",
              label: i18n.t("common.priority", { priority: 12 })
            },
            {
              value: "PRIORITY_13",
              label: i18n.t("common.priority", { priority: 13 })
            },
            {
              value: "PRIORITY_14",
              label: i18n.t("common.priority", { priority: 14 })
            },
            {
              value: "PRIORITY_15",
              label: i18n.t("common.priority", { priority: 15 })
            },
            {
              value: "PRIORITY_16",
              label: i18n.t("common.priority", { priority: 16 })
            }
          ]
        }
      },
      bacnet_priority: {
        label: i18n.t("common.bacnet_priority"),
        field: InputNumber,
        options: {
          showButtons: true,
          min: BACNET_PRIORITY.MIN,
          max: BACNET_PRIORITY.MAX
        }
      },
      bacnet_priority_uninhibition: {
        label: i18n.t("common.bacnet_priority_uninhibition"),
        field: InputNumber,
        options: {
          showButtons: true,
          min: BACNET_PRIORITY.MIN,
          max: BACNET_PRIORITY.MAX
        }
      }
    }
  }
}

export const BacnetOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      object_instance: { label: i18n.t("common.object_instance") },
      object_name: { label: i18n.t("common.object_name") },
      object_type: { label: i18n.t("common.object_type") },
      bacnet_priority: { label: i18n.t("common.bacnet_priority") },
      bacnet_priority_uninhibition: {
        label: i18n.t("common.bacnet_priority_uninhibition")
      }
    }
  }
}
