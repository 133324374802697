import { errorNotification, successNotification } from "/utils/notification.js"
import { addNotifications } from "/utils/store/notification.js"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar.js"
import { site } from "/utils/store/site"
import { DeleteConfirmation, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getCategoriesCount, removeCategories } from "../api"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [objectCount, setObjectCount] = useState()
  const [processing, setProcessing] = useState(false)
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)
  const ids = searchParams.getAll("id")

  const count = async () => {
    setLoadingRelatedObject(true)
    await getCategoriesCount(
      siteStore.id,
      { unavailability_category_ids: ids },
      auth.userData.access_token
    )
      .then(({ data, status, statusText }) => {
        if (status === 200) {
          setObjectCount(data)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(
          addNotifications([errorNotification(t("common.unavailability_categories"), message)])
        )
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removeCategories(
      siteStore.id,
      { unavailability_category_ids: ids },
      auth.userData.access_token
    )
      .then(({ status, statusText }) => {
        if (status === 204) {
          dispatch(
            addNotifications([
              successNotification(t("common.unavailability_categories"), t("common.delete_success"))
            ])
          )
          navigate(
            `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-categories/`
          )
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(
          addNotifications([errorNotification(t("common.unavailability_categories"), message)])
        )
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    siteStore.id && count()
  }, [siteStore.id])

  return loadingRelatedObject ? (
    <Loader />
  ) : (
    objectCount && objectCount.label && !!objectCount.label.length && (
      <DeleteConfirmation
        objectType={t("common.unavailability_categories")}
        objectNames={objectCount.label}
        relatedObjects={objectCount}
        remove={bulkDelete}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}

export default DeleteBulk
