import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import { InputNumber } from "primereact/inputnumber"
import { InputText } from "primereact/inputtext"
import { commonsFields, commonsOverview } from "./commons"
import i18n from "i18next"
import { INT_MAX_VALUE } from "/utils/constants/input"

export const OPCUANode = spaces => {
  return {
    ...commonsFields(spaces),
    ...{
      namespace_index: {
        label: i18n.t("common.namespace_index"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 0,
          max: INT_MAX_VALUE
        }
      },
      path: {
        label: i18n.t("common.path"),
        field: InputText,
        options: { maxLength: 255, required: true }
      },
      batch_size_read: {
        label: i18n.t("common.batch_size_read"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 1,
          max: INT_MAX_VALUE
        }
      },
      batch_size_write: {
        label: i18n.t("common.batch_size_write"),
        field: InputNumber,
        options: {
          required: true,
          showButtons: true,
          min: 1,
          max: INT_MAX_VALUE
        }
      },
      security_policy: {
        label: i18n.t("common.security_policy"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "None", label: i18n.t("common.none") },
            { value: "Basic256", label: "Basic256" },
            { value: "Basic128Rsa15", label: "Basic128Rsa15" },
            { value: "Basic256Sha256", label: "Basic256Sha256" }
          ]
        }
      },
      security_mode: {
        label: i18n.t("common.security_mode"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "None", label: i18n.t("common.none") },
            { value: "Sign", label: i18n.t("common.sign") },
            { value: "SignAndEncrypt", label: i18n.t("common.sign_and_encrypt") }
          ]
        }
      },
      server_certificate_path: {
        label: i18n.t("common.server_certificate_path"),
        field: InputText,
        options: { maxLength: 255, required: true }
      }
    }
  }
}

export const OPCUAOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      namespace_index: { label: i18n.t("common.namespace_index") },
      path: { label: i18n.t("common.path") },
      batch_size_read: { label: i18n.t("common.batch_size_read") },
      batch_size_write: { label: i18n.t("common.batch_size_write") },
      security_policy: { label: i18n.t("common.security_policy") },
      security_mode: { label: i18n.t("common.security_mode") },
      server_certificate_path: { label: i18n.t("common.server_certificate_path") }
    }
  }
}
