import { GatewayRoutesContext } from "/apps/connectivity/gateway/routes"
import { addInterface, getAllValues, retrieveList } from "/apps/connectivity/network-interface/api"
import { NetworkIPInterface } from "/apps/connectivity/network-interface/models"
import { paginatedRequest } from "/utils/api"
import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  ButtonWithLoader,
  FormContent,
  ListActions,
  ListLayout
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const { gateway } = useContext(GatewayRoutesContext)
  const [items, setItems] = useState([])
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [networks, setNetworks] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingNetworks, setLoadingNetworks] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [connectVisible, setConnectVisible] = useState(false)
  const [errors, setErrors] = useState({})
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)
  const [selected, setSelected] = useState([])
  const [interfaceForm, setInterfaceForm] = useState({
    type: "ipnetwork",
    gateway: gateway.id
  })
  const DEFAULT_FILTER = { gateway: [gateway.id] }

  const retrieve = async (queryParams = { ...DEFAULT_FILTER, limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        if (data) {
          const networkInterfaces = data.results.map(item => ({
            ...item,
            network: item.network.id,
            ip_address_range: item.network.label
          }))

          setCount(data.count)
          setItems(networkInterfaces)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.network_interface"), message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(gateway.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.filter_values"), error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const retrieveNetworks = async () => {
    setLoadingNetworks(true)
    await paginatedRequest(
      `asset/sites/${siteStore.id}/networks/?type=ipnetwork`,
      auth.userData.access_token
    )()
      .then(results => {
        setNetworks(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.network_interface"), message)]))
      })
      .finally(() => setLoadingNetworks(false))
  }

  const interfaceAdd = async e => {
    e.preventDefault()
    setProcessing(true)
    await addInterface(siteStore.id, interfaceForm, auth.userData.access_token)
      .then(async () => {
        dispatch(
          addNotifications([
            successNotification(t("common.gateway_interface"), t("common.creation_success"))
          ])
        )

        // Reset list
        setOffset(REQUEST_DEFAULT_OFFSET)
        setItems([])

        retrieve()
        setConnectVisible(false)
      })
      .catch(error => {
        if (error.response.data) {
          setErrors(error.response.data)
        }
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.gateway_interface"), message)]))
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  const getDetailUrl = interfaceId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/${interfaceId}/`

  const getDeleteUrl = interfaceId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/${interfaceId}/delete/`

  const onRowClick = networkId => {
    navigate(getDetailUrl(networkId))
  }

  const goBack = e => {
    e && e.preventDefault()
    setConnectVisible(false)
  }

  const columns = [
    { field: "ip_address_range", header: t("common.network"), sortable: true },
    { field: "ip_addr", header: t("common.ip_address"), sortable: true }
  ]

  const columnsFilter = [
    { field: "ip_address_range", header: t("common.network_ip_range") },
    { field: "ip_addr", header: t("common.ip_address") }
  ]

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  useEffect(() => {
    if (gateway && gateway.id && siteStore.id && organizationStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      getFilterValues()
    }
  }, [siteStore.id, organizationStore.id, gateway])

  const queryParams = () => {
    return {
      ...filters,
      ...DEFAULT_FILTER,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  useEffect(() => {
    connectVisible && retrieveNetworks()
  }, [connectVisible])

  return connectVisible ? (
    <form
      className="form-view"
      onSubmit={interfaceAdd}
    >
      <div className="base-content-title">
        <h2>{t("common.connect_a_network")}</h2>
      </div>

      <FormContent
        model={NetworkIPInterface(networks, loadingNetworks)}
        data={interfaceForm}
        setData={setInterfaceForm}
        errors={errors}
      />
      <div className="action-buttons-wrapper">
        <Button
          rounded
          outlined
          severity="secondary"
          label={t("common.cancel")}
          disabled={processing}
          icon="fa-solid fa-xmark"
          onClick={goBack}
        />
        <ButtonWithLoader
          rounded
          severity="success"
          label={t("common.connect")}
          type="submit"
          disabled={processing || loadingNetworks}
          icon="fa-solid fa-floppy-disk"
        />
      </div>
    </form>
  ) : (
    <section className="list-section">
      <ListActions
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <Button
          rounded
          label={t("common.connect")}
          icon="fa-solid fa-plug-circle-plus"
          onClick={() => setConnectVisible(true)}
          severity="info"
        />
      </ListActions>
      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
        backendSort={true}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/network-interfaces/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
      />
    </section>
  )
}
