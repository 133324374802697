import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation } from "react-router-dom"

import AggregatorRoutes from "./aggregator/routes"
import DistributorRoutes from "./distributor/routes"
import RetailerRoutes from "./retailer/routes"
import TransmissionRoutes from "./transmission/routes"

export default function EnergyCompanyRoutes() {
  /* Tabs to ListLayout component*/
  const location = useLocation()
  const { t } = useTranslation()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        name: t("common.distributors"),
        active: location.pathname.includes("distributors"),
        url: "/configuration/energy-company/distributors/"
      },
      {
        name: t("common.aggregators"),
        active: location.pathname.includes("aggregators"),
        url: "/configuration/energy-company/aggregators/"
      },
      {
        name: t("common.retailers"),
        active: location.pathname.includes("retailers"),
        url: "/configuration/energy-company/retailers/"
      },
      {
        name: t("common.tso"),
        active: location.pathname.includes("transmissions"),
        url: "/configuration/energy-company/transmissions/"
      }
    ])
  }, [location])

  return (
    <Routes>
      <Route
        element={
          <TabsLayout
            title={t("common.energy_companies")}
            tabs={tabs}
            setTabs={setTabs}
          />
        }
      >
        <Route
          index
          path="distributors/*"
          element={<DistributorRoutes />}
        />
        <Route
          path="aggregators/*"
          element={<AggregatorRoutes />}
        />
        <Route
          path="retailers/*"
          element={<RetailerRoutes />}
        />
        <Route
          path="transmissions/*"
          element={<TransmissionRoutes />}
        />
      </Route>
    </Routes>
  )
}
