import axios from "axios"
import { config } from "/utils/api"

export async function retrieveList(countryCode, token, queryParams) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/weather_zones/${countryCode}/`,
    config({ token, queryParams })
  )
}

export async function update(weatherZoneId, data, token) {
  return await axios.patch(
    `${window.BACKEND_URL}/asset/weather_zones/${weatherZoneId}`,
    data,
    config({ token })
  )
}

export async function getAllValues(countryCode, token) {
  return await axios.get(
    `${window.BACKEND_URL}/asset/weather_zones/${countryCode}/allvalues/`,
    config({ token })
  )
}
