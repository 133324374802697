import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { Dropdown } from "primereact/dropdown"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add } from "../api"
import {
  AUTOMATION_POINT_DEFAULT_VALUES,
  AUTOMATION_POINT_TYPES,
  MAPPING_NODE_TYPES,
  READ_WRITE
} from "/utils/constants/automation_point"
import { handleErrors } from "/utils/error"
import { get_form_model } from "../models/utils"

const SelectType = ({ data, setData }) => {
  const { t } = useTranslation()

  const [type, setType] = useState(null)
  const types = [
    { value: AUTOMATION_POINT_TYPES.MODBUS, label: t("common.modbus") },
    { value: AUTOMATION_POINT_TYPES.BACNET, label: t("common.bacnet") },
    { value: AUTOMATION_POINT_TYPES.SM, label: t("common.aksm") },
    { value: AUTOMATION_POINT_TYPES.OPCUA, label: t("common.opcua") },
    { value: AUTOMATION_POINT_TYPES.CAREL, label: t("common.carel") },
    { value: AUTOMATION_POINT_TYPES.EXTERNAL_API, label: t("common.external_api") }
  ]
  const [subTypeList, setSubTypeList] = useState([])
  const [subType, setSubType] = useState(null)

  const smSubTypes = [
    { value: AUTOMATION_POINT_TYPES.SM_IO, label: t("common.aksm_io") },
    { value: AUTOMATION_POINT_TYPES.SM_METER, label: t("common.aksm_meter") },
    { value: AUTOMATION_POINT_TYPES.SM_CONTROLLER, label: t("common.aksm_controller") }
  ]

  const modbusSubTypes = [
    { value: AUTOMATION_POINT_TYPES.MODBUS_BIT, label: t("common.modbus_bit") },
    { value: AUTOMATION_POINT_TYPES.MODBUS_REGISTER, label: t("common.modbus_register") },
    {
      value: AUTOMATION_POINT_TYPES.MODBUS_REGISTER_BIT_EXTRACT,
      label: t("common.modbus_register_bit_extract")
    }
  ]

  const updateType = value => {
    setType(value)
    setSubType(null)

    if (value === AUTOMATION_POINT_TYPES.SM) {
      setSubTypeList(smSubTypes)
    } else if (value === AUTOMATION_POINT_TYPES.MODBUS) {
      setSubTypeList(modbusSubTypes)
    } else {
      setSubTypeList([])
    }
  }

  const updateSubType = value => {
    setSubType(value)
  }

  useEffect(() => {
    if (type === AUTOMATION_POINT_TYPES.SM && subType) {
      setData({ ...data, ...{ type: subType }, ...AUTOMATION_POINT_DEFAULT_VALUES[subType] })
    } else if (type === AUTOMATION_POINT_TYPES.MODBUS && subType) {
      setData({ ...data, ...{ type: subType }, ...AUTOMATION_POINT_DEFAULT_VALUES[subType] })
    } else {
      setData({ ...data, ...{ type: type }, ...AUTOMATION_POINT_DEFAULT_VALUES[type] })
    }
  }, [type, subType])

  return (
    <>
      <div className="field-container">
        <div className="field dropdown2">
          <label className="label label-required">{t("common.type")}</label>
          <div className="field-input">
            <Dropdown
              options={types}
              value={type}
              onChange={e => updateType(e.value)}
              placeholder={t("common.choose_type")}
            />
          </div>
        </div>
      </div>

      {subTypeList.length > 0 && (
        <div className="field-container">
          <div className="field dropdown2">
            <label className="label label-required">{t("common.sub_type")}</label>
            <div className="field-input">
              <Dropdown
                options={subTypeList}
                value={subType}
                onChange={e => updateSubType(e.value)}
                placeholder={t("common.choose_sub_type")}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [nodes, setNodes] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingNodes, setLoadingNodes] = useState(true)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)

    const pointData = { ...data, site: siteStore.id }
    if (pointData.type === AUTOMATION_POINT_TYPES.SM_CONTROLLER) {
      pointData.rw = pointData.writable ? READ_WRITE.WRITE : READ_WRITE.READ
    }

    await add(siteStore.id, pointData, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([
            successNotification(t("common.automation_point"), t("common.creation_success"))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/communication/automation-points/${response.data.id}`
        )
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.automation_point"))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    setLoadingNodes(true)
    const retrieveNodes = async () => {
      await paginatedRequest(
        `asset/sites/${siteStore.id}/nodes/?type=${MAPPING_NODE_TYPES[data.type]}`,
        auth.userData.access_token
      )()
        .then(results => {
          setNodes(results)
        })
        .catch(error => {
          dispatch(
            addNotifications([errorNotification(t("common.automation_point"), error.message)])
          )
        })
        .finally(() => setLoadingNodes(false))
    }
    if (data && data.type) retrieveNodes()
  }, [data.type])

  return (
    data && (
      <BaseLayout
        title={t("common.create_asset", { asset: t("common.automation_point").toLowerCase() })}
      >
        <form
          className="form-view"
          onSubmit={create}
        >
          <SelectType
            data={data}
            setData={setData}
          />
          {data.type && (
            <FormContent
              model={get_form_model(data.type, nodes, loadingNodes)}
              data={data}
              setData={setData}
              errors={errors}
            />
          )}
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              disabled={processing}
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("common.save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
              disabled={processing}
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}
