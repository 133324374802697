import { organization } from "utils/store/organization"
import { site } from "utils/store/site"
import { DeleteConfirmation, Loader } from "@software-engineering/hivolution-frontend-utils"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { FlexContractRoutesContext } from "../context"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { siteId, organizationId, flexId } = useParams()

  const {
    flexContract,
    retrieveFlexContractById,
    isLoading,
    isProcessing,
    deleteCurrentFlexContract
  } = useContext(FlexContractRoutesContext)

  useEffect(() => {
    !flexContract && retrieveFlexContractById(flexId)
  }, [siteId, organizationId, flexId])

  if (isLoading) {
    return <Loader />
  }

  return (
    flexContract && (
      <DeleteConfirmation
        objectType={t("common.flex_contract")}
        objectNames={[flexContract.name ?? ""]}
        remove={deleteCurrentFlexContract}
        processing={isProcessing}
        goBack={() =>
          navigate(
            `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/${flexId}/parameters/`
          )
        }
      />
    )
  )
}
