import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const UnavailabilityCategory = (spaces, loadingSpaces) => ({
  /*
   * List of fields for the model, like this
   */
  name: {
    label: i18n.t("common.name"),
    field: InputText,
    options: { required: true }
  },
  scope: {
    label: i18n.t("common.scope"),
    field: Dropdown,
    options: {
      options: [
        { value: "Flexibility", label: i18n.t("common.flexibility") },
        { value: "Optimization", label: i18n.t("common.optimization") }
      ],
      required: true
    }
  },
  description: {
    label: i18n.t("common.description"),
    field: InputTextarea,
    options: {}
  },
  space: {
    label: i18n.t("common.space"),
    field: Dropdown,
    options: {
      options: spaces,
      optionLabel: "name",
      optionValue: "id",
      loading: loadingSpaces,
      placeholder: loadingSpaces
        ? i18n.t("common.loading")
        : i18n.t("common.select_a", {
            value: i18n.t("common.space").toLowerCase()
          })
    }
  }
})
