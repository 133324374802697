import { STORAGE_TYPES } from "/utils/constants/storage"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  add,
  getAutomationPoints,
  getAutomationPointsAllValues,
  getEquipmentAllValues,
  getEquipments,
  getParameters,
  getStores
} from "../api"
import { Point } from "../models"
import { handleErrors } from "/utils/error"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()

  const [data, setData] = useState({
    parameter: 1,
    read: false,
    write: false,
    time_series_storage_info: {
      linear_multiplier: 1,
      acceptable_values: [
        {
          original_point_value: 0,
          gateway_point_value: 0,
          gateway_point_text: "",
          gateway_point_value_change: false
        },
        {
          original_point_value: 1,
          gateway_point_value: 1,
          gateway_point_text: "",
          gateway_point_value_change: false
        }
      ]
    }
  })
  const [errors, setErrors] = useState({})
  const [parameters, setParameters] = useState([])
  const [stores, setStores] = useState([])
  const [processing, setProcessing] = useState(false)
  const [loadingParameters, setLoadingParameters] = useState(false)
  const [loadingStores, setLoadingStores] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const create = async function (e) {
    e.preventDefault()
    setProcessing(true)
    const pointData = structuredClone(data)

    switch (pointData.time_series_storage_info.store_type) {
      case STORAGE_TYPES.GAUGE:
        delete pointData.time_series_storage_info.acceptable_values
        break

      case STORAGE_TYPES.STATUS:
        delete pointData.time_series_storage_info.linear_multiplier
        delete pointData.time_series_storage_info.linear_constant
        break

      default:
        delete pointData.time_series_storage_info
        break
    }

    pointData.equipment = pointData.equipment?.id ?? null
    pointData.automation_point = pointData.automation_point?.id ?? null

    await add(siteStore.id, pointData, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([successNotification(t("common.point"), t("common.creation_success"))])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/points/${response.data.id}`
        )
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.point"))
      })
      .finally(() => setProcessing(false))
  }

  const retrieveEquipments = async (queryParams = {}) => {
    return await getEquipments(siteStore.id, auth.userData.access_token, queryParams)
  }

  const getEquipmentValues = async () => {
    return await getEquipmentAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveParameters = async () => {
    setLoadingParameters(true)
    await getParameters(auth.userData.access_token)
      .then(response => {
        response.data.results && setParameters(response.data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.parameters"), message)]))
      })
      .finally(() => setLoadingParameters(false))
  }

  const retrieveAutomationPoints = async (queryParams = {}) => {
    return await getAutomationPoints(siteStore.id, auth.userData.access_token, {
      ...queryParams,
      node_equipment: data.equipment?.id
    })
  }

  const getAutomationPointValues = async () => {
    return await getAutomationPointsAllValues(siteStore.id, auth.userData.access_token)
  }

  const retrieveStores = async () => {
    setLoadingStores(true)
    await getStores(
      siteStore.id,
      data.time_series_storage_info.store_type,
      auth.userData.access_token
    )
      .then(response => {
        response.data.results && setStores(response.data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.stores"), message)]))
      })
      .finally(() => setLoadingStores(false))
  }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      retrieveParameters()
    }
  }, [siteStore.id])

  useEffect(() => {
    data.time_series_storage_info.store_type && retrieveStores()
  }, [data.time_series_storage_info.store_type])

  return (
    data && (
      <>
        <div className="base-content-title">
          <h1 className="title">
            {t("common.create_asset", { asset: t("common.point").toLowerCase() })}
          </h1>
        </div>

        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={Point(
              retrieveEquipments,
              getEquipmentValues,
              retrieveAutomationPoints,
              getAutomationPointValues,
              parameters,
              stores,
              loadingParameters,
              loadingStores
            )}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              disabled={processing}
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("common.save")}
              type="submit"
              disabled={processing || loadingParameters || loadingStores}
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
