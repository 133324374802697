{
  "label_batch": "Batch",
  "label_comment": "Comment",
  "label_constraint_state": "Constraint",
  "label_create_button": "Create",
  "label_curtail_state": "Curtail",
  "label_export_button": "Export",
  "label_export_with_batch": "Export with batch",
  "label_export_without_batch": "Export without batch",
  "label_false": "No",
  "label_gateway": "Gateway",
  "label_import_button": "Import",
  "label_import_with_batch": "Import with batch",
  "label_import_without_batch": "Import without batch",
  "label_inhibit_state": "Inhibit",
  "label_modified_at": "Modified at",
  "label_modified_by": "Modified by",
  "label_name": "Name",
  "label_not_selected_gateway": "Select a gateway",
  "label_not_selected_state": "Select a state",
  "label_point": "Point",
  "label_point_values": "Point values",
  "label_postcool_state": "Postcool",
  "label_precool_state": "Precool",
  "label_safe_state": "Safe",
  "label_save_button": "Save",
  "label_state": "State",
  "label_state_name": "Name",
  "label_true": "Yes",
  "label_type": "Type",
  "label_value": "Value",
  "notification_create": "Create",
  "notification_delete": "Delete",
  "notification_error": "Error",
  "notification_export": "Export",
  "notification_import": "Import",
  "notification_state_created": "State has been created.",
  "notification_state_deleted": "State has been deleted.",
  "notification_state_export_success": "State has been exported successfully.",
  "notification_state_updated": "State has been updated.",
  "notification_state_values_import_success": "State values have been imported successfully.",
  "notification_update": "Update",
  "overview_tab_label": "Overview",
  "point_values": "Point values",
  "point_values_tab_label": "Point values",
  "title_state_creation": "State creation",
  "title_state_list": "States"
}
