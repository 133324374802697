import { RetailerRoutesContext } from "/apps/configuration/energy-company/retailer/routes"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { BaseLayout, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { update } from "../api"
import { Retailer as RetailerModel } from "../models"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const { t } = useTranslation()
  const auth = useAuth()
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const { retailer, retrieveDetail } = useContext(RetailerRoutesContext)

  const updateObject = async function (e) {
    e.preventDefault()

    setProcessing(true)
    await update(retailer.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([successNotification(t("common.retailer"), t("common.update_success"))])
        )
        setLoading(true)
        retrieveDetail()
        setEdit(false)
      })
      .catch(error => {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response && error.response.data && setErrors(error.response.data)
        dispatch(addNotifications([errorNotification(t("common.retailer"), message)]))
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    if (retailer) {
      setLoading(false)
      setData(retailer)
    }
  }, [retailer])

  return (
    retailer && (
      <BaseLayout title={retailer.name}>
        <OverView
          object={retailer}
          model={RetailerModel()}
          updateObject={updateObject}
          data={data}
          setData={setData}
          errors={errors}
          edit={edit}
          setEdit={setEdit}
          loading={loading}
          processing={processing}
          deleteUrl={`/configuration/energy-company/retailers/${retailer.id}/delete/`}
        />
      </BaseLayout>
    )
  )
}
