import { GATEWAY_TYPES, VIRTUAL_MACHINE_TYPES } from "/utils/constants/gateway"
import { Gateway, GatewayOverview } from "./GatewayBox"
import { Grdf, GrdfOverview } from "./Grdf"
import { Koovea, KooveaOverview } from "./Koovea"
import { MobileAlerts, MobileAlertsOverview } from "./MobileAlerts"
import { MySirius, MySiriusOverview } from "./MySirius"
import { Sensinov, SensinovOverview } from "./Sensinov"
import { Sigfox, SigfoxOverview } from "./Sigfox"

const FORM_MODELS = {
  [GATEWAY_TYPES.BOX]: Gateway,
  [VIRTUAL_MACHINE_TYPES.SENSINOV]: Sensinov,
  [VIRTUAL_MACHINE_TYPES.MY_SIRIUS]: MySirius,
  [VIRTUAL_MACHINE_TYPES.SIGFOX]: Sigfox,
  [VIRTUAL_MACHINE_TYPES.GRDF]: Grdf,
  [VIRTUAL_MACHINE_TYPES.KOOVEA]: Koovea,
  [VIRTUAL_MACHINE_TYPES.MOBILE_ALERTS]: MobileAlerts
}

export const getFormModel = ({ type, subType, modelParams }) => {
  const model = type === GATEWAY_TYPES.BOX ? FORM_MODELS[type] : FORM_MODELS[subType]
  return model ? model(modelParams) : {}
}

const OVERVIEW_MODELS = {
  [GATEWAY_TYPES.BOX]: GatewayOverview,
  [VIRTUAL_MACHINE_TYPES.SENSINOV]: SensinovOverview,
  [VIRTUAL_MACHINE_TYPES.MY_SIRIUS]: MySiriusOverview,
  [VIRTUAL_MACHINE_TYPES.SIGFOX]: SigfoxOverview,
  [VIRTUAL_MACHINE_TYPES.GRDF]: GrdfOverview,
  [VIRTUAL_MACHINE_TYPES.KOOVEA]: KooveaOverview,
  [VIRTUAL_MACHINE_TYPES.MOBILE_ALERTS]: MobileAlertsOverview
}

export const getOverviewModel = ({ type, subType }) => {
  const model = type === GATEWAY_TYPES.BOX ? OVERVIEW_MODELS[type] : OVERVIEW_MODELS[subType]
  return model ? model() : {}
}
