import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { datetimeStr } from "/utils/datetime"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import {
  ListActions,
  ListLayout,
  CopyButton
} from "@software-engineering/hivolution-frontend-utils"
import fileDownload from "js-file-download"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { exportSpaces, getAllValues, importSpaces, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [loadingFilters, setLoadingFilters] = useState(false)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(1)
  const [sortField, setSortField] = useState("name")
  const [selected, setSelected] = useState([])
  const [processingImport, setProcessingImport] = useState(false)
  const [processingExport, setProcessingExport] = useState(false)
  const [processingTemplate, setProcessingTemplate] = useState(false)
  const hiddenFileInput = useRef(null)

  const retrieve = async (queryParams = { limit, offset }) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setCount(data.count)
        data.results && setItems(data.results)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.space"), error.message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues(response.data)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.filter_values"), error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "name", header: t("common.name"), sortable: true },
    // { field: "surface_area", header: t("Surface area (m²)"), sortable: true },
    // { field: "height", header: t("Height (m)"), sortable: true },
    {
      field: "is_optimized",
      header: t("common.optimized"),
      sortable: true,
      processValue: value => `${value ? t("common.yes") : t("common.no")}`
    },
    {
      field: "is_permeable",
      header: t("common.permeable"),
      sortable: true,
      processValue: value => `${value ? t("common.yes") : t("common.no")}`
    },
    {
      field: "is_cooled",
      header: t("common.cooled"),
      sortable: true,
      processValue: value => `${value ? t("common.yes") : t("common.no")}`
    },
    {
      field: "is_heated",
      header: t("common.heated"),
      sortable: true,
      processValue: value => `${value ? t("common.yes") : t("common.no")}`
    }
  ]

  const getDetailUrl = objectId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/${objectId}/`
  }

  const getDeleteUrl = objectId => {
    return `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/${objectId}/delete/`
  }

  const onRowClick = objectId => {
    navigate(getDetailUrl(objectId))
  }

  const getExport = async function () {
    setProcessingExport(true)
    await exportSpaces(siteStore.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `spaces_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.export"), error.message)]))
      })
      .finally(() => setProcessingExport(false))
  }

  const getTemplate = async function () {
    setProcessingTemplate(true)
    await exportSpaces(0, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `template_spaces_${datetimeStr()}.csv`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.template"), error.message)]))
      })
      .finally(() => setProcessingTemplate(false))
  }

  const postImport = async function (target) {
    const file = target.files[0]

    if (file) {
      setProcessingImport(true)
      await importSpaces(siteStore.id, file, auth.userData.access_token)
        .then(async response => {
          dispatch(
            addNotifications([successNotification(t("common.import"), response.data.message)])
          )
          setLoading(true)
          await retrieve()
          setLoading(false)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.headers &&
            error.response.headers["content-type"] === "text/csv"
          ) {
            fileDownload(error.response.data, `ERROR_spaces_${datetimeStr()}.csv`)
            dispatch(
              addNotifications([errorNotification(t("common.import"), t("common.import_error"))])
            )
          } else {
            dispatch(addNotifications([errorNotification(t("common.import"), error.message)]))
          }
        })
        .finally(() => {
          setProcessingImport(false)
          target.value = null
        })
    }
  }

  const menuEntries = [
    {
      label: t("common.import"),
      icon: processingImport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-import",
      disabled: processingImport,
      command: () => hiddenFileInput && hiddenFileInput.current.click()
    },
    {
      label: t("common.export"),
      icon: processingExport ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file-export",
      disabled: processingExport,
      command: getExport
    },
    {
      label: t("common.template"),
      icon: processingTemplate ? "fa-solid fa-spinner fa-spin" : "fa-solid fa-file",
      disabled: processingTemplate,
      command: getTemplate
    }
  ]

  const menuFilters = { columnsFilter: columns, filters, setFilters, filterValues }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      getFilterValues()
    }
  }, [siteStore.id, organizationStore.id])

  const queryParams = () => {
    return {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  }

  useEffect(() => {
    siteStore.id && (offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve(queryParams()))
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve(queryParams())
  }, [limit, offset])

  const isSelectable = event => {
    return event.data && event.data.id !== siteStore.equivalent_space
  }

  const ActionsTemplate = ({ id }) => {
    return (
      <div className="p-datatable-action-buttons">
        {getDetailUrl && (
          <CopyButton
            isUrl
            icon="fa-solid fa-link"
            value={getDetailUrl(id)}
          />
        )}

        {getDeleteUrl && id !== siteStore.equivalent_space && (
          <Link
            className="delete-button"
            to={getDeleteUrl(id)}
          >
            <i className="fa-solid fa-trash-can" />
          </Link>
        )}
      </div>
    )
  }

  return (
    <>
      <ListActions
        entries={menuEntries}
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <input
          className="hidden"
          ref={hiddenFileInput}
          type="file"
          onChange={e => postImport(e.target)}
        />
        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/create/`}
        >
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("common.create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        offset={offset}
        limit={limit}
        setOffset={setOffset}
        setLimit={setLimit}
        count={count}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/building/spaces/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
        isDataSelectable={isSelectable}
        actionsBodyTemplate={ActionsTemplate}
        backendSort={true}
      />
    </>
  )
}
