import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BILL_PRICE_TYPES, MAPPING_CURRENCY_SYMBOL } from "utils/constants/bill"

export default function BillResume({ bill, currency }) {
  const { t } = useTranslation()

  const [fixPrices, setFixPrices] = useState([])
  const [schPrices, setSchPrices] = useState([])

  useEffect(() => {
    if (bill) {
      const fix = bill.prices.filter(price => price.price_type === BILL_PRICE_TYPES.FIX)
      const sch = bill.prices.filter(price => price.price_type === BILL_PRICE_TYPES.SCHEDULE)
      setFixPrices(fix)
      setSchPrices(sch)
    }
  }, [bill])

  return (
    bill && (
      <div className="bill-resume">
        <div className="bill-resume-dates">
          <h3>{t("common.dates")}</h3>
          <dl>
            <div className="bill-resume-item">
              <dt>{t("common.start_date")}</dt>
              <dd>{new Date(bill.start_validity.replace("T", " ")).toLocaleDateString()}</dd>
            </div>
            <div className="bill-resume-item">
              <dt>{t("common.end_date")}</dt>
              <dd>
                {new Date(Date.parse(bill.end_validity.replace("T", " "))).toLocaleDateString()}
              </dd>
            </div>
          </dl>
        </div>

        <div className="bill-resume-fix-prices">
          <h3>{t("common.fix_prices")}</h3>

          {fixPrices.length > 0 ? (
            <dl>
              {fixPrices.map((fixPrice, index) => {
                return (
                  <div
                    key={index}
                    className="bill-resume-item"
                  >
                    <dt>{fixPrice.name}</dt>
                    <dd>
                      {fixPrice.value} {MAPPING_CURRENCY_SYMBOL[currency]}
                    </dd>
                  </div>
                )
              })}
            </dl>
          ) : (
            <span>{t("common.no_fix_prices")}</span>
          )}
        </div>
        <div className="bill-resume-schedule-prices">
          <h3>{t("common.schedule_prices")}</h3>

          {schPrices.length > 0 ? (
            <div className="schedule-prices">
              {schPrices.map((schPrice, index) => {
                return (
                  <div
                    key={index}
                    className="schedule-price"
                  >
                    <span>{schPrice.name}</span>
                    <dl>
                      {Object.entries(schPrice.value).map(([key, value], index) => {
                        return (
                          <div
                            className="bill-resume-item"
                            key={index}
                          >
                            <dt>{key}</dt>
                            <dd>
                              {value} {MAPPING_CURRENCY_SYMBOL[currency]}
                            </dd>
                          </div>
                        )
                      })}
                    </dl>
                  </div>
                )
              })}
            </div>
          ) : (
            <span>{t("common.no_schedule_prices")}</span>
          )}
        </div>
      </div>
    )
  )
}
