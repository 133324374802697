import axios from "axios"
import { config } from "/utils/api"

export async function getGateways(token, queryParams) {
  return await axios.get(`${window.HAWKBIT_URL}/rest/v1/targets`, config({ token, queryParams }))
}

export async function getVersions(token, queryParams) {
  return await axios.get(
    `${window.HAWKBIT_URL}/rest/v1/distributionsets`,
    config({ token, queryParams })
  )
}

export async function getVersion(token, id) {
  return await axios.get(`${window.HAWKBIT_URL}/rest/v1/distributionsets/${id}`, config({ token }))
}

export async function setVersionToGateways(distributionId, targets, token) {
  return await axios.post(
    `${window.HAWKBIT_URL}/rest/v1/distributionsets/${distributionId}/assignedTargets`,
    targets,
    config({ token })
  )
}
