import { config } from "/utils/api"
import axios from "axios"

const header = token => ({
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Authorization": `Bearer ${token}`
  }
})

export async function retrieveList(siteId, stateId, queryParams, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/${stateId}/values/`,
    config({ token, queryParams })
  )
}

export async function exportStateValues(siteId, stateId, batch, token) {
  return await axios({
    method: "get",
    url: `${window.BACKEND_URL}/optimization/sites/${siteId}/states/${stateId}/export-values/?batch=${batch}`,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export async function importStateValues(siteId, stateId, jsonContent, batch, token) {
  const data = {
    file: jsonContent,
    batch: batch
  }

  return await axios.post(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/${stateId}/import-values/`,
    data,
    header(token)
  )
}

export async function getAllValues(siteId, stateId, token) {
  return await axios.get(
    `${window.BACKEND_URL}/optimization/sites/${siteId}/states/${stateId}/values/allvalues`,
    header(token)
  )
}
