import { EdgeControllerRoutesContext } from "../routes"
import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  exportEdgeController,
  getEdgeControllerTypes,
  importConfigurationEdgeController,
  update
} from "../api"
import { EdgeController, EdgeControllerOverview } from "../models"
import { organization } from "/utils/store/organization"
import ConfigErrors from "./ConfigError"
import { Button } from "primereact/button"
import { EDGE_CONTROLLER_TYPES } from "/utils/constants/edge_controller"
import CodeMirror from "./CodeMirrorInput"
import fileDownload from "js-file-download"
import { datetimeStr } from "/utils/datetime"

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const { t } = useTranslation()
  const [object, setObject] = useState({})
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [gateways, setGateways] = useState([])
  const hiddenFileInput = useRef(null)
  const [edgeControllerTypes, setEdgeControllerTypes] = useState([
    EDGE_CONTROLLER_TYPES.OPTIMIZATION
  ])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const { edgeController, retrieveDetail } = useContext(EdgeControllerRoutesContext)

  const [configErrors, setConfigErrors] = useState(null)

  const updateObject = async function (e) {
    e.preventDefault()
    setConfigErrors(null)
    setLoading(true)
    await update(siteStore.id, object.id, data, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([
            successNotification(t("common.edge_controller"), t("common.update_success"))
          ])
        )
        setEdit(false)

        retrieveDetail()
      })
      .catch(error => {
        if (error.response.data) {
          try {
            const errorConfig = JSON.parse(error.response.data.message)
            setConfigErrors(errorConfig)
          } catch {
            setErrors(error.response.data)
          }
        }
        dispatch(addNotifications([errorNotification(t("common.edge_controller"), error.message)]))
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (edgeController) {
      setObject(edgeController)
      setData(edgeController)
      setLoading(false)
    }
  }, [edgeController])

  useEffect(() => {
    // Get gateways
    const getGW = async () => {
      // TODO: remove when the api will no longer paginate
      await paginatedRequest(
        `connectivity/sites/${siteStore.id}/gateways/`,
        auth.userData.access_token
      )()
        .then(results => {
          setGateways(results)
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification(t("common.gateways"), error.message)]))
        })
    }

    const getECT = async () => {
      await getEdgeControllerTypes(auth.userData.access_token)
        .then(({ data }) => {
          setEdgeControllerTypes(data)
        })
        .catch(error => {
          dispatch(
            addNotifications([errorNotification(t("common.edge_controller_types"), error.message)])
          )
        })
    }

    // Only on edit
    if (siteStore.id && edit) {
      getGW()
      getECT()
    }
  }, [siteStore.id, edit])

  const customError = () => {
    return configErrors && <ConfigErrors configErrors={configErrors} />
  }

  const getExport = async function () {
    await exportEdgeController(siteStore.id, edgeController.id, auth.userData.access_token)
      .then(response => {
        fileDownload(response.data, `edge_controller_${datetimeStr()}.tar`)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.export"), error.message)]))
      })
  }

  const resumeConfiguration = () => {
    return (
      <div className="ml-3">
        <h4>{t("common.configuration")}</h4>

        <CodeMirror
          value={object.config}
          height="400px"
          readOnly={true}
        />
      </div>
    )
  }

  const exportButton = () => {
    return (
      <Button
        rounded
        severity="info"
        label={t("common.export")}
        type="button"
        icon="fa-solid fa-download"
        onClick={getExport}
        outlined
      />
    )
  }

  const postImport = async function (target) {
    const file = target.files[0]

    if (file) {
      await importConfigurationEdgeController(file, auth.userData.access_token)
        .then(response => {
          setData({
            ...data,
            config: response.data
          })
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification(t("common.import"), error.message)]))
        })
        .finally(() => {
          target.value = null
        })
    }
  }

  return object && !loading ? (
    <>
      {edit && (
        <div className="base-content-title flex flex-column align-items-start">
          <input
            className="hidden"
            ref={hiddenFileInput}
            type="file"
            onChange={e => postImport(e.target)}
          />
          <Button
            rounded
            label={t("common.import")}
            type="button"
            icon="fa-solid fa-upload"
            onClick={() => hiddenFileInput && hiddenFileInput.current.click()}
          />
        </div>
      )}
      <OverView
        object={object}
        model={EdgeController(gateways, edgeControllerTypes)}
        overviewModel={EdgeControllerOverview()}
        additionalComponent={resumeConfiguration}
        additionalActionButtons={exportButton}
        updateObject={updateObject}
        data={data}
        setData={setData}
        errors={errors}
        edit={edit}
        setEdit={setEdit}
        customErrorComponent={customError}
        loading={loading}
        deleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/edge-controllers/${object.id}/delete/`}
      />
    </>
  ) : (
    <Loader />
  )
}
