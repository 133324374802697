import { SiteContentContext } from "/apps/assets/site/routes"
import { retrieveAll } from "/apps/organization/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { BaseLayout, Loader, OverView } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { TabPanel, TabView } from "primereact/tabview"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  addContract,
  addSavingShare,
  getContracts,
  getPriceZones,
  getSavingShares,
  getWeatherZones,
  update,
  updateContract,
  removeContract,
  updateSavingShare
} from "../api"
import {
  SiteAddress,
  SiteAddressForm,
  SiteContract,
  SiteContractForm,
  SiteOverview,
  SiteOverviewForm
} from "../models"

function ContractView() {
  const dispatch = useDispatch()
  const siteStore = useSelector(site)
  const [contractObject, setContractObject] = useState({})
  const [contractData, setContractData] = useState({
    forced_stop_hours: 144
  })
  const [contract, setContract] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const auth = useAuth()
  const [savingShare, setSavingShare] = useState()
  const [contractErrors, setContractErrors] = useState({})
  const [processingContractData, setProcessingContractData] = useState(false)
  const [loadingContractData, setLoadingContractData] = useState(false)
  const [edit, setEdit] = useState(false)
  const { t } = useTranslation()

  const getSiteContract = async () => {
    const response = await getContracts(siteStore.id, auth.userData.access_token).catch(error =>
      dispatch(addNotifications([errorNotification(t("common.contract"), error.message)]))
    )
    return (
      response &&
      response.data &&
      response.data.results &&
      !!response.data.results.length &&
      response.data.results[0]
    )
  }

  const getSiteSavingShare = async id => {
    const response = await getSavingShares(id, auth.userData.access_token).catch(error =>
      dispatch(addNotifications([errorNotification(t("common.saving_share"), error.message)]))
    )
    return (
      response &&
      response.data &&
      response.data.results &&
      !!response.data.results.length &&
      response.data.results[0]
    )
  }

  const updateSiteContractData = async () => {
    setLoadingContractData(true)

    let contractInfos = { ...contractData }

    const contract = await getSiteContract()
    const savingShare = contract && contract.id && (await getSiteSavingShare(contract.id))

    if (contract) {
      const {
        hiveoptimal_contract = true,
        id,
        name,
        forced_stop_hours,
        forced_stop_reset
      } = contract
      contractInfos = {
        ...contractInfos,
        hiveoptimal_contract,
        id,
        name,
        forced_stop_hours,
        forced_stop_reset
      }
      setContract(contract)
    } else {
      const { hiveoptimal_contract = false } = contract
      contractInfos = { ...contractInfos, hiveoptimal_contract }
      setContract(contract)
    }

    if (savingShare) {
      const { bb_share, start_validity, end_validity } = savingShare

      contractInfos = {
        ...contractInfos,
        bb_share
      }

      start_validity && (contractInfos.start_validity = start_validity.split("T")[0])
      end_validity && (contractInfos.end_validity = end_validity.split("T")[0])

      setSavingShare(savingShare)
    }

    setContractData(contractInfos)
    setContractObject(contractInfos)
    setLoadingContractData(false)
  }

  const updateContractData = async e => {
    e && e.preventDefault()
    setProcessingContractData(true)

    let contractErrorsData = {}
    let hasError = false

    const contract = await updateContractDetail().catch(error => {
      hasError = true
      error.response.data &&
        (contractErrorsData = { ...contractErrorsData, ...error.response.data })
      dispatch(addNotifications([errorNotification(t("common.contract"), error.message)]))
    })

    contract &&
      contract.data &&
      contract.data.id &&
      (await updateSavingShareDetail(contract.data.id).catch(error => {
        hasError = true
        error.response.data &&
          (contractErrorsData = { ...contractErrorsData, ...error.response.data })
        dispatch(addNotifications([errorNotification(t("common.saving_share"), error.message)]))
      }))

    setProcessingContractData(false)

    if (hasError) {
      setContractErrors(contractErrorsData)
    } else {
      setEdit(false)
    }
    updateSiteContractData()
  }

  const updateContractDetail = async () => {
    const data = {
      site_id: siteStore.id,
      ...contractData
    }

    if (!data.hiveoptimal_contract) {
      return removeContract(data.id, auth.userData.access_token)
        .then(() => {
          dispatch(
            addNotifications([
              successNotification(t("common.contract"), t("common.delete_success"))
            ])
          )
        })
        .catch(error => {
          dispatch(addNotifications([errorNotification(t("common.contract"), error.message)]))
          return (data.hiveoptimal_contract = true)
        })
    } else {
      return contract
        ? updateContract(contract.id, data, auth.userData.access_token)
        : addContract(data, auth.userData.access_token)
            .then(() => {
              dispatch(
                addNotifications([
                  successNotification(t("common.contract"), t("common.update_success"))
                ])
              )
            })
            .catch(error => {
              dispatch(addNotifications([errorNotification(t("common.contract"), error.message)]))
            })
    }
  }

  const updateSavingShareDetail = contractId => {
    const { bb_share, start_validity, end_validity } = contractData
    const data = {
      contract_id: contractId,
      bb_share,
      start_validity,
      end_validity
    }

    return savingShare
      ? updateSavingShare(savingShare.id, data, auth.userData.access_token)
      : addSavingShare(data, auth.userData.access_token)
  }

  useEffect(() => {
    setStartDate(contractData.start_validity)
    setEndDate(contractData.end_validity)
  }, [contractData.start_validity, contractData.end_validity])

  useEffect(() => {
    if (siteStore.id && !edit) {
      setContractData({})
      setContractObject({})
      updateSiteContractData()
    }
  }, [siteStore, edit])

  return (
    <OverView
      model={SiteContractForm(
        edit,
        contractData.hiveoptimal_contract,
        setStartDate,
        startDate,
        setEndDate,
        endDate
      )}
      overviewModel={SiteContract(contractData.hiveoptimal_contract)}
      object={contractObject}
      updateObject={updateContractData}
      data={contractData}
      setData={setContractData}
      errors={contractErrors}
      edit={edit}
      loading={loadingContractData}
      processing={processingContractData}
      setEdit={setEdit}
    />
  )
}

export default function Detail() {
  /*
   * This component work with PrimeReact
   * It displays an OverView component to see and update the current object.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/${id}" element={<Detail />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const dispatch = useDispatch()
  const auth = useAuth()
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)
  const [priceZones, setPriceZones] = useState([])
  const [weatherZones, setWeatherZones] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [active, setActive] = useState(0)
  const [loadingPriceZone, setLoadingPriceZone] = useState(false)
  const [loadingWeatherZone, setLoadingWeatherZone] = useState(false)
  const [loadingOrganizations, setLoadingOrganizations] = useState(false)
  const { retrieveDetail } = useContext(SiteContentContext)

  const onTabChange = e => {
    setActive(e.index)
    setEdit(false)
  }

  const getPZ = async () => {
    setLoadingPriceZone(true)
    await getPriceZones(auth.userData.access_token)
      .then(({ data }) => {
        data && setPriceZones(data)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification(t("common.site"), message)]))
        console.error(message)
      })
      .finally(() => setLoadingPriceZone(false))
  }

  const getWZ = async countryCode => {
    setLoadingWeatherZone(true)
    await getWeatherZones(countryCode, auth.userData.access_token)
      .then(({ data }) => {
        setWeatherZones(data.results)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification(t("common.weather_zone"), message)]))
        console.error(message)
      })
      .finally(() => setLoadingWeatherZone(false))
  }

  const getOrganizations = async () => {
    setLoadingOrganizations(true)
    await retrieveAll(auth.userData.access_token)
      .then(results => {
        results && setOrganizations(results)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification(t("common.organizations"), message)]))
        console.error(message)
      })
      .finally(() => setLoadingOrganizations(false))
  }

  const updateObject = async function (e) {
    e.preventDefault()
    setProcessing(true)

    const postData = {
      site: {
        ...data,
        latitude: `${data.latitude}`,
        longitude: `${data.longitude}`,
        altitude: `${data.altitude}`
      }
    }
    await update(siteStore.id, postData, auth.userData.access_token)
      .then(() => {
        dispatch(
          addNotifications([successNotification(t("common.site"), t("common.update_success"))])
        )
        retrieveDetail()
        setEdit(false)
        setErrors({})
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.site"), message)]))
        error.response && error.response.data && setErrors(error.response.data)
      })
      .finally(() => setProcessing(false))
  }

  const getDeleteUrl = () => `/organizations/${organizationStore.id}/sites/${siteStore.id}/delete/`

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      setData(siteStore)
      setLoading(false)
    }
  }, [siteStore.id])

  useEffect(() => {
    if (edit && organizationStore.id) {
      getPZ()
      getOrganizations()
    }
  }, [edit, organizationStore.id])

  useEffect(() => {
    edit && data.country && getWZ(data.country)
  }, [edit, data.country])

  useEffect(() => {
    if (edit && data.weather_zone_id) {
      const wz = weatherZones.find(item => item.id === data.weather_zone_id)
      wz && setData({ ...data, weather_zone_timezone: wz.timezone })
    }
  }, [edit, data.weather_zone_id])
  return siteStore ? (
    <BaseLayout title={siteStore.pretty_name ?? siteStore.name}>
      <TabView
        activeIndex={active}
        onTabChange={onTabChange}
      >
        <TabPanel header={t("common.overview")}>
          <OverView
            model={SiteOverviewForm(
              priceZones,
              organizations,
              loadingPriceZone,
              loadingOrganizations
            )}
            overviewModel={SiteOverview()}
            object={siteStore}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={
              processing || loadingPriceZone || loadingWeatherZone || loadingOrganizations
            }
            setEdit={setEdit}
            deleteUrl={getDeleteUrl()}
          />
        </TabPanel>
        <TabPanel header={t("common.address")}>
          <OverView
            model={SiteAddressForm(weatherZones)}
            overviewModel={SiteAddress()}
            object={siteStore}
            updateObject={updateObject}
            data={data}
            setData={setData}
            errors={errors}
            edit={edit}
            loading={loading}
            processing={processing}
            setEdit={setEdit}
          />
        </TabPanel>
        <TabPanel header={t("common.contract")}>
          <ContractView />
        </TabPanel>
      </TabView>
    </BaseLayout>
  ) : (
    <Loader />
  )
}
