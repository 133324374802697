import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputSwitch } from "primereact/inputswitch"
import { InputNumber } from "primereact/inputnumber"

import { commonsFields, commonsOverview } from "./commons"
import { INT_MAX_VALUE } from "/utils/constants/input"

export const Carel = (nodes, loadingNodes) => {
  return {
    ...commonsFields(nodes, loadingNodes),
    ...{
      id_var: {
        label: i18n.t("common.id_var"),
        field: InputNumber,
        options: {
          required: true,
          useGrouping: false,
          min: 0,
          max: INT_MAX_VALUE
        }
      },
      write: {
        label: i18n.t("common.writable"),
        field: InputSwitch,
        options: {}
      },
      is_logic: {
        label: i18n.t("common.is_logic"),
        field: InputSwitch,
        options: {}
      },
      carel_type: {
        label: i18n.t("common.type"),
        field: Dropdown,
        options: {
          required: true,
          options: [
            { value: "1", label: i18n.t("common.digital_1") },
            { value: "2", label: i18n.t("common.analog_2") },
            { value: "3", label: i18n.t("common.integer_3") },
            { value: "4", label: i18n.t("common.alarm_4") }
          ]
        }
      }
    }
  }
}

export const CarelOverview = () => {
  return {
    ...commonsOverview(),
    ...{
      id_var: { label: i18n.t("common.id_var") },
      read_write: { label: i18n.t("common.read_write") },
      type: { label: i18n.t("common.type") }
    }
  }
}
