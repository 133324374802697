{
  "Automation point type": "Automation point type",
  "common": {
    "0_digital_input": "0: digital input",
    "1_digital_output": "1: digital output",
    "2_analog_input": "2: analog input",
    "3_analog_output": "3: analog output",
    "acceptable_values": "Acceptable values",
    "access_level": "Access level",
    "action": "Action",
    "activated_success": "Activated successfully",
    "active": "Active",
    "add": "Add",
    "add_bill": "Add bill",
    "add_energy_delivery_point": "Add energy delivery point",
    "add_fix_price": "Add fix price",
    "add_gateway": "Add gateway",
    "add_schedule_price": "Add schedule price",
    "add_values": "Add values",
    "address": "Address",
    "aggregator": "Aggregator",
    "aggregator_response": "Aggregator response",
    "aggregators": "Aggregators",
    "aksm": "AK-SM",
    "aksm_controller": "AK-SM Controller",
    "aksm_controller_node": "AK-SM Controller Node",
    "aksm_io": "AK-SM IO",
    "aksm_io_node": "AK-SM IO Node",
    "aksm_meter": "AK-SM Meter",
    "aksm_meter_node": "AK-SM Meter Node",
    "aksm_network_node": "AK-SM Network Node",
    "aksm_node": "AK-SM Node",
    "alarm_4": "Alarm (4)",
    "altitude": "Altitude",
    "analog_2": "Analog (2)",
    "analog_input_0": "Analog input (0)",
    "analog_output_1": "Analog output (1)",
    "analog_value_2": "Analog value (2)",
    "aofd_nebef_rectangle": "AOFD + NEBEF Up Rectangle (Double Referencer Corrected)",
    "assets": "Assets",
    "automation_node": "Automation node",
    "automation_nodes": "Automation nodes",
    "automation_point": "Automation point",
    "automation_points": "Automation points",
    "back_edge_block_template_list": "Back to edge block template list",
    "backoff_max_wait": "Backoff max wait",
    "bacnet": "Bacnet",
    "bacnet_ip": "Bacnet IP",
    "bacnet_network": "Bacnet Network",
    "bacnet_number": "Bacnet number",
    "bacnet_priority": "Bacnet priority",
    "bacnet_priority_uninhibition": "Bacnet priority uninhibition",
    "bacnet_property": "Bacnet property",
    "base_informations": "Base informations",
    "batch": "Batch",
    "batch_size": "Batch size",
    "batch_size_read": "Batch size read",
    "batch_size_write": "Batch size write",
    "batch_size_writing": "Batch size writing",
    "bb_share": "Percent of share economics for BeeBryte",
    "beebrytebox": "BeeBryteBox",
    "bill": "Bill",
    "bills": "Bills",
    "binary_input_3": "Binary input (3)",
    "binary_output_4": "Binary output (4)",
    "binary_value_5": "Binary value (5)",
    "bit_start": "Bit start",
    "bit_type": "Type of bit",
    "bits_number": "Number of bits",
    "boolean": "Boolean",
    "boxes": "Boxes",
    "building": "Building",
    "bulk_import_bacnet_node": "Bulk import Bacnet node",
    "bulk_import_bacnet_point": "Bulk import Bacnet point",
    "bulk_import_carel_node": "Bulk import Carel node",
    "bulk_import_carel_point": "Bulk import Carel point",
    "bulk_import_circuit_breaker": "Bulk import circuit breaker",
    "bulk_import_circuit_breaker_relations": "Bulk import circuit breaker relations",
    "bulk_import_equipment": "Bulk import equipment",
    "bulk_import_modbus_node": "Bulk import Modbus node",
    "bulk_import_modbus_point": "Bulk import Modbus point",
    "bulk_import_network": "Bulk import network",
    "bulk_import_opcua_point": "Bulk import OPCUA point",
    "bulk_import_point": "Bulk import point",
    "bulk_import_sm_node": "Bulk import SM node",
    "bulk_import_sm_point": "Bulk import SM point",
    "bulk_import_space": "Bulk import space",
    "bulk_import_state_values": "Bulk import state values",
    "cabinet": "Cabinet",
    "cabinet_name": "Cabinet name",
    "cancel": "Cancel",
    "cards": "Cards",
    "carel": "Carel",
    "carel_network_node": "Carel Network Node",
    "carel_node": "Carel Node",
    "changes": "Changes",
    "choose_node_type": "Choose a node type",
    "choose_sub_type": "Choose a sub type",
    "choose_type": "Choose a type",
    "cid": "Cid",
    "cidr_format": "CIDR format: {{format}}",
    "circuit_breaker": "Circuit breaker",
    "circuit_breaker_relation": "Circuit breaker relation",
    "circuit_breaker_relations": "Circuit breaker relations",
    "circuit_breakers": "Circuit breakers",
    "circuit_breakers_relations": "Circuit breakers relations",
    "city": "City",
    "coef": "Coef",
    "coil": "Coil",
    "comment": "Comment",
    "communication": "Communication",
    "condition": "Condition",
    "configuration": "Configuration",
    "confirm_delete": "Are you sure you want to delete this {{asset}}?",
    "confirm_gateway_deployment": "Confirm gateway deployment",
    "confirm_gateway_deployment_history_delete": "Confirm gateway deletion",
    "confirm_gateway_deployment_history_delete_message": "You are about to delete a deployment history. This action is irreversible, please confirm.",
    "confirm_gateway_deployment_message": "You are about to deploy the box. Add information about the changes that apply to this deployment.",
    "confirm_password": "Confirm password",
    "confirmation_software_update": "Are you sure you want to deploy it?",
    "confirmed": "Confirmed",
    "confirmed_time": "Confirmed time",
    "connect": "Connect",
    "connect_a_network": "Connect a network",
    "connect_timeout": "Connect timeout",
    "connectivity": "Connectivity",
    "connectivity_view": "Connectivity view",
    "connector_instance": "Connector instance",
    "connector_instances": "Connector instances",
    "constraint_state": "Constraint state",
    "constraint_states": "Constraint states",
    "contract": "Contract",
    "control_method": "Control method",
    "cooled": "Cooled",
    "country": "Country",
    "country_code": "Country code",
    "create": "Create",
    "create_asset": "Create {{asset}}",
    "creation_success": "Created successfully",
    "curtail_capacity": "Curtail capacity",
    "curtail_end": "Curtail end",
    "curtail_parameters": "Curtail parameters",
    "curtail_schedule": "Curtail schedule",
    "curtail_schedules": "Curtail schedules",
    "curtail_start": "Curtail start",
    "curtail_state": "Curtailed state",
    "data_step": "Data step",
    "data_type": "Data type",
    "dates": "Dates",
    "deactivated_success": "Deactivated successfully",
    "delete": "Delete",
    "delete_error": "Delete error",
    "delete_gateway_box_linked_site": "Delete gateway box link to a site is not allowed.",
    "delete_success": "Deleted successfully",
    "delivery_points": "Delivery points",
    "deployment": "Deployment",
    "description": "Description",
    "device_id": "Device ID",
    "device_instance": "Device instance",
    "device_type": "Device type",
    "digital_1": "Digital (1)",
    "disable_storage": "Disable storage",
    "discrete_input": "Discrete input",
    "distributor": "Distributor",
    "distributor_company": "Distributor company",
    "distributors": "Distributors",
    "done": "Done",
    "double": "Double",
    "edge_block_template": "Edge block template",
    "edge_block_templates": "Edge block templates",
    "edge_blocks": "Edge blocks",
    "edge_controller": "Edge controller",
    "edge_controller_types": "Edge controller types",
    "edge_controllers": "Edge controllers",
    "electrical": "Electrical",
    "electricity": "Electricity",
    "end": "End",
    "end_date": "End date",
    "end_smaller_than_start": "End validity date must be after than start validity date.",
    "end_time": "End time",
    "end_validity": "End validity",
    "energy": "Energy",
    "energy_companies": "Energy companies",
    "energy_company": "Energy company",
    "energy_delivery_point": "Energy delivery point",
    "energy_delivery_point_missing": "Energy delivery point missing",
    "energy_delivery_points": "Energy delivery points",
    "energy_type": "Energy type",
    "equipment": "Equipment",
    "equipment_sub_type": "Equipment sub type",
    "equipment_sub_types": "Equipment sub types",
    "equipment_type": "Equipment type",
    "equipment_types": "Equipment types",
    "equipments": "Equipments",
    "equipments_count": "Equipments count",
    "equipments_powered": "Equipments powered",
    "equivalent_metering": "Equivalent metering",
    "equivalent_metering_equation": "Equivalent metering equation",
    "equivalent_metering_intercept": "Equivalent metering intercept",
    "error": "Error",
    "error_config": "There is errors in configuration",
    "error_fetching_data": "An error occurred while fetching the data. Please contact an administrator.",
    "event": "Event",
    "events": "Events",
    "export": "Export",
    "export_button": "Export",
    "export_success": "Exported successfully",
    "export_with_batch": "Export with batch",
    "export_without_batch": "Export without batch",
    "external_api": "External API",
    "external_api_node": "External API Node",
    "external_id": "External ID",
    "external_point_type": "External point type",
    "failed": "Failed",
    "false": "False",
    "filter_values": "Filter values",
    "fix_price_name": "Fix price name",
    "fix_price_value": "Fix price value",
    "fix_prices": "Fix prices",
    "flex": "Flex",
    "flex_contract": "Flex contract",
    "flex_opti": "Flex Opti",
    "flexibility": "Flexibility",
    "float": "Float",
    "forced_stop_hours": "Forced stop hours",
    "forced_stop_reset": "Forced stop reset",
    "format": "Format",
    "gas": "Gas",
    "gateway": "Gateway",
    "gateway_box": "Gateway box",
    "gateway_boxes": "Gateway boxes",
    "gateway_configuration": "Gateway configuration",
    "gateway_configuration_deploy_success": "Configuration has been successfully deployed",
    "gateway_interface": "Gateway interface",
    "gateway_interfaces": "Gateway interfaces",
    "gateway_missing": "Gateway missing",
    "gateway_point": "Gateway point",
    "gateway_udp_port": "Gateway UDP port",
    "gateways": "Gateways",
    "gauge": "Gauge",
    "generate": "Generate",
    "generated_at": "Generated at",
    "generated_by": "Generated by",
    "grdf": "GRDF",
    "hardware": "Hardware",
    "heated": "Heated",
    "height": "Height (m)",
    "history": "History",
    "hiveoptimal_contract": "HiveOptimal contract",
    "holding_register": "Holding register",
    "http": "HTTP",
    "https": "HTTPS",
    "id_var": "ID Var",
    "import": "Import",
    "import_button": "Import",
    "import_error": "An error occured during the import, see more details in the error report file.",
    "import_success": "Imported successfully",
    "import_with_batch": "Import with batch",
    "import_without_batch": "Import without batch",
    "in_progress": "In progress",
    "incoherent_dates": "Incoherent dates",
    "infos": "Infos",
    "inhibit_state": "Inhibit state",
    "inhibit_states": "Inhibit states",
    "input_register": "Input register",
    "int": "Int",
    "int_value": "Int{{value}}",
    "integer_3": "Integer (3)",
    "interface": "Interface",
    "interfaces": "Interfaces",
    "io_node_type": "IO Node Type",
    "ip": "IP",
    "ip_address": "IP Address",
    "ip_address_range": "IP Address Range",
    "ip_addresses": "IP addresses",
    "ip_range": "IP Range",
    "is_logic": "Is Logic",
    "is_measured_by": "Is measured by",
    "keep_alive_timer": "Keep alive timer",
    "koovea": "Koovea",
    "latitude": "Latitude",
    "line_id": "Line ID",
    "linear_constant": "Linear constant",
    "linear_multiplier": "Linear multiplier",
    "linked_equipments": "Linked equipments",
    "list": "List",
    "load_curtailment_for": "Load curtailment for",
    "loading": "Loading...",
    "logo_svg": "Logo (SVG)",
    "longitude": "Longitude",
    "low_cipher": "Low cipher",
    "manage_modbus_response_timeouts": "Manage Modbus response timeouts",
    "manage_timeout": "Manage timeout",
    "manufacturer": "Manufacturer",
    "map": "Map",
    "market_player_interaction": "Market player interaction",
    "market_schedule_group": "Market schedule group",
    "market_schedule_group_proposal_history": "Market schedule group proposal history",
    "max_schedules_per_day": "Max schedules per day",
    "measured_by": "Measured by",
    "meter_id": "Meter ID",
    "mobilealerts": "MobileAlerts",
    "mod": "Mod",
    "modbus": "Modbus",
    "modbus_bit": "Modbus bit",
    "modbus_ip": "Modbus IP",
    "modbus_register": "Modbus register",
    "modbus_register_bit_extract": "Modbus register bit extract",
    "mode": "Mode",
    "model": "Model",
    "modified_at": "Modified at",
    "modified_by": "Modified by",
    "mstp_address": "MSTP Address",
    "multi_case_name": "Multi case name",
    "multi_state_input_13": "Multi-state input (13)",
    "multi_state_input_14": "Multi-state input (14)",
    "mysirius": "MySirius",
    "name": "Name",
    "name_schedule_prices": "Name schedule prices",
    "namespace_index": "Namespace index",
    "network": "Network",
    "network_interface": "Network interface",
    "network_interfaces": "Network interfaces",
    "network_ip_range": "Network IP Range",
    "networks": "Networks",
    "neutralization_duration": "Neutralization duration",
    "no": "No",
    "no_bill": "No bill",
    "no_fix_prices": "No fix prices",
    "no_manufacturer": "No manufacturer",
    "no_organization_found": "No organization found",
    "no_requested_update": "No requested update",
    "no_schedule_prices": "No schedule prices",
    "no_weather_zone_found": "No weather zone found",
    "node": "Node",
    "node_address": "Node Address",
    "node_id": "Node ID",
    "node_interfaces": "Node interfaces",
    "nominal_equipments_powered": "Nominal equipments powered",
    "nominal_intensity": "Nominal intensity",
    "none": "None",
    "not_selected_gateway": "Gateway not selected",
    "not_selected_state": "State not selected",
    "not_selected_type": "Type not selected",
    "num_suction": "Num suction",
    "number_of_sites": "Number of sites",
    "object_instance": "Object instance",
    "object_name": "Object name",
    "object_type": "Object type",
    "offline": "Offline",
    "online": "Online",
    "opcua": "OPCUA",
    "opcua_node": "OPCUA Node",
    "open": "Open",
    "operations": "Operations",
    "opscua": "OPSCUA",
    "optimization": "Optimization",
    "optimization_gateway": "Optimization gateway",
    "optimized": "Optimized",
    "organization": "Organization",
    "organization_delete_not_allowed_site_linked": "You can't delete this organization, there is sites linked to it",
    "organizations": "Organizations",
    "overview": "Overview",
    "owm_city_id": "OWM city ID",
    "parameter": "Parameter",
    "parameters": "Parameters",
    "parent_circuit_breaker": "Parent circuit breaker",
    "parent_space": "Parent space",
    "password": "Password",
    "path": "Path",
    "permeable": "Permeable",
    "point": "Point",
    "point_values": "Point values",
    "points": "Points",
    "points_count": "Points count",
    "points_number": "Number of points",
    "port": "Port",
    "postcool_state": "Postcool state",
    "powers": "Powers",
    "precool_state": "Precool state",
    "present_value": "Present value",
    "pretty_name": "Pretty name",
    "price_zone": "Price zone",
    "price_zones": "Price zones",
    "priority": "Priority {{priority}}",
    "priority_array": "Priority array",
    "proposal_for_aggregator": "Proposal for aggregator",
    "proposal_sent": "Proposal sent",
    "propose_time": "Propose time",
    "proposed_by": "Proposed by",
    "proposed_time": "Proposed time",
    "protocol": "Protocol",
    "protocol_instance": "Protocol instance",
    "protocol_instances": "Protocol instances",
    "protocol_type": "Protocol type",
    "provide_date_site_timezone": "Dates must be provided in local site timezone",
    "pulling_interval": "Pulling interval",
    "quantity": "Quantity",
    "rack_id": "Rack ID",
    "read": "Read",
    "read_timeout": "Read timeout",
    "read_write": "Read / Write",
    "register_type": "Type of register",
    "relation_between": "Relation between",
    "relation_type": "Relation type",
    "relinquish_default": "Relinquish default",
    "remove": "Remove",
    "request_time": "Request time",
    "request_type": "Request type",
    "requested": "Requested",
    "requested_by": "Requested by",
    "resolution_interval": "Resolution interval",
    "response_timeout": "Response timeout",
    "response_timeout_seconds": "Response timeout (seconds)",
    "retailer": "Retailer",
    "retailers": "Retailers",
    "router": "Router",
    "running": "Running",
    "safe_state": "Safe state",
    "safe_states": "Safe states",
    "same_passwords": "Passwords must be the same",
    "save": "Save",
    "saving_share": "Saving share",
    "schedule_date": "Schedule date",
    "schedule_duration": "Schedule duration",
    "schedule_prices": "Schedule prices",
    "schedule_prices_currency": "Schedule prices currency",
    "scheme": "Scheme",
    "scope": "Scope",
    "section": "Section",
    "security_mode": "Security mode",
    "security_policy": "Security policy",
    "select": "Select {{value}}",
    "select_a": "Select a {{value}}",
    "select_an": "Select an {{value}}",
    "send_success": "Sent successfully",
    "send_to_aggregator": "Send to aggregator",
    "sensinov": "Sensinov",
    "sent_by": "Sent by",
    "serial": "Serial",
    "serial_id": "Serial ID",
    "serial_number": "Serial number",
    "server_certificate_path": "Server certificate path",
    "set_filter": "Set filter",
    "set_wait_time": "Set wait time",
    "sigfox": "Sigfox",
    "sign": "Sign",
    "sign_and_encrypt": "SignAndEncrypt",
    "site": "Site",
    "sites": "Sites",
    "sites_count_one": "{{count}} site",
    "sites_count_other": "{{count}} sites",
    "slave_id": "Slave ID",
    "sleep_time": "Sleep time",
    "sm": "SM",
    "sm_nodes": "SM Nodes",
    "sm_type": "SM Type",
    "sm_xml_api": "SM XML API",
    "software": "Software",
    "space": "Space",
    "space_constraints": "Space constraints",
    "spaces": "Spaces",
    "start": "Start",
    "start_date": "Start date",
    "start_greater_than_end": "Start validity date must be before than end validity date.",
    "start_time": "Start time",
    "start_validity": "Start validity",
    "state": "State",
    "state_name": "State name",
    "state_value": "State value",
    "states": "States",
    "status": "Status",
    "status_flags": "Status flags",
    "store": "Store",
    "store_name": "Store name",
    "store_type": "Store type",
    "stores": "Stores",
    "stores_number": "Number of stores",
    "string": "String",
    "sub_type": "Subtype",
    "suction_id": "Suction ID",
    "summary_for": "Summary for",
    "surface_area": "Surface area (m²)",
    "swap_bytes": "Swap bytes",
    "swap_words": "Swap words",
    "synchronize_legacy": "Synchronize legacy",
    "system_manager": "System manager",
    "tag": "Tag",
    "target": "Target",
    "template": "Template",
    "timeouts": "Timeouts",
    "timezone": "Timezone",
    "tls_version": "TLS Version",
    "tls_version_1": "TLS Version 1",
    "tls_version_1_1": "TLS Version 1.1",
    "tls_version_1_2": "TLS Version 1.2",
    "transmission": "Transmission",
    "transmission_company": "Transmission company",
    "transport_type": "Transport type",
    "true": "True",
    "tso": "TSO",
    "type": "Type",
    "uint": "UInt",
    "uint_value": "UInt{{value}}",
    "unavailability_categories": "Unavailability categories",
    "unavailability_category": "Unavailability category",
    "unavailability_period": "Unavailability period",
    "unavailability_periods": "Unavailability periods",
    "uninhibit_priority": "Uninhibit priority",
    "unit": "Unit",
    "units_and_quantities": "Units and quantities",
    "unspecified_loads": "Unspecified loads",
    "up_to_date": "Up to date",
    "update": "Update",
    "update_last_bill": "Update last bill",
    "update_response_timeouts": "Update response timeouts",
    "update_software": "Update software",
    "update_status": "Update status",
    "update_success": "Updated successfully",
    "updated_by": "Updated by",
    "updated_time": "Updated time",
    "updated_version": "Updated version",
    "user": "User",
    "value": "Value",
    "version": "Version",
    "version_name": "Version name",
    "vid": "Vid",
    "warning_update": "Warning: the gateway will be updated",
    "weather_zone": "Weather zone",
    "weather_zones": "Weather zones",
    "words_number": "Words of number",
    "writable": "Writable",
    "write": "Write",
    "write_timeout": "Write timeout",
    "yes": "Yes",
    "zip_code": "ZIP code",
    "zone_name": "Zone name"
  },
  "flex": {
    "list_curtail_schedule": "Proposed and confirmed load curtailment for {{date}}",
    "more_than_one_market_schedule_group": "There is more than one market schedule group for this day.",
    "no_market_schedule_group": "There is no market shcedule group for this day"
  },
  "Height (m)": "Height (m)",
  "point": {
    "gateway_text": "Gateway point text",
    "gateway_value": "Gateway point value",
    "original_value": "Original point value"
  },
  "Protocol instances": "Protocol instances",
  "Sign out": "Sign out",
  "Surface area (m²)": "Surface area (m²)"
}
