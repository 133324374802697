import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import * as React from "react"
import { createContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { retrieve } from "./api"
import { useTranslation } from "react-i18next"

import Create from "./components/Create"
import Delete from "./components/Delete"
import DeleteBulk from "./components/DeleteBulk"
import Detail from "./components/Detail"
import List from "./components/List"

const PointRouteContext = createContext(null)

const PointContent = () => {
  const [point, setPoint] = useState()
  const auth = useAuth()
  const { siteId, pointId } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const retrieveDetail = async () => {
    await retrieve(siteId, pointId, auth.userData.access_token)
      .then(response => {
        const point = {
          ...response.data
        }
        // handle equipment and automation point
        // this should be done in the backend
        // for now we do it here to preserve the current behavior
        if (response.data.equipment) {
          point.equipment = {
            id: response.data.equipment,
            name: response.data.equipment_name
          }
        } else {
          point.equipment = null
        }

        if (response.data.automation_point) {
          point.automation_point = {
            id: response.data.automation_point,
            name: response.data.automation_point_name,
            type: response.data.automation_point_type
          }
        } else {
          point.automation_point = null
        }

        setPoint(point)
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.error"), error.message)]))
      })
  }

  useEffect(() => {
    pointId && retrieveDetail()
  }, [pointId])

  return (
    <PointRouteContext.Provider value={{ point, retrieveDetail }}>
      {point ? <Outlet /> : <Loader />}
    </PointRouteContext.Provider>
  )
}

export { PointRouteContext }
export default function PointRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<List />}
      />
      <Route
        path="create"
        element={<Create />}
      />
      <Route
        path="multi-delete"
        element={<DeleteBulk />}
      />
      <Route
        path=":pointId"
        element={<PointContent />}
      >
        <Route
          index
          element={<Detail />}
        />
        <Route
          path="delete"
          element={<Delete />}
        />
      </Route>
    </Routes>
  )
}
