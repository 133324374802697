import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Space = (spaces, loadingSpaces, equivalentSpace = false) => {
  const fields = {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: {
        required: true,
        maxLength: 255
      }
    },

    parent_space_id: {
      label: i18n.t("common.parent_space"),
      field: Dropdown,
      options: {
        required: true,
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        loading: loadingSpaces,
        placeholder: loadingSpaces
          ? i18n.t("common.loading")
          : i18n.t("common.select", {
              value: i18n.t("common.parent_space").toLowerCase()
            })
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    },
    surface_area: {
      label: i18n.t("common.surface_area"),
      field: InputNumber,
      options: { showButtons: true }
    },
    height: {
      label: i18n.t("common.height"),
      field: InputNumber,
      options: { showButtons: true }
    },
    is_optimized: {
      label: i18n.t("common.optimized"),
      field: InputSwitch,
      options: {}
    },
    is_permeable: {
      label: i18n.t("common.permeable"),
      field: InputSwitch,
      options: {}
    },
    is_heated: {
      label: i18n.t("common.heated"),
      field: InputSwitch,
      options: {}
    },
    is_cooled: {
      label: i18n.t("common.cooled"),
      field: InputSwitch,
      options: {}
    }
  }

  if (equivalentSpace) {
    delete fields.parent_space_id
  }
  return fields
}

export const SpaceOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("common.name") },
    description: { label: i18n.t("common.description") },
    parent_space_name: { label: i18n.t("common.parent_space") },
    surface_area: { label: i18n.t("common.surface_area") },
    height: { label: i18n.t("common.height") },
    is_optimized: { label: i18n.t("common.optimized") },
    is_permeable: { label: i18n.t("common.permeable") },
    is_heated: { label: i18n.t("common.heated") },
    is_cooled: { label: i18n.t("common.cooled") }
  }
}
