import { retrieveAll } from "/apps/organization/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { handleErrors } from "/utils/error"
import {
  BaseLayout,
  ButtonWithLoader,
  FormContent
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, getPriceZones, getWeatherZones } from "../api"
import { SiteContractForm, SiteForm } from "../models"

const Create = () => {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [priceZones, setPriceZones] = useState([])
  const [weatherZones, setWeatherZones] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [processing, setProcessing] = useState(false)
  const [data, setData] = useState({
    organization_id: organizationStore.id,
    active: true,
    synchronise_legacy: true,
    hiveoptimal_contract: true
  })
  const [contractData, setContractData] = useState({
    forced_stop_hours: 144
  })
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [errors, setErrors] = useState({})
  const [loadingPriceZone, setLoadingPriceZone] = useState(false)
  const [loadingWeatherZone, setLoadingWeatherZone] = useState(false)
  const [loadingOrganizations, setLoadingOrganizations] = useState(false)

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const processCreation = async () => {
    const postData = {
      site: {
        ...data,
        latitude: `${data.latitude}`,
        longitude: `${data.longitude}`,
        altitude: data.altitude !== null ? `${data.altitude}` : data.altitude
      },
      contract: data.hiveoptimal_contract
        ? {
            name: contractData.name,
            forced_stop_hours: contractData.forced_stop_hours,
            forced_stop_reset: contractData.forced_stop_reset
          }
        : null,
      savings_share: data.hiveoptimal_contract
        ? {
            bb_share: contractData.bb_share,
            start_validity: contractData.start_validity,
            end_validity: contractData.end_validity
          }
        : null
    }

    return await add(postData, auth.userData.access_token)
  }

  const create = async e => {
    e.preventDefault()
    setProcessing(true)

    processCreation()
      .then(siteData => {
        dispatch(
          addNotifications([successNotification(t("common.site"), t("common.creation_success"))])
        )
        navigate(`/organizations/${organizationStore.id}/sites/${siteData.data.site.id}`)
      })
      .catch(error => {
        handleErrors(error, setErrors, dispatch, t("common.site"))
      })
      .finally(() => setProcessing(false))
  }

  const getPZ = async () => {
    setLoadingPriceZone(true)
    await getPriceZones(auth.userData.access_token)
      .then(({ data }) => {
        data && setPriceZones(data)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification(t("common.price_zones"), message)]))
      })
      .finally(() => setLoadingPriceZone(false))
  }

  const getWZ = async countryCode => {
    setLoadingWeatherZone(true)
    await getWeatherZones(countryCode, auth.userData.access_token)
      .then(({ data }) => {
        data.results && setWeatherZones(Array.isArray(data.results) ? data.results : [])
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification(t("common.weather_zones"), message)]))
      })
      .finally(() => setLoadingWeatherZone(false))
  }

  const getOrganizations = async () => {
    setLoadingOrganizations(true)
    await retrieveAll(auth.userData.access_token)
      .then(results => {
        results && setOrganizations(results)
      })
      .catch(({ message }) => {
        dispatch(addNotifications([errorNotification(t("common.organizations"), message)]))
      })
      .finally(() => setLoadingOrganizations(false))
  }

  useEffect(() => {
    getPZ()
    getOrganizations()
  }, [organizationStore.id])

  useEffect(() => {
    setStartDate(contractData.start_validity)
    setEndDate(contractData.end_validity)
  }, [contractData])

  useEffect(() => {
    data.country && getWZ(data.country)
  }, [data.country])

  useEffect(() => {
    if (data.weather_zone_id) {
      const wz = weatherZones.find(item => item.id === data.weather_zone_id)
      wz && setData({ ...data, weather_zone_timezone: wz.timezone })
    }
  }, [data.weather_zone_id])

  return (
    data && (
      <BaseLayout title={t("common.create_asset", { asset: t("common.site").toLowerCase() })}>
        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={SiteForm(
              priceZones,
              weatherZones,
              organizations,
              loadingPriceZone,
              loadingWeatherZone,
              loadingOrganizations
            )}
            initial={data}
            data={data}
            setData={setData}
            errors={errors}
          />

          {data.hiveoptimal_contract && (
            <>
              <div className="base-separator">
                <h2>{t("common.contract")}</h2>
              </div>
              <FormContent
                model={SiteContractForm(
                  false,
                  data.hiveoptimal_contract,
                  setStartDate,
                  startDate,
                  setEndDate,
                  endDate
                )}
                initial={contractData}
                data={contractData}
                setData={setContractData}
                errors={errors}
              />
            </>
          )}

          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              onClick={goBack}
              disabled={
                processing || loadingPriceZone || loadingWeatherZone || loadingOrganizations
              }
            />
            <ButtonWithLoader
              rounded
              severity="success"
              label={t("common.save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
              disabled={
                processing || loadingPriceZone || loadingWeatherZone || loadingOrganizations
              }
            />
          </div>
        </form>
      </BaseLayout>
    )
  )
}

export default Create
