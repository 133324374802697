import { PROTOCOL_INSTANCE_TYPE } from "/utils/constants/protocol-instance"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { GatewayRoutesContext } from "../../../gateway/routes"
import { ConnectorInstanceRoutesContext } from "../../routes"
import { remove } from "../api"
import { ProtocolInstanceRouteContext } from "../routes"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { gateway } = useContext(GatewayRoutesContext)
  const { connector } = useContext(ConnectorInstanceRoutesContext)
  const { protocolInstance } = useContext(ProtocolInstanceRouteContext)
  const [processing, setProcessing] = useState(false)

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    setProcessing(true)
    await remove(siteStore.id, protocolInstance.id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification(t("common.protocol_instance"), t("common.delete_success"))
        ])
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/${connector.id}/protocol-instances/`
        )
      })
      .catch(error => {
        if (error.response.status === 400 && error.response.data.id) {
          displayNotification([
            errorNotification(t("common.protocol_instance"), error.response.data.id[0])
          ])
        } else {
          const message =
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : error.message
          displayNotification([errorNotification(t("common.protocol_instance"), message)])
        }
      })
      .finally(() => setProcessing(true))
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    protocolInstance && (
      <DeleteConfirmation
        objectType={t("common.protocol_instance")}
        objectNames={[`${PROTOCOL_INSTANCE_TYPE[protocolInstance.type]}`]}
        remove={removeObject}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}
