import { TabsLayout } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Route, Routes, useLocation, useParams } from "react-router-dom"
import Create from "./components/Create"
import Detail from "./components/Detail"
import Delete from "./components/Delete"
import MarketPlayerInteractionRoutes from "./market-player-interaction/routes"
import CurtailSchedulesRoutes from "./curtail-schedule/routes"
import { useDispatch, useSelector } from "react-redux"
import { organization } from "utils/store/organization"
import { site } from "utils/store/site"
import Home from "./components/Home"
import { updateSideBar } from "utils/store/sidebar"

function FlexLayout() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const { flexId } = useParams()
  const location = useLocation()
  const { t } = useTranslation()
  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      {
        name: t("common.parameters"),
        active: location.pathname.includes("parameters"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/${flexId}/parameters/`
      },
      {
        name: t("common.curtail_schedules"),
        active: location.pathname.includes("curtail-schedules"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/${flexId}/curtail-schedules/`
      },
      {
        name: t("common.market_player_interaction"),
        active: location.pathname.includes("market-player-interaction"),
        url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/${flexId}/market-player-interaction/`
      }
    ])
  }, [siteStore.id, flexId])

  return (
    <TabsLayout
      title={t("common.flexibility")}
      tabs={tabs}
      setTabs={setTabs}
    />
  )
}

export default function FlexibilityRoutes() {
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()

  useEffect(() => {
    if (siteStore.id && organizationStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
    }
  }, [siteStore.id, organizationStore.id])

  return (
    <Routes>
      <Route
        index
        element={<Home />}
      />
      <Route
        path="create/"
        element={<Create />}
      />
      <Route
        path=":flexId/"
        element={<FlexLayout />}
      >
        <Route
          index
          path="delete/"
          element={<Delete />}
        />
        <Route
          index
          path="parameters/*"
          element={<Detail />}
        />
        <Route
          index
          path="curtail-schedules/*"
          element={<CurtailSchedulesRoutes />}
        />
        <Route
          index
          path="market-player-interaction/*"
          element={<MarketPlayerInteractionRoutes />}
        />
      </Route>
    </Routes>
  )
}
