import { STORAGE_TYPES } from "/utils/constants/storage"
import { ArrayInput, Dropdown, SelectArray } from "@software-engineering/hivolution-frontend-utils"
import i18n from "i18next"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"

export const Point = (
  retrieveEquipments,
  getEquipmentsValues,
  retrieveAutomationPoints,
  getAutomationPointValues,
  parameters,
  stores,
  loadingParameters,
  loadingStores
) => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: {
        required: true,
        keyfilter: /[\w\d\-_]/,
        maxLength: 19
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    },
    equipment: {
      label: i18n.t("common.equipment"),
      field: SelectArray,
      options: {
        required: true,
        getItems: retrieveEquipments,
        getAllValues: getEquipmentsValues,
        keyLabel: "name",
        columns: [
          { field: "name", header: i18n.t("common.name"), sortable: true },
          {
            field: "node_name",
            header: i18n.t("common.node"),
            sortable: true
          },
          {
            field: "gateway_name",
            header: i18n.t("common.gateway"),
            sortable: true
          }
        ]
      }
    },
    automation_point: {
      label: i18n.t("common.automation_point"),
      field: SelectArray,
      options: {
        getItems: retrieveAutomationPoints,
        getAllValues: getAutomationPointValues,
        keyLabel: "name",
        columns: [
          { field: "name", header: i18n.t("common.name"), sortable: true },
          {
            field: "node_name",
            header: i18n.t("common.node"),
            sortable: true
          },
          {
            field: "description",
            header: i18n.t("common.description"),
            sortable: true
          }
        ]
      },
      dependsOn: "equipment"
    },
    read: {
      label: i18n.t("common.read"),
      field: InputSwitch
    },
    write: {
      label: i18n.t("common.write"),
      field: InputSwitch
    },
    parameter: {
      label: i18n.t("common.unit"),
      field: Dropdown,
      options: {
        options: parameters,
        optionLabel: "context_name",
        optionValue: "id",
        loading: loadingParameters,
        placeholder: loadingParameters
          ? i18n.t("common.loading")
          : i18n.t("common.select_a", {
              value: i18n.t("common.parameter").toLowerCase()
            })
      }
    },
    time_series_storage_info: {
      store_type: {
        label: i18n.t("common.store_type"),
        field: Dropdown,
        options: {
          options: [
            { label: i18n.t("common.gauge"), value: STORAGE_TYPES.GAUGE },
            { label: i18n.t("common.status"), value: STORAGE_TYPES.STATUS }
          ],
          optionLabel: "label",
          optionValue: "value",
          placeholder: i18n.t("common.select_a", {
            value: i18n.t("common.store_type").toLowerCase()
          })
        }
      },
      store: {
        label: i18n.t("common.store"),
        field: Dropdown,
        options: {
          options: stores,
          optionLabel: "name",
          optionValue: "id",
          loading: loadingStores,
          placeholder: loadingStores
            ? i18n.t("common.loading")
            : i18n.t("common.select_a", {
                value: i18n.t("common.store").toLowerCase()
              })
        }
      },
      disable_storage: {
        label: i18n.t("common.disable_storage"),
        field: InputSwitch,
        dependsOn: "store_type"
      },
      linear_multiplier: {
        label: i18n.t("common.linear_multiplier"),
        field: InputNumber,
        options: {
          showButtons: true
        },
        dependsOn: "store_type",
        dependsOnValue: STORAGE_TYPES.GAUGE
      },
      linear_constant: {
        label: i18n.t("common.linear_constant"),
        field: InputNumber,
        options: { showButtons: true },
        dependsOn: "store_type",
        dependsOnValue: STORAGE_TYPES.GAUGE
      },
      acceptable_values: {
        field: ArrayInput,
        dependsOn: "store_type",
        dependsOnValue: STORAGE_TYPES.STATUS,
        options: {
          columns: [
            {
              field: "original_point_value",
              header: i18n.t("point.original_value")
            },
            {
              field: "gateway_point_value",
              header: i18n.t("point.gateway_value")
            },
            {
              field: "gateway_point_text",
              header: i18n.t("point.gateway_text")
            }
          ],
          modelForm: () => {
            return {
              original_point_value: {
                label: i18n.t("point.original_value"),
                field: InputNumber,
                options: { showButtons: true, required: true }
              },
              gateway_point_value: {
                label: i18n.t("point.gateway_value"),
                field: InputNumber,
                options: { showButtons: true, required: true }
              },
              gateway_point_text: {
                label: i18n.t("point.gateway_text"),
                field: InputText
              }
            }
          },
          label: i18n.t("common.acceptable_values"),
          createLabel: i18n.t("common.add_values")
        }
      }
    }
  }
}

export const PointOverview = () => {
  return {
    /*
     * List of fields for the model, like this
     */
    name: { label: i18n.t("common.name") },
    description: { label: i18n.t("common.description") },
    equipment: { label: i18n.t("common.equipment"), getValue: item => item.name },
    automation_point: { label: i18n.t("common.automation_point"), getValue: item => item.name },
    read_write: { label: i18n.t("common.read_write") },
    parameter_name: { label: i18n.t("common.unit") },
    time_series_storage_info: {
      store_type: { label: i18n.t("common.store_type") },
      store_name: { label: i18n.t("common.store") },
      disable_storage: { label: i18n.t("common.disable_storage") }
    }
  }
}
