import { Dropdown } from "@software-engineering/hivolution-frontend-utils"
import { InputText } from "primereact/inputtext"
import { InputTextarea } from "primereact/inputtextarea"
import i18n from "i18next"

export const ExternalAPINode = spaces => {
  return {
    name: {
      label: i18n.t("common.name"),
      field: InputText,
      options: { required: true }
    },
    external_id: {
      label: i18n.t("common.external_id"),
      field: InputText,
      options: { required: true }
    },
    space: {
      label: i18n.t("common.space"),
      field: Dropdown,
      options: {
        options: spaces,
        optionLabel: "name",
        optionValue: "id",
        filter: true
      }
    },
    description: {
      label: i18n.t("common.description"),
      field: InputTextarea,
      options: {}
    }
  }
}

export const ExternalAPIOverview = () => {
  return {
    name: { label: i18n.t("common.name") },
    external_id: { label: i18n.t("common.external_id") },
    space_name: { label: i18n.t("common.space") },
    description: { label: i18n.t("common.description") }
  }
}
