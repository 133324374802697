import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { ButtonWithLoader, Loader } from "@software-engineering/hivolution-frontend-utils"
import { Button } from "primereact/button"
import { useAuth } from "oidc-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { setVersionToGateways, getVersion, getGateways } from "../api"

export default function Update() {
  const auth = useAuth()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()
  const [loadingVersion, setLoadingVersion] = useState(false)
  const [loadingGateways, setLoadingGateways] = useState(false)
  const [gatewaysName, setGatewaysName] = useState([])
  const [versionName, setVersionName] = useState()
  const [processing, setProcessing] = useState(false)
  const gatewayIds = searchParams.getAll("gateway_id")
  const versionId = searchParams.get("version_id")

  const displayNotification = notification => {
    dispatch(addNotifications(notification))
  }

  const retrieveVersionName = async () => {
    setLoadingVersion(true)
    await getVersion(auth.userData.access_token, versionId)
      .then(({ data }) => {
        data.version && setVersionName(data.version)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.version_name"), message)]))
      })
      .finally(() => setLoadingVersion(false))
  }

  const retrieveGatewaysName = async queryParams => {
    setLoadingGateways(true)
    await getGateways(auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.content && setGatewaysName(data.content.map(gateway => gateway.name))
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.gateway"), message)]))
      })
      .finally(() => setLoadingGateways(false))
  }

  const update = async () => {
    setProcessing(true)
    await setVersionToGateways(
      versionId,
      gatewayIds.map(gatewayId => ({ id: gatewayId, type: "forced", confirmationRequired: false })),
      auth.userData.access_token
    )
      .then(({ status, statusText }) => {
        if (status === 200) {
          displayNotification([
            successNotification(t("common.gateway"), t("common.update_success"))
          ])
          navigate("/configuration/gateways/update-software/")
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification(t("common.gateway"), message)])
      })
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    retrieveVersionName()
    const queryParams = {
      limit: gatewayIds.length,
      sort: "name:ASC",
      q: "controllerId=in=("
        .concat(gatewayIds.map(gatewayId => `${gatewayId}`).join(","))
        .concat(")")
    }
    retrieveGatewaysName(queryParams)
  }, [])

  return (
    <div>
      {loadingVersion || loadingGateways ? (
        <Loader />
      ) : (
        <>
          <div className="gateway-confirmation-update-software--warning">
            <div>
              <i className="fa-regular" />
              {t("common.warning_update")} {versionName}
            </div>
          </div>
          <div className="gateway-confirmation-update-software--list-gateway">
            {gatewaysName.map((objectName, key) => (
              <div key={key}>{objectName}</div>
            ))}
          </div>
          <div className="gateway-confirmation-update-software--warning">
            <div>
              <i className="fa-regular" />
              {t("common.confirmation_software_update")}
            </div>
          </div>
        </>
      )}
      <div>
        <div className="action-buttons-wrapper centered">
          <Button
            rounded
            outlined
            severity="secondary"
            onClick={() => navigate(-1)}
            disabled={processing}
            label={t("common.cancel")}
            icon="fa-solid fa-xmark"
          />

          <ButtonWithLoader
            rounded
            severity="info"
            onClick={update}
            disabled={processing}
            label={t("common.update_software")}
            icon="fa-solid fa-arrow-up-right-from-square"
          />
        </div>
      </div>
    </div>
  )
}
