import { REQUEST_DEFAULT_LIMIT, REQUEST_DEFAULT_OFFSET } from "/utils/constants/api"
import { errorNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ListActions, ListLayout } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { getAllValues, retrieveList } from "../api"

export default function List() {
  /*
   * This component work with PrimeReact
   * It load and struct data to display a Table component with a Filter
   * component and button to go to a creation page.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/" element={<List />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()

  const [items, setItems] = useState([])
  const [filters, setFilters] = useState({})
  const [filterValues, setFilterValues] = useState(null)
  const [selected, setSelected] = useState([])

  const [loading, setLoading] = useState(true)
  const [loadingFilters, setLoadingFilters] = useState(false)

  const [offset, setOffset] = useState(REQUEST_DEFAULT_OFFSET)
  const [limit, setLimit] = useState(REQUEST_DEFAULT_LIMIT)
  const [count, setCount] = useState(0)
  const [sortOrder, setSortOrder] = useState(null)
  const [sortField, setSortField] = useState(null)

  const retrieve = async (
    queryParams = {
      ...filters,
      limit,
      offset,
      ordering: sortField && `${sortOrder > 0 ? "" : "-"}${sortField}`
    }
  ) => {
    setLoading(true)
    await retrieveList(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        data.results && setItems(data.results)
        setCount(data.count)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.unavailability_periods"), message)]))
      })
      .finally(() => setLoading(false))
  }

  const getFilterValues = async () => {
    setLoadingFilters(true)
    await getAllValues(siteStore.id, auth.userData.access_token)
      .then(response => {
        setFilterValues({
          ...response.data,
          start: Array.from(
            new Set(
              response.data.start.map(start => {
                return {
                  label: new Date(start).toLocaleString(),
                  value: start
                }
              })
            )
          ),
          end: Array.from(
            new Set(
              response.data.end.map(end => {
                return {
                  label: new Date(end).toLocaleString(),
                  value: end
                }
              })
            )
          )
        })
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.filter_values"), error.message)]))
      })
      .finally(() => setLoadingFilters(false))
  }

  const onSort = sortEvent => {
    setSortOrder(sortEvent.sortOrder)
    setSortField(sortEvent.sortField)
  }

  const columns = [
    { field: "category_name", header: t("common.unavailability_category"), sortable: true },
    { field: "comment", header: t("common.comment"), sortable: true },
    { field: "updated_by", header: t("common.updated_by"), sortable: true },
    {
      field: "start",
      header: t("common.start"),
      sortable: true,
      processValue: value => {
        return new Date(value).toLocaleString()
      }
    },
    {
      field: "end",
      header: t("common.end"),
      sortable: true,
      processValue: value => {
        return value ? new Date(value).toLocaleString() : ""
      }
    }
  ]

  const columnsFilter = [
    { field: "category_name", header: t("common.unavailability_category") },
    { field: "updated_by", header: t("common.updated_by") },
    { field: "start", header: t("common.start") },
    { field: "end", header: t("common.end") }
  ]

  const getDetailUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/${objectId}/`

  const getDeleteUrl = objectId =>
    `/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/${objectId}/delete/`

  const onRowClick = objectId => navigate(getDetailUrl(objectId))

  const menuFilters = { columnsFilter, filters, setFilters, filterValues }

  useEffect(() => {
    if (siteStore.id) {
      dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
      getFilterValues()
    }
  }, [siteStore.id, organizationStore.id])

  useEffect(() => {
    siteStore.id && offset ? setOffset(REQUEST_DEFAULT_OFFSET) : retrieve()
  }, [filters, sortField, sortOrder])

  useEffect(() => {
    siteStore.id && retrieve()
  }, [limit, offset])

  return (
    <section className="list-section">
      <ListActions
        filterBackend={true}
        filters={menuFilters}
        loading={loadingFilters}
      >
        <Link
          to={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/create/`}
        >
          <Button
            rounded
            severity="info"
            icon="fa-regular fa-plus"
            label={t("common.create")}
          />
        </Link>
      </ListActions>

      <ListLayout
        columns={columns}
        value={items}
        loading={loading}
        filters={filters}
        getDetailUrl={getDetailUrl}
        getDeleteUrl={getDeleteUrl}
        onRowClick={onRowClick}
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        count={count}
        bulkDeleteUrl={`/organizations/${organizationStore.id}/sites/${siteStore.id}/operations/unavailability-periods/multi-delete/?${selected.map(item => `id=${item.id}`).join("&")}`}
        handleSelection={setSelected}
        onSort={onSort}
        sortOrder={sortOrder}
        sortField={sortField}
        backendSort={true}
      />
    </section>
  )
}
