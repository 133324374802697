import { paginatedRequest } from "/utils/api"
import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import {
  ButtonWithLoader,
  FormContent,
  ListActions,
  ListLayout
} from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { addInterfaces, removeInterfaces, updateInterfaces } from "../api"
import { Interface } from "../models/interface"
import { NODE_TYPES } from "/utils/constants/node"

export default function Interfaces({ siteId, nodeId, node }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const [loadingNetworks, setLoadingNetworks] = useState(false)
  const [loadingInterfaces, setLoadingInterfaces] = useState(false)
  const [interfaces, setInterfaces] = useState([])
  const [visible, setVisible] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [networks, setNetworks] = useState([])
  const [interfaceForm, setInterfaceForm] = useState({ node: nodeId })
  const [errors, setErrors] = useState({})
  const [currentInterface, setCurrentInterface] = useState(null)
  const [processing, setProcessing] = useState(false)

  const retrieveInterfaces = async () => {
    setLoadingInterfaces(true)
    await paginatedRequest(
      `asset/sites/${siteId}/node_interfaces/?node=${nodeId}`,
      auth.userData.access_token
    )()
      .then(results => {
        setInterfaces(
          results.map(nodeInterface => {
            return {
              ...nodeInterface,
              ...{
                network_label: nodeInterface.ip_address_range
                  ? nodeInterface.ip_address_range
                  : nodeInterface.name
              }
            }
          })
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.interfaces"), message)]))
      })
      .finally(() => setLoadingInterfaces(false))
  }

  const retrieveNetworks = async () => {
    setLoadingNetworks(true)
    await paginatedRequest(`asset/sites/${siteId}/networks/`, auth.userData.access_token)()
      .then(results => {
        setNetworks(results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.networks"), message)]))
      })
      .finally(() => setLoadingNetworks(false))
  }

  const findNetworkType = () => {
    const currentNetwork = networks.find(network => network.id === interfaceForm.network)
    return currentNetwork ? currentNetwork.type : null
  }

  const findTypeAndTransportType = (nodeType, networkType) => {
    if (networkType === "ipnetwork") {
      return {
        type: nodeType === NODE_TYPES.BACNET ? "udpip" : "tcpip",
        transport_type: nodeType === NODE_TYPES.BACNET ? "UDP/IP" : "TCP/IP"
      }
    } else if (networkType === "serialnetwork") {
      return {
        type: nodeType === NODE_TYPES.BACNET ? "mstpserial" : "serial"
      }
    }
    return {}
  }

  const submit = async e => {
    e.preventDefault()

    setProcessing(true)

    const dataToSend = {
      ...interfaceForm,
      ...findTypeAndTransportType(node.type, findNetworkType())
    }

    if (currentInterface) {
      await updateInterfaces(siteId, currentInterface, dataToSend, auth.userData.access_token)
        .then(() => {
          navigate(0)
          dispatch(
            addNotifications([
              successNotification(t("common.interfaces"), t("common.update_success"))
            ])
          )
        })
        .catch(error => {
          if (error.response.data) {
            setErrors(error.response.data)
          }
          dispatch(addNotifications([errorNotification(t("common.interfaces"), error.message)]))
        })
    } else {
      await addInterfaces(siteId, dataToSend, auth.userData.access_token)
        .then(() => {
          navigate(0)
          dispatch(
            addNotifications([
              successNotification(t("common.interfaces"), t("common.creation_success"))
            ])
          )
        })
        .catch(error => {
          if (error.response.data) {
            setErrors(error.response.data)
          }
          dispatch(addNotifications([errorNotification(t("common.interfaces"), error.message)]))
        })
    }

    setProcessing(false)
  }

  const deleteInterface = async () => {
    await removeInterfaces(siteId, currentInterface, auth.userData.access_token)
      .then(() => {
        navigate(0)
        dispatch(
          addNotifications([successNotification(t("common.interface"), t("common.delete_success"))])
        )
      })
      .catch(error => {
        dispatch(addNotifications([errorNotification(t("common.interface"), error.message)]))
      })
  }

  const actionsBodyTemplate = rowData => {
    return (
      <div className="p-datatable-action-buttons">
        <button
          onClick={() => {
            setDeleteVisible(true)
            setCurrentInterface(rowData.id)
          }}
        >
          <i className="fa-regular fa-trash-can" />
        </button>
      </div>
    )
  }

  const hideDialog = () => {
    setVisible(false)
    setCurrentInterface(null)
    setInterfaceForm({ node: nodeId })
  }

  useEffect(() => {
    nodeId && retrieveInterfaces()
  }, [nodeId])

  useEffect(() => {
    siteId && visible && retrieveNetworks()
  }, [siteId, visible])

  return (
    <>
      <Dialog
        visible={visible}
        onHide={hideDialog}
      >
        <form
          className="form-view"
          onSubmit={submit}
        >
          <FormContent
            model={Interface(networks, node.type, findNetworkType(), loadingNetworks)}
            data={interfaceForm}
            setData={setInterfaceForm}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              disabled={processing}
              onClick={hideDialog}
            />
            <ButtonWithLoader
              rounded
              className="button"
              severity="success"
              label={t("common.save")}
              type="submit"
              disabled={processing}
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteVisible}
        style={{ width: "50vw" }}
        onHide={() => {
          setDeleteVisible(false)
          setCurrentInterface(null)
        }}
      >
        <span>{t("common.confirm_delete", { asset: t("common.interface") })}</span>
        <div className="flex justify-content-between">
          <Button
            className="button"
            severity="success"
            label={t("common.yes")}
            type="button"
            onClick={deleteInterface}
          />
          <Button
            className="button"
            severity="danger"
            label={t("common.no")}
            type="button"
            onClick={() => setDeleteVisible(false)}
          />
        </div>
      </Dialog>

      <section className="list-section">
        <ListActions>
          <Button
            rounded
            label={t("common.connect")}
            icon="fa-solid fa-plug-circle-plus"
            onClick={() => setVisible(true)}
            severity="info"
          />
        </ListActions>
        <ListLayout
          columns={[
            { field: "network_label", header: t("common.network") },
            { field: "type", header: t("common.type") },
            { field: "ip_address", header: t("common.infos") }
          ]}
          value={interfaces}
          onRowClick={interfaceId => {
            const nodeInterface = interfaces.find(item => item.id === interfaceId)
            setInterfaceForm(nodeInterface)
            setCurrentInterface(interfaceId)
            setVisible(true)
          }}
          loading={loadingInterfaces}
          actionsBodyTemplate={actionsBodyTemplate}
        />
      </section>
    </>
  )
}
