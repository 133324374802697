import React from "react"
import { FormContent } from "@software-engineering/hivolution-frontend-utils"
import { Button } from "primereact/button"
import { useTranslation } from "react-i18next"

import { FlexContract, CurtailParameters, OptimizationActivation } from "../models"

export default function Form(props) {
  const { t } = useTranslation()
  /*
  props can also have following properties (optional) :
   -  header property (e.g. <Form header={<h1>Header</h1>} />)
   -  onDelete property (e.g. <Form onDelete={onDelete} />)
   -  className property (e.g. <Form className="my-class" />)
   -  style property (e.g. <Form style={{color: 'red'}} />)
   */
  const { data, setData, errors, fieldOptions, onSubmit, processing } = props
  return (
    data && (
      <div
        className={props.className ? props.className : null}
        style={props.style ? props.style : null}
      >
        {props.header && props.header}
        <form
          id="flex-contract-form"
          className="form-view flex-contract-form"
          onSubmit={onSubmit}
        >
          <div className="form-2-columns">
            <h3>{t("common.flex_contract")}</h3>
            <FormContent
              model={FlexContract(fieldOptions)}
              data={data}
              setData={setData}
              errors={errors}
              processing={processing}
            />
          </div>
          <div className="form-3-columns mt-5">
            <h3>{t("common.curtail_parameters")}</h3>
            <FormContent
              model={CurtailParameters(fieldOptions)}
              data={data}
              setData={setData}
              errors={errors}
              processing={processing}
            />
          </div>

          <h3>{t("common.optimization")}</h3>
          <FormContent
            model={OptimizationActivation(fieldOptions)}
            data={data}
            setData={setData}
            errors={errors}
            processing={processing}
          />
        </form>
        <div className="action-buttons-wrapper">
          {props.onDelete && (
            <Button
              type="button"
              rounded
              severity="danger"
              label={t("common.delete")}
              onClick={props.onDelete}
              disabled={processing}
              loading={processing}
              icon="fa-solid fa-trash"
            />
          )}
          <Button
            rounded
            form="flex-contract-form"
            severity="success"
            label={t("common.save")}
            type="submit"
            disabled={Object.keys(errors).length > 0 || processing}
            loading={processing}
            icon="fa-solid fa-floppy-disk"
          />
        </div>
      </div>
    )
  )
}
