import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { ButtonWithLoader, FormContent } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import { Button } from "primereact/button"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { add, retrieveEquipments } from "../api"
import { CircuitBreakerRelation } from "../models"
import { CircuitBreakerRouteContext } from "../../circuit-breaker/routes"

export default function Create() {
  /*
   * This component work with PrimeReact
   * It displays a formContent component to create a new object and
   * redirect to the detail view.
   * It is placed in the element attribute of Route component.
   *
   * @component
   * @example
   *
   * export default function Routes () {
   *   return (
   *     <Routes>
   *       <Route path="/create" element={<Create />} />
   *       ...
   *     </Routes>
   *   )
   * }
   *
   */

  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const { circuitBreaker } = useContext(CircuitBreakerRouteContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const [data, setData] = useState({
    circuit_breaker_id: circuitBreaker.id,
    relationship_type: null
  })
  const [errors, setErrors] = useState({})
  const [processing, setProcessing] = useState(false)
  const [equipments, setEquipments] = useState([])

  const create = async e => {
    e.preventDefault()

    setProcessing(true)
    await add(siteStore.id, data, auth.userData.access_token)
      .then(response => {
        dispatch(
          addNotifications([
            successNotification(t("common.circuit_breaker_relation"), t("common.creation_success"))
          ])
        )
        navigate(
          `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/electrical/circuit-breakers/${circuitBreaker.id}/circuit-breaker-relations/${response.data.id}`
        )
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        error.response.data && setErrors(error.response.data)
        dispatch(
          addNotifications([errorNotification(t("common.circuit_breaker_relation"), message)])
        )
      })
      .finally(() => setProcessing(false))
  }

  const goBack = e => {
    e && e.preventDefault()
    navigate(-1)
  }

  const getEquipments = async (queryParams = {}) => {
    await retrieveEquipments(siteStore.id, auth.userData.access_token, queryParams)
      .then(({ data }) => {
        setEquipments(data.results)
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.equipments"), message)]))
      })
  }

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  useEffect(() => {
    if (data.relationship_type) {
      data.relationship_type === "IS_MEASURED_BY"
        ? getEquipments({ equipment_type_name: ["Meter"] })
        : getEquipments()
      setData({ ...data, equipment_id: null })
    }
  }, [data.relationship_type])

  return (
    data && (
      <>
        <div className="base-content-title">
          <h2>
            {t("common.create_asset", {
              asset: t("common.circuit_breaker_relation").toLowerCase()
            })}
          </h2>
        </div>

        <form
          className="form-view"
          onSubmit={create}
        >
          <FormContent
            model={CircuitBreakerRelation(equipments)}
            data={data}
            setData={setData}
            errors={errors}
          />
          <div className="action-buttons-wrapper">
            <Button
              rounded
              outlined
              severity="secondary"
              disabled={processing}
              label={t("common.cancel")}
              icon="fa-solid fa-xmark"
              onClick={goBack}
            />
            <ButtonWithLoader
              rounded
              severity="success"
              disabled={processing}
              label={t("common.save")}
              type="submit"
              icon="fa-solid fa-floppy-disk"
            />
          </div>
        </form>
      </>
    )
  )
}
