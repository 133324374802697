import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const { t } = useTranslation()
  const { id } = useParams()

  const displayNotification = notification => {
    dispatch(addNotifications(notification))
  }

  const removeObject = async () => {
    await remove(id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification(t("common.edge_block_template"), t("common.delete_success"))
        ])
        navigate("/configuration/edge-block-templates/")
      })
      .catch(error => {
        displayNotification([errorNotification(t("common.edge_block_template"), error.message)])
      })
  }

  return (
    <DeleteConfirmation
      objectType={t("common.edge_block_template")}
      remove={removeObject}
      goBack={() => navigate(-1)}
    />
  )
}
