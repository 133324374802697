import { updateSideBar } from "utils/store/sidebar"
import { organization } from "utils/store/organization"
import { Cards } from "@software-engineering/hivolution-frontend-utils"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router-dom"
import { site } from "utils/store/site"
import { useTranslation } from "react-i18next"
import StateRoutes from "./state/routes"
import PointRoutes from "./point/routes"
import EdgeControllerRoutes from "./edge-controller/routes"

import { FlexContractContextProvider } from "./flex-contract/context"

function OptimizationHome() {
  const siteStore = useSelector(site)
  const organizationStore = useSelector(organization)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return (
    <Cards
      items={[
        {
          icon: "fa-solid fa-gears",
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/states/`,
          title: t("common.states")
        },
        {
          icon: "fa-solid fa-gear",
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/edge-controllers/`,
          title: t("common.edge_controller")
        },
        {
          icon: "fa-solid fa-random",
          url: `/organizations/${organizationStore.id}/sites/${siteStore.id}/optimization/flexibility/`,
          title: t("common.flex")
        }
      ]}
    />
  )
}

export default function OptimizationRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<OptimizationHome />}
      />
      <Route
        path="states/*"
        element={<StateRoutes />}
      />
      <Route
        path="configuration/*"
        element={<PointRoutes />}
      />
      <Route
        path="edge-controllers/*"
        element={<EdgeControllerRoutes />}
      />
      <Route
        path="/flexibility/*"
        element={<FlexContractContextProvider />}
      />
    </Routes>
  )
}
