import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { DeleteConfirmation } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { remove } from "../api"

export default function Delete() {
  const organizationStore = useSelector(organization)
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const displayNotification = notifications => {
    dispatch(addNotifications(notifications))
  }

  const removeObject = async () => {
    await remove(organizationStore.id, auth.userData.access_token)
      .then(() => {
        displayNotification([
          successNotification(t("common.organization"), t("common.delete_success"))
        ])
        navigate("/")
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        displayNotification([errorNotification(t("common.organization"), message)])
      })
  }

  return (
    organizationStore && (
      <DeleteConfirmation
        objectType={t("common.organization")}
        objectNames={[organizationStore.name]}
        remove={removeObject}
        removeNotAllowedMessage={
          organizationStore.sites_count > 0
            ? t("common.organization_delete_not_allowed_site_linked")
            : null
        }
        goBack={() => navigate(-1)}
      />
    )
  )
}
