import * as React from "react"
import { Route, Routes } from "react-router-dom"
import View from "./components/View"

export default function CurtailSchedulesRoutes() {
  return (
    <Routes>
      <Route
        index
        element={<View />}
      />
    </Routes>
  )
}
