import { errorNotification, successNotification } from "/utils/notification"
import { addNotifications } from "/utils/store/notification"
import { organization } from "/utils/store/organization"
import { updateSideBar } from "/utils/store/sidebar"
import { site } from "/utils/store/site"
import { HTTP_STATUS } from "/utils/constants/http_status"
import { DeleteConfirmation, Loader } from "@software-engineering/hivolution-frontend-utils"
import { useAuth } from "oidc-react"
import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { GatewayRoutesContext } from "../../gateway/routes"
import { getConnectorInstancesCount, removeConnectorInstances } from "../api"

const DeleteBulk = () => {
  const auth = useAuth()
  const organizationStore = useSelector(organization)
  const siteStore = useSelector(site)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [objectCount, setObjectCount] = useState()
  const [loadingRelatedObject, setLoadingRelatedObject] = useState(false)
  const [processing, setProcessing] = useState(false)
  const ids = searchParams.getAll("id")
  const { gateway } = useContext(GatewayRoutesContext)

  const count = async () => {
    setLoadingRelatedObject(true)
    await getConnectorInstancesCount(ids, auth.userData.access_token)
      .then(({ data, status, statusText }) => {
        if (HTTP_STATUS.OK === status) {
          setObjectCount(data)
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.connector_instances"), message)]))
      })
      .finally(() => setLoadingRelatedObject(false))
  }

  const bulkDelete = async () => {
    setProcessing(true)
    await removeConnectorInstances(siteStore.id, ids, auth.userData.access_token)
      .then(({ status, statusText }) => {
        if (HTTP_STATUS.NO_CONTENT === status) {
          dispatch(
            addNotifications([
              successNotification(t("common.connector_instances"), t("common.delete_success"))
            ])
          )
          navigate(
            `/organizations/${organizationStore.id}/sites/${siteStore.id}/assets/gateways/${gateway.id}/connector-instances/`
          )
        } else {
          throw new Error(statusText)
        }
      })
      .catch(error => {
        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message
        dispatch(addNotifications([errorNotification(t("common.connector_instances"), message)]))
      })
      .finally(() => setProcessing(false))
  }

  useLayoutEffect(() => {
    count()
  }, [])

  useEffect(() => {
    siteStore.id && dispatch(updateSideBar({ menuName: "site", siteId: siteStore.id }))
  }, [siteStore.id])

  return loadingRelatedObject ? (
    <Loader />
  ) : (
    objectCount && (
      <DeleteConfirmation
        objectType={t("common.connector_instances")}
        objectNames={objectCount.label}
        relatedObjects={objectCount}
        remove={bulkDelete}
        processing={processing}
        goBack={() => navigate(-1)}
      />
    )
  )
}

export default DeleteBulk
